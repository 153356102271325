import React, { useState, useEffect, useRef } from 'react'
import { Table, Button, Dropdown, DropdownButton, Form } from 'react-bootstrap';
import axios from 'axios'
import AdminModal from "./AdminModal"
import InfiniteScroll from 'react-infinite-scroll-component'
import NavbarDashboard from '../NavbarDashboard';
import { useHistory } from 'react-router-dom';
import ScrollToTop from '../../ScrollToTop';
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";
import { convertAuthUserResponseToReact } from '../../../utils/Auth';

const AdminPanel = () => {
    const history = useHistory()
    const userStatus = useRef(null);
    const userToken = window.localStorage.getItem("Stoken")
    const { email } = JSON.parse(window.localStorage.getItem("userData"))
    const [hasMoreRecords, setMoreRecords] = useState(true)
    const [toggleList, setToggleList] = useState(undefined);
    const [userData, setUserData] = useState('');
    const [searchText, setSearchText] = useState('')
    const [toggleTitle, setToggleTittle] = useState("Toggle Users")
    const [userList, setUserList] = useState([])
    const [loading, setLoading] = useState(true)
    const [emptyMessage, setEmptyMessage] = useState(false)


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        checkAdmin()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchUserApi = (listSize) => {
        const getUsers = {
            method: "post",
            url: "v1/oauth/user/get_user_data",
            headers: {
                Authorization: `Bearer ${userToken}`
            }
            ,
            data: {}
        }
        if (toggleList !== undefined) {
            getUsers.data.active = toggleList
        }
        if (listSize) {
            getUsers.data.listSize = listSize
        }
        axios(getUsers)
            .then(res => {
                if (res.data.Users.length === 0) {
                    setMoreRecords(false)
                }
                document.body.classList.remove("loading-indicator")
                setLoading(false)
                setUserList([...userList, ...res.data.Users])
            })
    }
    const ChangeUserActive = (myData) => {
        const toggle_status = myData.is_active === 0 ? "1" : "0"
        var data = new FormData()
        data.append("email", myData.email)
        data.append("id", myData.id)
        data.append("is_active", toggle_status)
        const ChangeStatus = {
            method: "post",
            url: "v1/oauth/user/chnage_user_isactive",
            headers: {
                Authorization: `Bearer ${userToken}`
            },
            data: data
        }
        axios(ChangeStatus).then(res => {

            const toggledUserIndex = userList.findIndex(e => e.id === myData.id)
            const status = userList[toggledUserIndex].is_active
            const copiedUserList = [...userList]
            copiedUserList[toggledUserIndex].is_active = status === 1 ? 0 : 1
            setUserList(copiedUserList)
            // if (userStatus.current.innerHTML === "Inactive") {
            //     userStatus.current.innerHTML = "Active"
            //     userStatus.current.className = "btn btn-outline-success"
            // } else {
            //     userStatus.current.innerHTML = "Inactive"
            //     userStatus.current.className = "btn btn-outline-danger"
            // }
            // handleClose()
        })
    }
    const handlePagination = () => {

        document.body.classList.add("loading-indicator");
        fetchUserApi(userList.length);
    };
    const onInputText = (searchInput) => {
        const controller = new AbortController()
        const signal = controller.signal
        const fetchNewUserData = async () => {
            var userData = new FormData()
            userData.append("search", searchInput)
            const SearchData = {
                method: "post",
                url: "v1/oauth/user/search_by_admin",
                headers: {
                    Authorization: `Bearer ${userToken}`
                },
                data: userData,
                signal: signal
            }
            try {
                const response = await axios(SearchData)
                if (response.data.status === false) {
                    setUserList([])
                    setEmptyMessage(true)
                } else {
                    setUserList(response.data.user)
                }
            } catch (err) {
                console.log("Error: " + err)
            }
        }
        fetchNewUserData()
        return () => {
            controller.abort()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }


    const checkAdmin = () => {
        const adminData = {
            method: "post",
            url: "v1/oauth/user/check_admin",
            headers: {
                Authorization: `Bearer ${userToken}`
            },
            data: {
                email: email,
            }
        }
        axios(adminData).then(res => {
            if (!res.data.is_admin) {
                history.push("/")
            }
        }).catch(err => {
            console.log(err)
        })
    }
    useEffect(() => {
        fetchUserApi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleList])

    const notify = (message, status) => {
        if (status) {
            toast.success(message, { theme: "dark" })
        } else {
            toast.error(message, { theme: "dark" })
        }
    }


    const DeleteUser = (user) => {
        var data = new FormData()
        data.append("user_id", user.id)
        const DeleteUser = {
            method: "post",
            url: "v1/oauth/user/delete_user",
            headers: {
                Authorization: `Bearer ${userToken}`
            },
            data: data
        }
        axios(DeleteUser).then(res => {
            if (res.data.status === true) {
                notify(res.data.message, true)
                const deleteUserIndex = userList.findIndex(e => e.id === user.id)
                const copiedUserList = [...userList]
                copiedUserList.splice(deleteUserIndex, 1)
                setUserList(copiedUserList)
            }
        })
            .catch(err => {
                if (err.response.status === 422) {
                    notify(err.data.message, false)
                }
            })
    }

    const signInAsUser = (user) => {
        if (user.is_active === 0) {
            notify("User is Currently Inactive, Please activate to Sign In", false)
            return
        }
        var data = new FormData()
        data.append("user_id", user.id)
        const singIn = {
            method: "post",
            url: "v1/oauth/user/login-as-user",
            headers: {
                Authorization: `Bearer ${userToken}`
            },
            data: data
        }
        axios(singIn).then(res => {
            window.localStorage.removeItem('userData');
            localStorage.setItem('access_token', res.data.access_token);
            getAuthUserApi()

        })
    }
    const getAuthUserApi = () => {
        let token = localStorage.getItem("access_token");
        var getUser = {
            method: "get",
            url: "/v1/oauth/user/get-user",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        axios(getUser)
            .then(function (res) {
                let user = convertAuthUserResponseToReact(res.data.user)
                window.localStorage.setItem("userData", JSON.stringify(user))

                window.location.href = "/dashboard"
            })
    }


    return <>
        <ScrollToTop />
        <NavbarDashboard isAdmin={true} />
        {!loading && (

            <>
                <>
                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', marginRight: 40, marginTop: 100 }}>
                        <Form.Group className="mb-3" controlId="formBasicPassword" style={{ paddingLeft: "3rem", width: "20rem" }}>
                            <Form.Control onChange={(e) => {
                                setUserList([])
                                if (e.target.value.length === 0) {
                                    setSearchText(e.target.value)
                                    fetchUserApi()
                                } else {
                                    setEmptyMessage(false)
                                    setSearchText(e.target.value)
                                    onInputText(e.target.value)
                                }
                            }} value={searchText} type="text" placeholder="Search User" />
                        </Form.Group>

                        <DropdownButton variant="outline-dark" id="dropdown-basic-button" title={toggleTitle} style={{ width: "10rem" }}>
                            <Dropdown.Item onClick={() => {
                                setSearchText('')
                                setUserList([])
                                setToggleList(1)
                                setToggleTittle("Active")
                            }}>Active</Dropdown.Item>
                            <Dropdown.Item onClick={() => {
                                setSearchText('')
                                setUserList([])
                                setToggleList(2)
                                setToggleTittle("Inactive")
                            }}>Inactive</Dropdown.Item>
                        </DropdownButton>
                    </div>
                </>


                <Table table-dark bordered responsive hover id='admin-panel-main' >
                    <thead>
                        <tr>
                            <th className="text-center">First Name</th>
                            <th className="text-center">Last Name</th>
                            <th className="text-center">Customer Email</th>
                            <th className="text-center">Customer Address</th>
                            <th className="text-center">Customer Phone</th>
                            <th className="text-center">Customer Status</th>
                            <th className="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <InfiniteScroll
                            dataLength={userList.length}
                            next={handlePagination}
                            hasMore={searchText.length > 0 ? false : hasMoreRecords}
                            scrollThreshold="500px"

                        // loader={<h4>Loading...</h4>}
                        // endMessage={<h4 style={{ marginLeft: 30, fontSize: 17, color: 'grey', fontWeight: 'bold' }}>All records have been fetched </h4>}

                        ></InfiniteScroll>
                        {userList && userList.map(user => {
                            return (


                                <tr
                                    className={user.is_active === 0 ? "table-secondary" : ""}
                                    key={user.id}>
                                    <td className='text-center'>{user.first_name}</td>
                                    <td className='text-center'>{user.last_name}</td>
                                    <td className='text-center'>{user.email}</td>
                                    <td className='text-center'>{user.adress}</td>
                                    <td className='text-center'>{user.phone_number}</td>
                                    <td className='text-center'>
                                        <Button ref={userStatus} onClick={() => {
                                            ChangeUserActive(user)

                                        }} variant={user.is_active === 1 ? 'outline-success' : 'outline-danger'}>{user.is_active === 1 ? "Active" : "Inactive"}</Button>
                                    </td>
                                    <td className='text-center' >
                                        <Dropdown>
                                            <Dropdown.Toggle variant="warning" id="dropdown-basic">
                                                Actions...
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => {
                                                    setUserData(user)
                                                    handleShow()
                                                }
                                                }><span>
                                                        <img src="/assets/icon/dpIcons/edit.png" alt="" />
                                                    </span>  Change Password </Dropdown.Item>
                                                <Dropdown.Item onClick={() => {
                                                    setUserData(user)
                                                    DeleteUser(user)
                                                }
                                                }><span>
                                                        <img src="/assets/icon/delete.png" style={{ height: 18, width: 18 }} alt="" />
                                                    </span>  Delete User </Dropdown.Item>
                                                <Dropdown.Item onClick={() => {
                                                    setUserData(user)
                                                    signInAsUser(user)
                                                }
                                                }><span>
                                                        <img src="/assets/icon/signin.png" style={{ height: 18, width: 18 }} alt="" />
                                                    </span>  Log in</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        {/* <Button className="mr10" variant="warning" onClick={() => {
                                            setUserData(user)
                                            handleShow()
                                        }
                                        }>Change Password</Button>
                                        <Button variant="danger" onClick={() => {
                                            setUserData(user)
                                            DeleteUser(user)
                                        }
                                        }>Delete</Button>
                                        <Button variant="success" onClick={() => {
                                            setUserData(user)
                                            signInAsUser(user)
                                        }
                                        }>SignIn</Button> */}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                {
                    emptyMessage && (
                        <h4 style={{ marginLeft: "3rem" }}>No Records Found!</h4>
                    )
                }
            </>
        )}

        <AdminModal show={show} userData={userData} handleClose={handleClose} notify={notify} />
        <ToastContainer
            position="bottom-right"
            autoClose={2000}
            transition={Slide}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </>
}

export default AdminPanel