import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import axios from "axios";
const RadioCheckTemplate = (props) => {
  const { checked, title, template_id, step_id, id} = props.data;
  const {
    handleViewPopup,
    handleCheckChange,
    handleSubItemEdit,
    handleCategoryDeletion,
  } = props;
  const token = localStorage.getItem("access_token");
  const [defaultComments, setDefaultComments] = useState("");
  const [updatedTitle, setUpdatedTitle] = useState(title);
  const [showModal, setShowModal] = useState(false);
  const [deleteConfirmShow, setDeleteConfirmShow] = useState(false);
  const [editedComment, setEditedComment] = useState("");
  const handleCommentChange = (e) => {
    setEditedComment(e.target.value);
  };
  const [show,setShow] = useState(false);
  const handleClose=() => {
    setShow(false);
  }
  const handleEditClick = () => {
    setShowModal(true);
  };
  const handleCategoryDelete = () => {
    handleCategoryDeletion(template_id, step_id, id);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSaveChanges = () => {
    handleSubItemEdit(props.data, updatedTitle);
    setShowModal(false);
  };



  const stepCategoryCheckComments = async (templateId, templateStepId, templateCategoryCheckId) => {
    try {
      const config = {
        method: "post",
        url: "/v1/oauth/templates/step-category-checks-comments",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: {
          template_id: templateId,
          template_step_id: templateStepId,
          template_category_check_id: templateCategoryCheckId,
        },
      };
      const response = await axios(config);
      const comment = response.data.comment;
      setDefaultComments(comment);
      return comment;
    } catch (error) {
      console.error("Error fetching comments:", error);
      return "";
    }
  };
  const updateStepCategoryCheckComment = async (templateId, templateStepId, templateCategoryCheckId, comment) => {
    try {
      const config = {
        method: "post",
        url: "/v1/oauth/templates/step-category-checks-comments/update",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: {
          template_id: templateId,
          template_step_id: templateStepId,
          template_category_check_id: templateCategoryCheckId,
          comment: comment,
        },
      };
      const response = await axios(config);
      console.log("Step category comment updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating comment:", error);
      throw error;
    }
  };


  const handleEyeClick = async () => {
    try {
      const comment = await stepCategoryCheckComments(template_id,step_id, id);
      setEditedComment(comment);
      setShow(true);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };
const handleSaveComment = async () => {
  try {
    await updateStepCategoryCheckComment(template_id, step_id, id, editedComment);
    setDefaultComments(editedComment);
    handleClose();
  } catch (error) {
    console.error("Error saving comment:", error);
  }
};



  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb20">
        <Form.Check
          type="radio"
          name="group1"
          label={title}
          checked={checked}
          onChange={() => handleCheckChange(props.data)}
        />

        <div style={{ display: "flex" }}>
          <div
            className="cursor-pointer"
            style={{ marginRight: "10px" }}
            onClick={handleEditClick}
          >
            <img
              src="/assets/icon/editicon.png"
              alt=""
              style={{ height: 20, width: 20 }}
            />
          </div>
          <div
            className="cursor-pointer"
            style={{ marginRight: "10px" }}
            onClick={()=> handleEyeClick()}
          >
            <img
              src="/assets/eye.png"
              alt=""
              style={{ height: 20, width: 20 }}
            />
          </div>

          <div
            className="cursor-pointer"
            onClick={() => setDeleteConfirmShow(true)}
          >
            <img
              src="/assets/icon/delete.png"
              alt=""
              style={{ height: 20, width: 20 }}
            />
          </div>
        </div>
      </div>
      {/* Modal for editing the title */}
      <Modal show={showModal} onHide={handleCloseModal} className="p-3">
        <Modal.Header
          closeButton
          className="my-2"
          style={{ borderBottom: "none" }}
        >
          <Modal.Title>Edit Title</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4 w-100">
          <input
            type="text"
            value={updatedTitle}
            style={{
              border: "none",
              borderBottom: "1px solid #CDCDCD",
              width: "100%",
            }}
            onChange={(e) => setUpdatedTitle(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer className="my-2" style={{ borderTop: "none" }}>
          <Button
            variant="secondary"
            className="m-0 w-25 mx-2 "
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="m-0 w-25 btn-submit mx-2 "
            variant="primary"
            onClick={handleSaveChanges}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* DELETE CONFIRMATION MODAL */}
      <Modal
        show={deleteConfirmShow}
        onHide={() => setDeleteConfirmShow(false)}
        className="p-3"
      >
        <Modal.Header
          closeButton
          className="my-2"
          style={{ borderBottom: "none" }}
        >
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          Are you sure you want to delete?
        </Modal.Body>
        <Modal.Footer className="my-2" style={{ borderTop: "none" }}>
          <Button
            variant="secondary"
            className="m-0 w-25 mx-2"
            onClick={() => setDeleteConfirmShow(false)}
          >
            Cancel
          </Button>
          <Button
            className="m-0 w-25 btn-submit mx-2"
            variant="danger"
            onClick={handleCategoryDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Add default comment modal */}
      <Modal
        enforceFocus={false}
        size="xl"
        show={show}
        onHide={handleClose}
        className="custom-Modal"
      >
        <Modal.Header
          closeButton
          className="pl0 pr0 text-transform-capitalize pb-2"
        >
          <h4 className="mb-0"> Add Default Comment</h4>
        </Modal.Header>
        <Modal.Body className="pt20 pb20">
          <Row className="mb-2">
          
              <Form.Control
                as="textarea"
                rows={8}
                value={editedComment}
                onChange={(e)=>handleCommentChange(e)}
              />
           
         
         
          </Row>
       
      
    
         
        </Modal.Body>

     
          <Modal.Footer className="row pl0 pr0">
            <div className="col-md-6 m-0 pl0">
              <Button
                className="m-0 w-100 btn-submit "
              
                onClick={() => handleSaveComment() }
              >
                Save
              </Button>
            </div>
            <div className="col-md-6 m-0 pr0">
              <Button
                variant="secondary"
                className="w-100 "
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </Modal.Footer>
       
      </Modal>
    </>
  );
};

export default RadioCheckTemplate;
