import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import ScrollToTop from "../../ScrollToTop";

const StepThree = ({ updateFormData, data, prev, next }) => {
  const [imageReader, setImageReader] = useState("");
  const imageHandler = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImageReader(reader.result);
        updateFormData("step3", "profile_image", e.target.files[0]);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  const validate = Yup.object({
    company_info: Yup.string().required("Enter Company Info"),
    website: Yup.string().matches(
      /[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi
    ),
  });
  const submitForm = () => {
    next();
  };

  return (
    <>
      <ScrollToTop />
      <Formik
        initialValues={data}
        enableReinitialize
        validationSchema={validate}
        onSubmit={submitForm}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleSubmit,
            handleBlur,
          } = props;
          return (
            <form onSubmit={handleSubmit}>
              <div className="input-custom form-group">
                <input
                  type="number"
                  name="inspector_license_number"
                  className="form-control "
                  value={values.inspector_license_number || ""}
                  onChange={(e) => {
                    handleChange(e);
                    updateFormData("step3", e.target.name, e.target.value);
                  }}
                  placeholder="Inspector's License Number"
                />
              </div>
              <div className="input-custom form-group">
                <input
                  type="date"
                  name="license_expiration_date"
                  value={values.license_expiration_date || ""}
                  onChange={(e) => {
                    handleChange(e);
                    updateFormData("step3", e.target.name, e.target.value);
                  }}
                  className="form-control "
                  selected={values.license_expiration_date}
                />
              </div>
              <div className="input-custom form-group">
                <input
                  type="text"
                  name="website"
                  value={values.website || ""}
                  className="form-control "
                  onChange={(e) => {
                    handleChange(e);
                    updateFormData("step3", e.target.name, e.target.value);
                  }}
                  placeholder="Website"
                />
              </div>
              <div className="input-custom form-group">
                <input
                  // onClick={() => setClicked({ ...clicked, company_info: true })}
                  type="text"
                  name="company_info"
                  onBlur={handleBlur}
                  value={values.company_info || ""}
                  onChange={(e) => {
                    handleChange(e);
                    updateFormData("step3", e.target.name, e.target.value);
                  }}
                  className={
                    "form-control " +
                    (errors.company_info && touched.company_info && "br-danger")
                  }
                  placeholder="Company Info *"
                />

                {errors.company_info && touched.company_info && (
                  <small className="input-error">{errors.company_info}</small>
                )}
              </div>
              <div className="input-custom form-group">
                <label className="upl-ct ">
                  <input
                    type="file"
                    className="form-control "
                    name="profile_image"
                    id="profileLogo"
                    accept="images/*"
                    onChange={imageHandler}
                  />
                  + <br />
                  Upload Logo
                </label>
              </div>
              {values?.profile_image && (
                <div className="img-holder">
                  <img
                    src={imageReader ? imageReader : values?.profile_image}
                    id="reg-logo"
                    className="reg-logo"
                    alt=""
                  />
                </div>
              )}
              <br />
              <button type="submit" className="btn btn-submit ">
                Submit <i className="fa fa-arrow-right"></i>{" "}
              </button>
              <button
                type="button"
                className="btn btn-light "
                onClick={() => prev(values)}
              >
                Back{" "}
              </button>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default StepThree;
