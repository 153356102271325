import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { isAuthenticated } from "../../../utils/Auth";
import ScrollToTop from "../../ScrollToTop";
import NavbarDashboard from "../NavbarDashboard";
import { Row, Col } from "react-bootstrap";
import Client from "./Client";
import axios from "axios";
import ClientSkeleton from "./ClientSkeleton";
import DialogBox from "../../../utils/DialogBox";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";
import InfiniteScroll from 'react-infinite-scroll-component'

const ClientsList = (props) => {
  isAuthenticated();

  const [weatherDetails, setWeatherDetails] = useState({});

  const [clientId, setClientID] = useState();

  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [clientExists, setClientExists] = useState(true);
  const [bodyText, setBodyText] = useState("");
  const [hasMoreRecords, setMoreRecords] = useState(true)
  var token = localStorage.getItem("access_token");

  const [show, setShow] = useState(false);
  const [deleteActiveID, setDeleteActiveID] = useState("");
  const [DialogBoxStatus, setDialogBoxStatus] = useState("");
  const [DialogBoxTitle, setDialogBoxTitle] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let initialSearchQuery
  if (props.location.state){
    initialSearchQuery = props.location.state
  }else{
    initialSearchQuery = ''
  }

  const [searchText, setSearchText] = useState(initialSearchQuery);

  const notify = (message, status) => {
    if (status) {
      toast.success(message, {
        theme: "dark",
      });
    } else {
      toast.error(message, {
        theme: "dark",
      });
    }
  };

  const getClientsApi = async (listSize) => {
    var getUser;
    setIsLoading(true);
    getUser = {
      method: "post",
      url: "/v1/oauth/client/get-clients",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    if (listSize) {
      getUser.data = {listSize: listSize}
    }

    await axios(getUser)
      .then((response) => {
        document.body.classList.remove("loading-indicator");
        if (response.data.status === true) {
          setClients([...clients, ...response.data.clients]);
        } else {
          setMoreRecords(false)
          setClientExists(false);
        }

        setIsLoading(false);
      })
      .catch((err) => {
        document.body.classList.remove("loading-indicator");
        notify("Network Error!");
        if (err?.response?.status === 401) {
          window.localStorage.clear();
          window.location.href = "/login";
        }
      });
  };

  useEffect(() => {
    setTimeout(() => {
      getClientsApi();
    }, 200);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClientDelete = (id) => {
    document.body.classList.add("loading-indicator");

    const token = localStorage.getItem("access_token");
    var FormData = require("form-data");
    var clientFormData = new FormData();
    clientFormData.append("client_id", id);

    var config = {
      method: "post",
      url: "/v1/oauth/client/delete",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data: clientFormData,
    };
    axios(config)
      .then((res) => {
        if (res.data.status === true) {
          handleClose();
          document.body.classList.remove("loading-indicator");
          notify("Client Deleted Successfully!", "success");
          const filtered = clients.filter((client) => {
            return client.id !== id;
          });
          setClients(filtered);
        }
      })
      .catch((err) => {
        document.body.classList.remove("loading-indicator");
        notify("Network Error!");
      });
  };
  // const scrollerHasValidHeight = (element) => {
  //   return element.scrollHeight - element.scrollTop === element.clientHeight;
  // };

  const handlePagination = async () => {
    if (clientExists) {
      document.body.classList.add("loading-indicator");
      getClientsApi(clients.length);
    }
  };

  const handleShowSearchInput = () => {
    const inputSearch = document.getElementById("searchField");
    if (document.getElementById("searchField").classList.contains("d-none")) {
      document.getElementById("searchField").classList.remove("d-none");
      inputSearch.focus();
    } else {
      document.getElementById("searchField").classList.add("d-none");
    }
  };

  const handleHideSearchInput = (e) => {
    document.getElementById("searchField").classList.add("d-none");
  };

  const handleWeatherDetailsPopup = (client_id) => {
    setClientID(client_id);
    setDialogBoxTitle("Weather Details");
    setDialogBoxStatus("weatherDetails");
    document.body.classList.add("loading-indicator");

    const configForm = {
      method: "get",
      url: "/v1/oauth/inspection/get-inspection-time",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { client_id: client_id },
    };
    axios(configForm)
      .then((res) => {
        if (res.data.status === true) {
          setWeatherDetails(res.data.inspection);
          document.body.classList.remove("loading-indicator");
          handleShow();
        } else if (res.data.status === false) {
          document.body.classList.remove("loading-indicator");
          notify(res.data.message);
        }
      })
      .catch((err) => {
        document.body.classList.remove("loading-indicator");
        notify("Network Error!");
        if (err?.response?.status === 401) {
          window.location = "/login";
        }
      });
  };

  const handleWeatherUpdationApi = (data) => {
    const weatherVals = {
      client_id: clientId,
      start_date: formatDate(data?.startDate),
      end_date: formatDate(data?.endDate),
      weather_condition: data?.weatherConditions,
      start_time: data?.startTime.toLocaleTimeString(),
      end_time: data?.endTime.toLocaleTimeString(),
    };
    function formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }
    const configForm = {
      method: "post",
      url: "/v1/oauth/inspection/update-inspection-time",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: weatherVals,
    };
    axios(configForm)
      .then((res) => {
        if (res.data.status === true) {
          handleClose();
          notify("Weather Details Updated!", "success");
        } else {
          notify(res.data.message);
        }
      })
      .catch((err) => {
        notify("Network Error!");
        if (err?.response?.status === 401) {
          window.location = "/login";
        }
      });
  };
  return (
    <>
      <ScrollToTop />
      <NavbarDashboard />
      <div className="content-container Mcontent">
        <Row className="side-spacing pt50" onMouseLeave={handleHideSearchInput}>
          <div className="mb20 ">
            <h2 className=" float-start">Clients</h2>
            <div className="float-end position-relative">
              <Link
                to="/clients/add-client"
                className="ml20 text-decoration-none greylight"
              >
                {" "}
                Add Clients
              </Link>
              <Link
                to="/clients/archived-clients"
                className="ml20 text-decoration-none greylight"
              >
                Archived Clients
              </Link>
              <img
                src="/assets/icon/search.png"
                className="ml20 cursor-pointer"
                alt=""
                onClick={handleShowSearchInput}
              />
              <input
                type="text"
                className="form-control d-none search-input"
                placeholder="Search Client"
                value={searchText}
                id="searchField"
                onMouseLeave={handleHideSearchInput}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          </div>
          {isLoading && (
            <Col xs={12} md={12} className="mt20 px-0">
              <ClientSkeleton />
            </Col>
          )}

          {clients && (
            <InfiniteScroll
              dataLength={clients.length}
              next={handlePagination}
              hasMore={hasMoreRecords}
              style={{minHeight: 320}}
              // loader={<h4>Loading...</h4>}
              endMessage={<h4 style={{ marginLeft: 30, fontSize: 17, color: 'grey', fontWeight: 'bold' }}>All records have been fetched </h4>}

            >
              {clients
                .filter((val) => {
                  if (searchText === "") {
                    return val;
                  } else if (
                    val.client_name
                      .toLowerCase()
                      .includes(searchText.toLowerCase()) ||
                    val.email
                      .toLowerCase()
                      .includes(searchText.toLowerCase()) ||
                    val.address.toLowerCase().includes(searchText.toLowerCase())
                  ) {
                    return val;
                  }
                  return false;
                }).map((client) => (
                  <Client
                    handleDeleteActiveID={setDeleteActiveID}
                    handleDialogBoxTitle={setDialogBoxTitle}
                    handleDialogBoxStatus={setDialogBoxStatus}
                    handleShow={handleShow}
                    key={client.id}
                    client={client}
                    clientsPage="clientList"
                    handleWeatherDetailsPopup={handleWeatherDetailsPopup}
                    handleBodyText={setBodyText}
                    handleWeatherUpdationApi={handleWeatherUpdationApi}
                  />
                ))}
            </InfiniteScroll>
          )}
          {clients.length === 0 && !isLoading && (
            <p> No Active Clients Available. </p>
          )}
        </Row>
      </div>
      {show && (
        <DialogBox
          handlePopupId={handleClientDelete}
          data={
            DialogBoxStatus === "weatherDetails"
              ? weatherDetails
              : deleteActiveID
          }
          bodyText={bodyText}
          title={DialogBoxTitle}
          status={DialogBoxStatus}
          handleClose={handleClose}
          show={show}
          handleWeatherUpdationApi={handleWeatherUpdationApi}
        />
      )}

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        transition={Slide}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default ClientsList;
