import React from 'react'
import "../node_modules/video-react/dist/video-react.css"
import { Player } from 'video-react'

const Testpage = () => {
    const [videoSrc, seVideoSrc] = React.useState("/assets/videos/whatsapp.mp4");
    console.log(videoSrc)

    // const handleChange = (file) => {
    //     console.log(file)
    //     var reader = new FileReader();
    //     console.log(file)
    //     var url = URL.createObjectURL(file.originFileObj);
    //     seVideoSrc(url);
    // };


    // const handleVideo = () => {
    //     const files = document.getElementById('fileItem')
    //     console.log(files)
    // }
    return (
        <div>

            <label htmlFor="file">Choose file to upload</label>
            <input style={{ display: 'block' }} onChangeCapture={(e) => seVideoSrc(e.target.files[0])} type="file" id="fileItem" name="file" multiple />
            {videoSrc && <div>
                Hello
                <Player
                    playsInline
                    src={videoSrc}
                    fluid={false}
                    width={780}
                    height={572}
                />
            </div>}
        </div>
    )
}

export default Testpage