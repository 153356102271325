import React from "react";
import { Modal, Button } from "react-bootstrap";

const DialogBox = (props) => {
  const {
    show,
    handleClose,
    title,
    categoryValue,
    inputLabel,
    inputName,
    inputPlaceholder,
    inputType,
    setCategoryValue,
    status,
    bodyText,
    handleCategoryApi,
  } = props;
  const handleChange = (updatedVal) => {
    // console.log(categoryValue);
    setCategoryValue(updatedVal);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} className="custom-Modal">
        <Modal.Header closeButton className="pl0 pr0">
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!status ? (
            <div className="input-custom form-group">
              <label htmlFor="edit_category" className="mb-2">
                {inputLabel}
              </label>{" "}
              <br />
              <input
                type={inputType}
                name={inputName}
                placeholder={inputPlaceholder}
                id="edit_category"
                value={categoryValue}
                onChange={(e) => {
                  // let Allowedvalue = e.target.value;
                  // Allowedvalue = Allowedvalue.replace(/[^A-Za-z ]/gi, "");
                  handleChange(e.target.value);
                }}
                className="form-control"
              />
            </div>
          ) : (
            <div className="pb-4 pt-2 fs18">{bodyText}</div>
          )}
        </Modal.Body>

        <Modal.Footer className="row pl0 pr0 ">
          <div className="col-md-6 m-0 pl0 pr0">
            {/* <Button className="m-0 w-100 btn-submit " onClick={status === 'edit' ? handleCategoryUpdation : status === 'add' ? handleCategoryCreation : ''}> */}
            <Button
              className="m-0 w-100 btn-submit "
              onClick={handleCategoryApi}
            >
              {status === "delete" ? "Yes" : "Save"}
            </Button>
          </div>
          <div className="col-md-6 m-0 pr0 pl0">
            <Button
              variant="secondary"
              className="w-100 "
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DialogBox;
