import React from 'react'
import NavbarTop from '../NavbarTop';
import { useEffect, useState } from 'react';
import ScrollToTop from '../ScrollToTop';
import Header from '../Header';
import ContactUs from '../ContactUs';
import Footer from '../Footer';
import { authenticate } from '../../utils/Auth';
// import Blogs from "../Blogs"


const LandingPage = () => {
    var isAuthenticated = true
    const [buttonText, setButtonText] = useState()
    useEffect(() => {
        if (authenticate()) {
            setButtonText(true)
        }
        else {
            setButtonText(false)
        }
    }, [])

    return (
        <>
            {
                isAuthenticated ? <>
                    <ScrollToTop />
                    <NavbarTop text={buttonText} />
                    <Header />
                    <ContactUs />
                    {/* <Blogs /> */}
                    <Footer />
                </> : ''
            }

        </>
    )
}

export default LandingPage
