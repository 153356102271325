import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
var token = localStorage.getItem("access_token");

export const inspectionsList = createAsyncThunk(
  "inspection/inspectionsList",
  async (arg) => {
    const listsize = arg;
    // if (!listsize) {
    //     document.body.classList.add('loading-indicator');
    // }
    let dataConfig;
    if (listsize) {
      dataConfig = {
        method: "post",
        url: "/v1/oauth/inspection/get-inspections",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          listSize: listsize,
        },
      };
    } else {
      dataConfig = {
        method: "post",
        url: "/v1/oauth/inspection/get-inspections",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
    const response = await axios(dataConfig);
    const data = await response.data;
    return data;
  }
);

export const inspectionExistence = createAsyncThunk(
  "inspection/inspectionExistence",
  async (arg) => {
    const client_id = arg.client_id;
    const from_review_inspection = arg.from_review_inspection;

    document.body.classList.add("loading-indicator");

    var checkIfInspectionExists = {
      method: "post",
      url: "/v1/oauth/inspection/checkIfInspectionExists",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { client_id: client_id },
    };
    const response = await axios(checkIfInspectionExists);
    const data = await response.data;
    console.log("dispatch", data);
    return { data, from_review_inspection };
  }
);

export const getInspectionStepsApi = createAsyncThunk(
  "inspection/getInspectionStepsApi",
  async (arg) => {
    document.body.classList.add("loading-indicator");

    const { id, stepName, bookmark_id, tabKey } = arg;

    const formData = {
      inspection_id: id,
      step_id: bookmark_id,
      type: tabKey,
    };

    var configInspStepsValues = {
      method: "post",
      url: "/v1/oauth/inspection/get-step-categories",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    };
    const stepsResp = await axios(configInspStepsValues);
    const data = await stepsResp.data;

    return { data };
  }
);

export const getInspectionStepsCategoryChecksApi = createAsyncThunk(
  "inspection/getInspectionStepsCategoryChecksApi",
  async (arg) => {
    document.body.classList.add("loading-indicator");

    const { id, step_id, type, inspection_step_category_id } = arg;
    const formData = {
      inspection_id: id,
      step_id: step_id,
      type: type,
      inspection_step_category_id: inspection_step_category_id,
    };
    const getChecksData = {
      method: "post",
      url: "/v1/oauth/inspection/get-category-checks",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    };

    const response = await axios(getChecksData);
    const data = response.data.checks;
    return { data };
  }
);

export const getInspectionBookmark = createAsyncThunk(
  "inspection/getInspectionBookmark",
  async (arg) => {
    document.body.classList.add("loading-indicator");
    const formData = {
      inspection_id: arg,
      step_id: 1,
    };
    const configData = {
      method: "post",
      url: "/v1/oauth/inspection/inspection-bookmark",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    };
    const response = await axios(configData);
    const data = await response.data;
    return data;
  }
);
export const generateReport = createAsyncThunk(
  "inspection/generateReport",
  async (arg) => {
    document.body.classList.add("loading-indicator");
    const { selectedClientId, appA, appB, fcm_token, notify, browserPlatform } =
      arg;
    const values = {
      client_id: selectedClientId,
      appendixA: appA,
      appendixB: appB,
      platform: "web",
    };
    if (browserPlatform !== "Chrome") {
      values.os = "safari";
    } else {
      values.fcm_token = fcm_token;
    }

    var configData = {
      url: "/v1/oauth/user/generate-pdf",
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: values,
    };
    var result;
    try {
      result = await axios(configData);
      notify(
        "Report generation in process, you will be notificed once finished",
        true
      );
      // if (fcm_token) {

      // } else {
      //   notify('Some features aren\'t supported in your current browser, try again later!')
      // }
    } catch (err) {
      notify("Something went wrong");
      return [err.response.data, fcm_token];
    }
    return [result.data, fcm_token];
  }
);

const inspectionSlice = createSlice({
  name: "inspection",
  initialState: {
    checkInspectionExistanceData: {},
    inspectionPdfLink: "",
    stepCategories: {},
    book_mark: "",
    storeActiveInspectionStep: {
      name: "",
      data: {},
      checksDataFor: {
        tabName: "",
        categoryName: "",
        data: {},
      },
    },
    redirection: false,
  },
  reducers: {
    storeActiveInspectionStep: (state, action) => {
      const { inspection_id, bookmark_id } = action.payload;
      if (state.storeActiveInspectionStep.data) {
        window.location.href = `/inspection-step-data/${inspection_id}/${action.payload.name}/${bookmark_id}`;
      }
    },
    switchRedirection: (state, action) => {
      state.redirection = action.payload;
    },
  },
  extraReducers: {
    [inspectionExistence.pending]: (state, action) => {
      state.status = "loading";
    },
    [inspectionExistence.fulfilled]: (state, { payload }) => {
      state.checkInspectionExistanceData = payload.data;
      var inspection_id;
      var client_id;

      if (
        payload.data.inspection !== null &&
        payload.data.inspection !== undefined
      ) {
        inspection_id = payload.data.inspection.id;
      } else {
        inspection_id = payload.data.inspection_id;
      }
      client_id = payload.data.client.id;

      document.body.classList.remove("loading-indicator");

      if (inspection_id === 0) {
        state.redirection = true;
        return;
      } else {
        if (payload.from_review_inspection) {
          const origin = window.location.origin;
          const link =
            origin + `/inspection-review/${inspection_id}/${client_id}`;

          const a = document.createElement("a");
          a.setAttribute("href", link);
          a.setAttribute("target", "_blank");
          a.click();
        } else {
          window.location.href = `/inspection/${inspection_id}/inspection-steps/${client_id}`;
        }
      }

      return;
    },
    [inspectionExistence.failed]: (state, action) => {
      state.status = "rejected";
    },

    [getInspectionStepsApi.fulfilled]: (state, action) => {
      state.stepCategories = action.payload.data;

      if (action.payload.stepName) {
        state.storeActiveInspectionStep.name = action.payload.stepName;
        if (action.payload.stepName in state.stepCategories) {
          state.storeActiveInspectionStep.data =
            action.payload.data[action.payload.stepName];
        }
      }
      document.body.classList.remove("loading-indicator");
    },
    [getInspectionStepsCategoryChecksApi.fulfilled]: (state, action) => {
      // setTimeout(() => {
      document.body.classList.remove("loading-indicator");
      // }, 200)
      state.storeActiveInspectionStep.checksDataFor.tabName =
        action.payload.tabName;
      state.storeActiveInspectionStep.checksDataFor.categoryName =
        action.payload.categoryName;
      state.storeActiveInspectionStep.checksDataFor.data = action.payload.data;
    },
    [getInspectionBookmark.fulfilled]: (state, action) => {
      if (action.payload.status === true) {
        state.book_mark = action.payload.book_mark;
        document.body.classList.remove("loading-indicator");
      }
    },
    [generateReport.fulfilled]: (state, action) => {
      document.body.classList.remove("loading-indicator");
      const [status, fcm_token] = action.payload;
      if (status && fcm_token !== null && fcm_token !== undefined) {
        state.inspectionPdfLink = true;
      } else {
        state.inspectionPdfLink = false;
      }
      // state.inspectionPdfLink = action.payload.pdf;
      // if (state.inspectionPdfLink) {
      //   const link = state.inspectionPdfLink;
      // } else if (action.payload.status === false) {
      //   state.inspectionexistForPdf = false;
      // }
    },
  },
});

export const {
  getInspectionSteps,
  storeActiveInspectionStep,
  switchRedirection,
} = inspectionSlice.actions;
export default inspectionSlice.reducer;
