import React, { useState, useRef, useEffect } from "react";
import ScrollToTop from "../../ScrollToTop";
import NavbarDashboard from "../NavbarDashboard";
import { Editor } from '@tinymce/tinymce-react';
import * as Yup from "yup";
import { Formik, Field } from "formik";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const InspectionPropertyDescription = () => {
  const token = localStorage.getItem("access_token");
  const history = useHistory();
  const { id: client_id } = useParams();
  const inspectionExistance = useSelector(
    (state) => state.inspection.checkInspectionExistanceData
  );

  const inspection_template_id = useSelector((state) => state.template.value);
  const template_id = inspectionExistance.template.id;

  const [description, setDescription] = useState("");
  const [conventions, setConventions] = useState("");
  const [scope, setScope] = useState("");

  const editorRef = useRef(null);
  const conventionsEditorRef = useRef(null);
  const scopeEditorRef = useRef(null);
  const handleFormSubmit = (values) => {
    axios.post("/v1/oauth/inspection/create", values, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      if (res.data.status) {
        history.push(`/inspection/${res.data.inspection_id}/inspection-steps/${client_id}`);
      }
    })
    .catch((err) => console.error(err));
  };
  const templateId = useSelector((state) => state.template.value);

  const fetchDescription = async () => {
    document.body.classList.add("loading-indicator");
    const config = {
      method: "get",
      url: `/v1/oauth/templates/details/${templateId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    try {
      const response = await axios(config);
      
      setDescription(response.data.template.paragraph_text || "");
      setConventions(response.data.template.conventions || "");
      setScope(response.data.template.scope || "");
      document.body.classList.remove("loading-indicator");
    } catch (error) {
      document.body.classList.remove("loading-indicator");
      console.log(error)
    }
  }

  useEffect(() => {
    fetchDescription();
  }, [templateId]);

  const validationSchema = Yup.object().shape({
    paragraph_text: Yup.string().required("Property Description is required"),
    conventions_text: Yup.string().required("Conventions are required"),
    scope_text: Yup.string().required("Scopes are required"),
  });

  const editorInitConfig = {
    height: 300,
    menubar: false,
    plugins: [
      'list', 'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'pagebreak'
    ],
    toolbar: 'bullist numlist | formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | fontsizeselect styleselect blockquote hr',
    toolbar_mode: 'sliding',
    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable'
  };

  return (
    <>
      <ScrollToTop />
      <NavbarDashboard />
      <div className="content-container Mcontent">
        <div className="side-spacing pt50">
          <div className="p-0">
            <h2 className="mb20">Inspection</h2>
          </div>
          <Formik
            initialValues={{
              paragraph_text: description,
              conventions_text: conventions,
              scope_text: scope,
              client_id,
              template_id,
              inspection_template_id,
            }}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({ values, touched, errors, handleSubmit, setFieldValue, handleChange }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="paragraph_text" className="mb10 fw-bold fs-4">
                    Property Description
                  </label>
                  <Field
                    as="textarea"
                    id="paragraph_text"
                    name="paragraph_text"
                    className="form-control"
                    rows={10}
                  />
                  {errors.paragraph_text && touched.paragraph_text && (
                    <small className="input-error">
                      {errors.paragraph_text}
                    </small>
                  )}
                </div>
                <br />
                <br />
                <EditorField
                  label="Conventions Used In This Report"
                  name="conventions_text"
                  value={values.conventions_text}
                  onChange={(content) => setFieldValue('conventions_text', content)}
                  error={errors.conventions_text}
                  touched={touched.conventions_text}
                  editorRef={conventionsEditorRef}
                  initConfig={editorInitConfig}
                />
                <EditorField
                  label="Scope"
                  name="scope_text"
                  value={values.scope_text}
                  onChange={(content) => setFieldValue('scope_text', content)}
                  error={errors.scope_text}
                  touched={touched.scope_text}
                  editorRef={scopeEditorRef}
                  initConfig={editorInitConfig}
                />
                <div className="text-center">
                  <button type="submit" className="btn btn-submit w-100">
                    Save
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

const EditorField = ({ label, name, value, onChange, error, touched, editorRef, initConfig }) => (
  <>
    <label className="mb10 fw-bold fs-4">{label}</label>
    <Editor
      onInit={(evt, editor) => editorRef.current = editor}
      apiKey={process.env.REACT_APP_TINY_MCE_KEY}
      value={value}
      onEditorChange={onChange}
      init={initConfig}
    />
    {error && touched && <small className="input-error">{error}</small>}
    <br /><br />
  </>
);

export default InspectionPropertyDescription;