import { Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import ScrollToTop from '../../ScrollToTop'

const ForgetPasswordForm = ({ submitPasswordForm }) => {
    const passwordValues = {
        password: ''
    }
    const validate = Yup.object().shape({
        password: Yup.string().required('Enter Password').min(8, "Password should be atleast 8 characters"),
        repeat_password: Yup.string()
            .test('passwords-match', 'Passwords must match', function (value) {
                return this.parent.password === value
            }),
    })

    const submitForm = (values) => {
        submitPasswordForm(values.repeat_password);
    }
    return (
        <>
            <ScrollToTop />
            <Formik
                initialValues={passwordValues}
                validationSchema={validate}
                onSubmit={submitForm}
            >
                {
                    (props) => {
                        const { values, handleSubmit, handleChange, errors, handleBlur, touched } = props;
                        return (
                            <form onSubmit={handleSubmit} className="form-wrapper login-form" >
                                <div className="auth-form-text">
                                    <h2> <u>Reset</u> Password</h2>
                                    <small>Great! Enter your password.</small>
                                </div><br />

                                <div className="input-custom form-group mb20">
                                    <input type="password" name="password" onChange={handleChange}
                                        value={values.password}
                                        onBlur={handleBlur}
                                        className="form-control "
                                        autoComplete="no-fill"
                                        placeholder="Password *" />

                                    {errors.password && touched.password && (<small className="input-error">{errors.password}</small>)}
                                </div>
                                <div className="input-custom form-group mb20">
                                    <input type="password" name="repeat_password" onChange={handleChange}
                                        // value={values}
                                        value={values.repeat_password}
                                        onBlur={handleBlur}
                                        className="form-control "
                                        autoComplete="no-fill"
                                        placeholder="Confirm Password *" />

                                    {errors.repeat_password && touched.repeat_password && (<small className="input-error">{errors.repeat_password}</small>)}
                                </div>
                                <br />
                                <button type="submit" className="btn btn-submit">Update Password <i className="fa fa-arrow-right "></i></button>
                            </form>
                        )
                    }
                }
            </Formik>
        </>
    )
}

export default ForgetPasswordForm
