import React from 'react'
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Slide } from 'react-toastify';
import ScrollToTop from '../../ScrollToTop';

const EnterCode = ({ email, setCode, next }) => {

    const validate = Yup.object().shape({
        reset_code: Yup.number().required('Enter Password reset code')
    })
    const codeVal = { reset_code: '' }

    const handleNext = () => {
        next();
    }

    const notify = (message, status) => {

        if (status) {
            toast.success(message, {
                theme: "dark"
            });
        }
        else {
            toast.error(message, {
                theme: "dark"
            });
        }
    };

    const submitForm = (values) => {

        const data = { ...values, email: email };
        setCode(values.reset_code);
        var verifyCodeData = {
            method: 'post',
            url: '/user/check-reset-code',
            data: data
        }
        axios(verifyCodeData)
            .then(res => {
                if (res.data.status === true) {
                    notify(res.data.message, true)
                    setTimeout(() => {
                        handleNext();
                    }, 2000)
                } else {
                    notify(res.data.message, false)
                }
            })
            .catch(err => {
                console.log(err, 'verify code resp error')
            })
    }

    return (
        <>
            <ScrollToTop />
            <Formik
                initialValues={codeVal}
                validationSchema={validate}
                onSubmit={submitForm}
            >
                {
                    (props) => {
                        const {
                            values,
                            handleChange,
                            handleSubmit,
                            handleBlur,
                            errors,
                            touched
                        } = props;


                        return (
                            <form onSubmit={handleSubmit} className="form-wrapper login-form" autoComplete="off" id="loginForm">
                                <div className="auth-form-text">
                                    <h2> <u>Reset</u> Password</h2>
                                    <small>Enter the password reset code.</small>
                                </div><br />

                                <div className="input-custom form-group mb20">
                                    <input type="number" name="reset_code" onChange={handleChange}
                                        value={values.reset_code ? values.reset_code : ''}
                                        onBlur={handleBlur}
                                        className="form-control "
                                        placeholder="Reset Code *" />

                                    {errors.reset_code && touched.reset_code && (<small className="input-error">{errors.reset_code}</small>)}
                                </div>
                                <br />
                                <button type="submit" className="btn btn-submit">Submit Code <i className="fa fa-arrow-right "></i></button>
                            </form>
                        )

                    }

                }

            </Formik>
            <ToastContainer
                position="bottom-right"
                autoClose={1900}
                transition={Slide}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    )
}

export default EnterCode
