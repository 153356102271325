import React from 'react'
import { Button } from 'react-bootstrap'

const TableRow = ({ data, handleOpen }) => {
  const { status, client } = data
  const { client_name, email } = client
  return (
    <tr>
      <td>
        {client_name}
      </td>
      <td>
        {email}
      </td>
      <td style={{ color: status === 1 ? "green" : status === 0 ? "blue" : "red" }}>
        {status === 1 ? "sent" : status === 0 ? "pending" : "fail"}
      </td>
      <td>
        <Button onClick={() => handleOpen(data)}>Show Details</Button>
      </td>
    </tr>

  )
}

export default TableRow