import * as PusherPushNotifications from '@pusher/push-notifications-web'
// export const getCategoryfromInspectionSteps = (response, stepName, tabName = 'description') => {
//     return response?.[stepName]?.[tabName]?.[0]?.id || null;
// }
export const debounce = (func, timeout= 1500) => {
    let timer
    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            func.apply(this, args)
        }, timeout);
    }

}
export function RegisterBeams(){
    let beamsClient
    
    window.navigator.serviceWorker.ready.then(serviceWorkerRegistration => {
        beamsClient = new PusherPushNotifications.Client({
            instanceId: 'a748be12-5e34-4c82-8023-940a90941cf9',
            serviceWorkerRegistration: serviceWorkerRegistration,
        })
    })
    return beamsClient
    
}

// export const beamsClient = new PusherPushNotifications.Client({
//   })