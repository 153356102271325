const authenticate = () => {
  let isAuthenticated = false;
  const access_token = localStorage.getItem("access_token");
  if (access_token !== undefined && access_token !== null) {
    isAuthenticated = true;
  }
  return isAuthenticated;
};

const isAuthenticated = () => {
  if (!authenticate()) {
    window.location.href = "/login";
  }
  return;
};

export function convertAuthUserResponseToReact(user) {
  //   console.log(user, "this is user data");
  const {
    first_name,
    last_name,
    country,
    city,
    profile_image,
    email,
    is_payment_done,
    created_at,
    user_type_id,
    id
  } = user;
  return {
    first_name,
    last_name,
    country,
    city,
    profile_image,
    email,
    is_payment_done,
    created_at,
    user_type_id,
    id
  };
}

export { isAuthenticated, authenticate };
