import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    notification: [],
    isRead: false
}

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        AddNotification: (state, payload) => {
            state.notification = [payload.payload, ...state.notification]
            state.isRead = false
        },
        RemoveNotification: (state, payload) => {
            state.notification = state.notification.filter(e => e.body !== payload.payload)
        },
        NotificationRead: (state, payload) => {
            state.isRead = payload.payload
        }
    }
})


export const { AddNotification, RemoveNotification, NotificationRead } = notificationSlice.actions
export default notificationSlice.reducer