import { Col, Card, Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { inspectionExistence } from "../../../redux/reducers/inspectionSlice";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { switchRedirection } from "../../../redux/reducers/inspectionSlice";

const Client = ({
  client,
  clientsPage,
  handleShow,
  handleDeleteActiveID,
  setClientId,
  handleWeatherDetailsPopup,
  handleDialogBoxTitle,
  handleDialogBoxStatus,
  handleBodyText,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const inspectionExistance = useSelector(
    (state) => state.inspection.checkInspectionExistanceData
  );
  const inspectionredirection = useSelector(
    (state) => state.inspection.redirection
  );

  useEffect(() => {
    if (inspectionExistance.status === true) {
      var inspection_id;

      if (
        inspectionExistance.inspection !== null &&
        inspectionExistance.inspection !== undefined
      ) {
        inspection_id = inspectionExistance.inspection.id;
      } else {
        inspection_id = inspectionExistance.inspection_id;
      }
      if (inspection_id === 0) {
        const client_id = inspectionExistance.client.id;
        if (inspectionredirection) {
          dispatch(switchRedirection(false));
          history.push(`/select-template/${client_id}`);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionExistance]);
  const client_id = client.id;

  const handleCircleText = (name) => {
    const splitedName = name.split(" ");
    var fLetter, lLetter;

    if (splitedName.length === 1) {
      fLetter = splitedName[0].charAt(0);
      lLetter = splitedName[0].charAt(splitedName[0].length - 1);
    }
    if (splitedName.length > 1) {
      fLetter = splitedName[0].charAt(0);
      lLetter = splitedName[splitedName.length - 1].charAt(0);
      if (lLetter === " ") {
        lLetter = splitedName[0].charAt(splitedName.length - 1);
      }
    }
    return [fLetter, lLetter];
  };

  return (
    <>
      <Col xs={12} md={12} className="mb20 px-0">
        <Card className="card-custom br10">
          <Card.Body className="position-relative text-start">
            <div className="circle-name float-start mr20 text-uppercase">
              {handleCircleText(client.client_name)}
            </div>
            <div className="card-mid-text">
              {client.client_name ? (
                <h6 className="fw-bold mb-0 ellipsis">{client.client_name}</h6>
              ) : (
                ""
              )}
              {client.email ? (
                <p className="text-black-50 mb-0 ellipsis">{client.email}</p>
              ) : (
                ""
              )}
              {client.address ? (
                <p className="text-black-50 mb-0 ellipsis">{client.address}</p>
              ) : (
                ""
              )}
            </div>
            {clientsPage === "clientList" && (
              <Dropdown className="dots-drop">
                <div className="position-relative">
                  <Dropdown.Toggle
                    variant=""
                    className="dotsImg"
                    id="dropdown-basic">
                    <img src="/assets/icon/dots.png" alt="" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="drp-shadow">
                    <Dropdown.Item
                      className="dropdown-item "
                      onClick={(e) => {
                        handleWeatherDetailsPopup(client_id);
                      }}>
                      <span>
                        <img src="/assets/icon/dpIcons/cloudy.png" alt="" />
                      </span>
                      Add Weather Details
                    </Dropdown.Item>
                    <Link
                      to={{
                        pathname: "/reports",
                        client_id,
                      }}
                      className="dropdown-item mr10"
                      data-rr-ui-dropdown-item
                      data="kappachino">
                      <span>
                        <img src="/assets/icon/dpIcons/report.png" alt="" />
                      </span>
                      Generate Report
                    </Link>

                    <Dropdown.Item
                      href="#"
                      className="dropdown-item mr10"
                      onClick={() =>
                        dispatch(inspectionExistence({ client_id, history }))
                      }>
                      <span>
                        <img src="/assets/icon/dpIcons/inspection.png" alt="" />
                      </span>
                      Start/Resume Inspection
                    </Dropdown.Item>

                    <Link
                      to={{
                        pathname: "/reports",
                        client_id,
                      }}
                      className="dropdown-item mr10"
                      data-rr-ui-dropdown-item>
                      <span>
                        <img src="/assets/icon/dpIcons/email.png" alt="" />
                      </span>
                      Email Report
                    </Link>
                    <Dropdown.Item
                      href="#"
                      className="dropdown-item mr10"
                      onClick={() =>
                        dispatch(
                          inspectionExistence({
                            client_id,
                            history,
                            from_review_inspection: true,
                          })
                        )
                      }>
                      <span>
                        <img
                          src="/assets/icon/dpIcons/call-center.png"
                          alt=""
                        />
                      </span>
                      Review Inspection
                    </Dropdown.Item>
                    <Link
                      to={`/clients/edit-client/${client.id}`}
                      className="dropdown-item mr10"
                      data-rr-ui-dropdown-item>
                      <span>
                        <img src="/assets/icon/dpIcons/edit.png" alt="" />
                      </span>
                      Edit Client
                    </Link>
                    <Dropdown.Item
                      href="#"
                      className="dropdown-item mr10"
                      onClick={(e) => {
                        handleDialogBoxTitle("Delete Client");
                        handleDialogBoxStatus(undefined);
                        handleDeleteActiveID(client.id);
                        handleBodyText("Are you sure?");
                        handleShow(e);
                      }}>
                      <span>
                        <img src="/assets/icon/dpIcons/user-del.png" alt="" />
                      </span>
                      Delete Client
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </div>
              </Dropdown>
            )}
            {clientsPage === "archiveClients" && (
              <button
                className="dots-drop btn dotsImg "
                type="button"
                onClick={(e) => {
                  handleShow(e);
                  setClientId(client.id);
                }}>
                <img
                  src="/assets/icon/dpIcons/refresh.png"
                  className="dotsImg"
                  alt=""
                />
              </button>
            )}
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default Client;
