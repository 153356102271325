import React, { useState } from 'react'
import FacebookLogin from '@greatsumini/react-facebook-login';
import { Modal, Button } from 'react-bootstrap';

const TestFacebookLogin = () => {
  const [showModal, setShowModal] = useState(false);
  const [pages, setPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);

  const handleSuccess = async (response) => {
    console.log('Login Success!', response);

    if (response.accessToken) {
      try {
        const pagesResponse = await fetch(`https://graph.facebook.com/v21.0/me/accounts?fields=name,picture{url},id,fan_count,category,access_token&access_token=${response.accessToken}`);
        const pagesData = await pagesResponse.json();

        if (pagesData.data && pagesData.data.length > 0) {
          setPages(pagesData.data);
          setShowModal(true);
        } else {
          console.log('No pages found for this user');
        }
      } catch (error) {
        console.error('Error fetching pages:', error);
      }
    }
  };

  const handlePageSelect = (page) => {
    setSelectedPage(page);
    setShowModal(false);
    console.log('Selected Page:', page);
    console.log('Page ID:', page.id);
    console.log('Page Access Token:', page.access_token);
    // Here you can perform any actions with the selected page and its access token
  };

  return (
    <>
      <FacebookLogin
        appId="837618783314847"
        onSuccess={handleSuccess}
        onFail={(error) => {
          console.log('Login Failed!', error);
        }}
        onProfileSuccess={(response) => {
          console.log('Get Profile Success!', response);
        }}
        scope="public_profile,email,pages_show_list,pages_read_engagement,publish_video,pages_messaging,pages_manage_metadata,pages_read_user_content,pages_manage_posts,pages_manage_engagement"
        fields="name,email,picture"
      />

<Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Select a Page</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {pages.map(page => (
            <Button 
              key={page.id} 
              onClick={() => handlePageSelect(page)}
              className="d-flex align-items-center w-100 mb-2 text-left"
              variant="outline-primary"
            >
              <img 
                src={page.picture?.data?.url || 'https://via.placeholder.com/50'} 
                alt={page.name} 
                style={{ width: '50px', height: '50px', marginRight: '10px', borderRadius: '50%' }}
              />
              <div>
                <strong>{page.name}</strong>
                <br />
                <small>ID: {page.id}</small>
              </div>
            </Button>
          ))}
        </Modal.Body>
      </Modal>

      {selectedPage && (
        <div>
          <h3>Selected Page:</h3>
          <p>Name: {selectedPage.name}</p>
          <p>ID: {selectedPage.id}</p>
          <p>Access Token: {selectedPage.access_token}</p>
        </div>
      )}
    </>
  )
}

export default TestFacebookLogin