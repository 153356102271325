import React from "react";
import { Col, Card } from "react-bootstrap";
// import { Link } from "react-router-dom";

const ReportRow = (props) => {
    // const { sourceLocation } = props
    const inspectionData = props.data;
    const date = inspectionData.inspection_report.created_date.split(" ")
    const handleCircleText = (name) => {
        const splitedName = name.split(" ");
        var fLetter, lLetter;

        if (splitedName.length === 1) {
            fLetter = splitedName[0].charAt(0);
            lLetter = splitedName[0].charAt(splitedName[0].length - 1);
        }
        if (splitedName.length > 1) {
            fLetter = splitedName[0].charAt(0);
            lLetter = splitedName[splitedName.length - 1].charAt(0);
            if (lLetter === "") {
                lLetter = splitedName[0].charAt(splitedName.length);
            }
            // console.log(splitedName, fLetter, lLetter, 'check if its an array - 1');
        }
        return [fLetter, lLetter];
    };
    return (
        <>
            <Col xs={12} md={12} className="pb25">
                <Card className="card-custom br10">
                    <Card.Body className="position-relative text-start row insp-list-ct">
                        <div className="col-md-2">
                            <div className="circle-name float-start text-uppercase">
                                {handleCircleText(inspectionData.client_name)}
                            </div>
                        </div>

                        <div className="col-md-4 pl0">
                            <div className="insp">
                                <h6 className="fw-bold mb-0 ellipsis">
                                    {inspectionData.client_name}
                                </h6>
                                <p className="text-black-50 mb-0 ellipsis">
                                    {inspectionData.email}
                                </p>
                                {/* <p className="text-black-50 mb-0 ellipsis">522,East - New York</p> */}
                            </div>
                        </div>
                        <div className="insp-details col-md-4 pr0">
                            {/* <label>Details</label> */}
                            <p className="text-black-50 mb-0 ellipsis">
                                Date:<strong>
                                    {` ${date[0]}`}
                                </strong>
                            </p>
                            <p className="text-black-50 mb-0 ellipsis">
                                Time:
                                <strong>
                                    {` ${date[1]}`}
                                </strong>
                            </p>
                        </div>
                        <div className="col-md-2 text-end ">
                            <div
                                onClick={() => window.open(inspectionData.inspection_report.url)}
                                className="cursor-pointer"
                            >
                                <img src='/assets/icon/download.png' style={{ height: 32, width: 32, cursor: 'pointer' }} alt="" />
                            </div>

                        </div>
                        {/* <div className="col-md-2 text-end ">
              <div
                onClick={() => window.open(inspectionData.pdf)}
                className="cursor-pointer"
              >
                <img src='/assets/icon/download.png' style={{ height: 32, width: 32, cursor: 'pointer' }} alt="" />
              </div>
            </div> */}
                    </Card.Body>
                </Card>
            </Col>
        </>
    );
};

export default ReportRow;
