import { messaging, getToken } from "./Firebase";

export const getFcmToken = () => {
  return getToken(messaging, { vapidKey: process.env.REACT_APP_KEY_PAIR })
    .then((currentToken) => {
      if (currentToken) {
        localStorage.setItem("fcm_token", currentToken);
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token ", err);
    });
};
