import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'

const MyAccountSkeleton = () => {
    return (
        <>
            <Row className="side-spacing pt50 ">
                <div className="profHead mb50 d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center ">
                        <div className="profImgCt noB">
                            <Skeleton className="profile-img" style={{ backgroundColor: 'none !important' }} />
                        </div>
                        <div className="ml20">
                            <h2 className="m-0"> <Skeleton height='35px' width='200px' /> </h2>
                            <p className="mb-1"> <Skeleton height='20px' width='200px' /> </p>
                        </div>
                    </div>
                    <Skeleton style={{ width: '50px', height: '40px', padding: '.375rem .75rem' }} />
                </div>
                <Col xs={12} md={12} className="pb25 p-0">
                    <Row>
                        <Col xs={12} md={6} >
                            <Card className="mb-4">
                                <div className="acc-header" >
                                    <Card.Title className="mb-0"><Skeleton /></Card.Title>
                                </div>

                            </Card>
                            <Card className="mb-4">
                                <div className="acc-header"  >
                                    <Card.Title className="mb-0"><Skeleton /></Card.Title>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={12} md={6}>
                            <Card className="mb-4">
                                <div className="acc-header " >

                                    <Card.Title className="mb-0"><Skeleton /></Card.Title>

                                </div>


                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
            
        </>
    )
}

export default MyAccountSkeleton
