import { Row, Col, Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ChoosePlan from "../ChoosePlan";
import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";
// import FirstTimeSubscription from "./FirstTimeSubscription";
// import BillingStatusBar from "./BillingStatusBar";
import './Billing.css'
const choosePlan = (planId) => {

  document.body.classList.add("loading-indicator");
  const token = localStorage.getItem("access_token");

  var data = new FormData();
  data.append("plan_id", planId.id);
  const setPlan = {
    method: "post",
    url: "/v1/oauth/getPaymentIntentSubscription",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  axios(setPlan)
    .then((res) => {
      document.body.classList.remove("loading-indicator");
      localStorage.setItem("payInt", res.data.paymentIntent);
      window.location.href = `/checkout/${res.data.paymentIntent}`;
    })
    .catch((err) => {
      document.body.classList.remove("loading-indicator");
      console.log(err);
    });
};

const BillingInformation = ({ authStatus }) => {
  const { created_at, is_payment_done } = JSON.parse(localStorage.getItem("userData"))
  const days_since_created = moment().unix() - moment(created_at, "YYYY-MM-DD").unix()
  const trial_ended = days_since_created > 2419200
  const days_since_created_days = moment(created_at, "YYYY-MM-DD").fromNow()
  let number_of_days = days_since_created_days.split(" ")
  number_of_days = number_of_days[0]
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem("access_token");
    const getSubscription = {
      method: "get",
      url: "/v1/oauth/recentTransactions",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(getSubscription)
      .then((res) => {
        setTransactions(res.data.trnsactions);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [plan, setPlan] = useState({

  });
  if (plan) {

    var PlanDays = plan.id === "1" || plan.id === "2" ? "30" : "365";
  }
  const notify = (message, status) => {
    if (status) {
      toast.success(message, {
        theme: "dark",
      });
    } else {
      toast.error(message, {
        theme: "dark",
      });
    }
  };
  return (
    <>
      <Container>
        <div className="content-container Mcontent">
          <Row className="pt20" >
            <h1 style={{ fontSize: 24, display: 'inline-flex', justifyContent: 'center' }}>Purchase Subscription</h1>
            <p style={{ fontSize: 15, display: 'inline-flex', justifyContent: 'center' }}>
              Please, complete your payment before logging in the system
            </p>
          </Row>
          {!trial_ended && (

            <Row >
              <p className="d-flex justify-content-end"> Free trial ends in: {" "} <strong> {" " + 30 - number_of_days} days</strong> </p>
            </Row>
          )}
          <ChoosePlan activeChoosePlan={plan} onChoose={(e) => setPlan(e)} />
          <Row>
            <h6 className="text-justify" style={{ marginTop: 30, textAlign: 'justify', textJustify: 'inter-word' }}>Please note that your new subscription to ProNspect comes with a 30-day free trial. If for any reason you decide to cancel your subscription during that 30-day period, your card will not be charged.</h6 >
            <div className="mb10 ">
              <div className="float-end position-relative">
                <Link
                  to={{
                    pathname: "/billinghistory",
                    state: { transactions, loading },
                  }}
                  className="ml20 text-decoration-none greylight"
                >
                  {" "}
                  <h4>
                    Billing History

                  </h4>
                </Link>
              </div>
            </div>

            {/* <BillingStatusBar /> */}

            <Row className="pt50">
              <div
                className="container"
                style={{
                  borderRadius: 15,
                  backgroundColor: "#FBF1C8",
                }}
              >

                {plan && (


                  <div className="container" style={{ maxWidth: "600px" }}>
                    <Row className=" pt10 ">
                      <div>
                        <Col md={{ span: 6, offset: 4 }}>
                          <h4 className="BillingText">Billing Cycle</h4>
                        </Col>
                      </div>
                    </Row>
                    {/* prettier-ignore */}
                    <div style={{ border: "2px solid white", marginBottom: 20 }}></div>
                    <Row className="mt10 mb10">
                      <Col md={8}>
                        <h4 className="BillingText">
                          {plan.nickname
                          }

                        </h4>
                        {/* {!authStatus && ( */}
                          <p className="BillingText" style={{ fontSize: "12px" }}>
                          Your Next payment will be on {" "}
                          {moment().add(`${PlanDays}`, "days").calendar()}
                        </p>
                          {/* )} */}
                      </Col>
                      <Col md={{ span: 2, offset: 2 }}>
                        <div className="float-end position-relative">
                          <h4 className="BillingText">${plan.amount / 100}</h4>
                        </div>
                      </Col>
                    </Row>

                    {/* {!loading && transactions.length === 0 ? (
                  <>
                    <div style={{ border: "2px solid white", marginBottom: "20px" }}></div>
                    <FirstTimeSubscription plan={plan} />
                  </>
                ) : null} */}
                  </div>
                )}
              </div>
            </Row>

            <div
              style={{
                flex: 1,
                justifyContent: "center",
                alignContent: "center",
              }}
              className="d-grid gap-2 mt50 mb100"
            >
              <Button
                style={{
                  marginBottom: 100,
                }}
                onClick={() => {
                  if (is_payment_done === 1 && !trial_ended) {
                    notify(`You are currently on Free Trial Period, You can subscribe after the free trial period is over`)
                  } else {
                    choosePlan(plan)
                  }

                }}
                variant={authStatus ? `warning disabled` : "warning"}
                size="lg"
                className="ml20"
              >
                Confirm Plan
              </Button>
            </div>
          </Row>
        </div>
      </Container>
      <ToastContainer
        position="bottom-right"
        autoClose={10000}
        transition={Slide}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default BillingInformation;
