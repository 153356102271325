import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'

const Footer = () => {

    return (
        <>
            <div className='mt50 pt160 footer-background' >
                <Row className="d-flex justify-content-around">
                    {/* <Col md={1}></Col> */}

                    <Col md={6}>

                        <ul className=" footer-menu">
                            {/* <li> <a href="/#about-us"> About Us</a></li> */}
                            <li><Link to="/terms-of-use"> Terms & Condition </Link></li>
                            <li><Link to="/privacy-policy"> Privacy Policy </Link></li>

                            {/* <li><a href="/#support"> Support </a></li> */}
                        </ul>

                    </Col>
                    <Col md={3}>
                        <div className="footer-social-icons d-flex justify-content-end flex-column">

                            <strong>Mobile Apps</strong>
                            <div className='mt50' style={{ display: 'flex', flexDirection: 'column' }}>

                                <img onClick={() => window.open("https://play.google.com/store/apps/details?id=com.cybussolutions.hititpro")} style={{ cursor: 'pointer', margin: 10 }} src='/assets/pronspect/google.png' alt="google play" />
                                <img onClick={() => window.open("https://apps.apple.com/us/app/pronspect/id1584339246")} style={{ cursor: 'pointer', margin: 10 }} src='/assets/pronspect/apple.png' alt="ios" />
                            </div>


                        </div>
                    </Col>
                    {/* <Col md={1}></Col> */}
                </Row>
                <br />
                <br />
                <Row className='d-flex justify-content-start flow-row'>
                    {/* <Col md={1}></Col> */}
                    <Col md={6}>
                        <div className="d-flex justify-content-center">
                            {/* <h1 className="mb-0">ProNspect</h1> */}
                            <div>

                                <p> &copy; 2022 Copyright Reserved | pronspect</p>
                            </div>
                        </div>
                    </Col>

                    <Col md={6} className="d-flex justify-content-end">
                        <div className="footer-social-icons" style={{ paddingLeft: 100 }}>

                            <img src="/assets/footer-fb.png" alt="" />
                            <img src="/assets/footer-tw.png" alt="" />
                            <img src="/assets/footer-in.png" alt="" />

                        </div>
                    </Col>
                    {/* <Col md={1}></Col> */}
                </Row>
            </div>
        </>
    );

}

export default Footer;