import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { Button } from 'react-bootstrap'
import ChoosePlan from '../Hooks/ChoosePlan'

const BillingMobile = () => {
    let { id } = useParams()
    const [plans, setPlans] = useState('')
    const [loading, setLoading] = useState(true)
    const [choose, setChoose] = useState({})
    const RetrievePlans = () => {
        const token = id;
        const getPlans = {
            method: "get",
            url: "/v1/oauth/retrievePlans",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        axios(getPlans)
            .then((res) => {
                document.body.classList.remove("loading-indicator");

                setPlans(res.data.plans);
                setLoading(false);
            })
            .catch((err) => {
                document.body.classList.remove("loading-indicator");
            });
    };
    // const checkUser = () => {
    //     const token = id;
    //     const getPlans = {
    //         method: "post",
    //         url: "/v1/oauth/private",
    //         headers: {
    //             Authorization: `Bearer ${token}`,
    //         },
    //     };
    //     axios(getPlans)
    //         .then((res) => {
    //             console.log(res.data)
    //         })
    //         .catch((err) => {
    //             // window.location.href = "/pagenotfound"
    //         });
    // };

    useEffect(() => {
        document.body.classList.add("loading-indicator");
        // checkUser()
        RetrievePlans()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div>
            <div style={{ display: 'block', justifyContent: 'center' }}>


                <h1 style={{ textAlign: 'center' }}>

                    Billing Information
                </h1>

                <h3 className="text-center billing-text mt20">Billing Cycle</h3>
                {!loading && (
                    plans.map(plan => (

                        <div key={plan.amount_decimal} style={{ display: 'inline-flex', justifyContent: "space-evenly" }}>
                            <div onClick={() => setChoose(plan)} style={{ height: 200, width: 150, borderRadius: 20, marginTop: 40, backgroundColor: '#FBF1C8', border: plan.id === choose.id ? "2px solid black" : "", margin: 10, justifyContent: 'center' }}>
                                <h3 className="billing-text pt20 text-center">{plan.interval_plan}</h3>
                                <h5 className="billing-text pt20 text-center">{(plan.amount_decimal / 100) + " $"}</h5>
                                <p className="billing-text pt20 text-center">{plan.nickname}</p>
                            </div>
                        </div>
                    ))

                )}
                <div style={{ height: 150, width: "93%", paddingRight: "8%", paddingLeft: "5%", paddingTop: 35, backgroundColor: '#FBF1C8', margin: 10, }}>
                    <div>
                        <h3 className="text-center billing-text">Final invoice</h3>
                    </div>
                    <div style={{ width: "100%", marginTop: 30, display: 'inline-flex', justifyContent: 'space-between' }}>

                        <h4 className="billing-text">Grand Total</h4>
                        <h5 className="billing-text">{choose.amount_decimal ? "$" + choose.amount_decimal / 100 : "$0"}</h5>
                    </div>

                </div>
                <div className='mt140'>
                    <Button
                        style={{
                            width: "80%"
                        }}
                        onClick={() => ChoosePlan(choose.id, id)}
                        variant="warning"
                        size="lg"
                        className="ml20"
                        disabled={choose.amount_decimal ? false : true}
                    >
                        <h5 className="billing-text">

                            Pay Now
                        </h5>
                    </Button>
                </div>
            </div>

        </div>
    )
}

export default BillingMobile