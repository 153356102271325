import React, { useState } from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
import axios from 'axios'

const AdminModal = ({ show, handleClose, userData, notify }) => {
  const userToken = window.localStorage.getItem("access_token")
  const [password, setPassword] = useState('')

  const changePassword = () => {
    var data = new FormData()
    data.append("email", userData.email)
    data.append("id", userData.id)
    data.append("password", password)
    const newPassword = {
      method: "post",
      url: "v1/oauth/user/change_password_by_admin",
      headers: {
        Authorization: `Bearer ${userToken}`
      },
      data: data
    }
    axios(newPassword).then(res => {
      if (res.data.status === true) {
        notify(`The password of ${userData.email} has been changed`, true)
      } 
      handleClose()
    }).catch(err => {
      if (err.response.status === 422) {
        notify("Something went wrong", false)
      }
      handleClose()
    })
  }
  return (
    <div>
      <>


        <Modal centered show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title >Change Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label style={{ marginLeft: "1rem" }}>Enter New Password</Form.Label>
                <Form.Control style={{ width: "80%", marginLeft: "1rem" }} type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="warning" onClick={changePassword}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  )
}

export default AdminModal