import { Row, Col } from 'react-bootstrap';
import ScrollToTop from './ScrollToTop';
import NavbarTop from './NavbarTop';
import Footer from './Footer';

const TermsOfUse = () => {
    return <>
        <ScrollToTop />
        <NavbarTop />
        <div className="signIn-container">
            <Row className="">
                <Col md={4} className="login-img-container ">
                    <img src="/assets/header-image.png" className="" alt="" />
                </Col>

                <Col md={8} >
                    <div className="auth-form-container privacy-policy-container">
                        <div className="auth-form-text">
                            <div className="mb-3">
                                <h2> Terms Of Use</h2>
                                <small>Updated 5/2/2022</small>
                            </div>
                            <p>
                                This Terms of Use (“TOU” or “Agreement”) is a legally binding contract between ProNspect, LLC (“ProNspect”) and you, as a user or subscriber to ProNspect sites and/or services. Our Privacy Policy and Cookie Policy are incorporated into this Agreement by reference.
                            </p>
                            <p>
                                The following terms and conditions govern all use of ProNspect.com, including, but not limited to the ProNspect Resource Center, ProNspect Blog, and Collectively, these resources will be referred to as “sites” or “websites”.
                            </p>
                            <p>
                                ProNspect, Inc. (“ProNspect”) offers businesses and individuals access to home inspection report writing software, available at or through our website. Collectively, these resources will be referred to as “Services” in this Agreement.
                            </p>
                            <p>
                                By using or accessing this website and/or services, you agree to be bound by the terms, policies and guidelines incorporated in the terms of use by reference, including, but not limited to ProNspect’s Privacy Policy (aka this “Agreement”). This agreement is legally binding and conditions your use of services and sites. If you do not agree to all the terms and conditions of this Agreement, you are directed to discontinue accessing or otherwise using the website, services or any materials obtained from it.
                            </p>
                            <p>
                                You may access the Sites or Services with a free or trial account under certain circumstances. While access to certain portions of the Sites and Services are free, ProNspect reserves the right to charge fees for services, features and benefits associated with the Sites or Services at any time.
                            </p>
                            <p>
                                A “Subscriber” or “Customer” shall be referred to any user who pays for or uses any portion of the Sites or Services.
                            </p>
                            <p><strong> Account Registration</strong></p>
                            <p>
                                By registering for ProNspect Services and creating an account, you (this includes any agent designated by you operating on your behalf) agree to provide us with complete and accurate information and to keep this information up to date. This may include, but is not limited to, your name, email address, home inspection association affiliation, telephone number, company name, company website, directory listings, social media profiles, and password.
                            </p>
                            <p>You are solely responsible for activities that occur under or through your account, including actives initiated by third parties, whether or not such activities are authorized by you. Should you grant a third party to act on your behalf, you shall ensure that third party is bound by, and abides by, the terms of this Agreement. You agree to immediately notify ProNspect of any unauthorized use of your account or any breach of your account security.</p>
                            <p>By using the Sites or Services, you acknowledge that ProNspect may access your account or information associated with your account to provide maintenance or support, for security related reasons, or for any other business purpose.</p>
                            <p><strong> Subscription Fees & Terms </strong></p>
                            <p>ProNspect may offer a range of subscription tiers or plans within its Services (each a “Plan”). Each plan includes a different set or Services that may be provided in the given month of the subscription period. A Plan is selected as part of your registration for ProNspect Services, which may begin as a Free Trial. Each Plan will have a monthly subscription period, starting on the day of payment. You will be billed for the Plan monthly and you are responsible for the subscription fees for the entire period chosen.</p>
                            <p>At the end of your current subscription period, your Plan will automatically renew for a subscription period equal to your prior subscription period unless you provide ProNspect with email notice (at support@pronspect.com) of your intent not to renew or your intent to upgrade or downgrade your Plan at least thirty (30) days prior to the end of the then-current subscription period.</p>
                            <p>You can view details of your Plan, including pricing and the end date of your subscription period, by logging into your account on the ProNspect website and clicking on the “Account” page.</p>
                            <p><strong> Billing Policies </strong></p>
                            <p className="text-center fs20" style={{ width: '90%', margin: 'auto' }}>During the first 30 days following registration you may cancel for any reason and you will receive a full refund of the first month service and the one-time setup fee.</p>


                            <ol>
                                <li><p className="mb-0"> Subscription fees for your Plan are billed in advance of each billing cycle. </p></li>
                                <li><p className="mb-0">No refunds or credits will be provided for partial or unused months or days of service in the event of cancellation of your Plan.</p></li>
                                <li><p className="mb-0">If you upgrade your Plan, you will immediately be charged for the cost of the upgraded Plan, minus a pro-rated credit to reflect the remaining duration of your subscription period. Your billing period will reset to begin at the time of upgrade. You will be charged the full amount of the new and current Plan, as provided on our Pricing page, beginning with your next billing cycle.</p></li>
                                <li><p className="mb-0">Downgrades go into effect on the next billing cycle.</p></li>
                                <li><p className="mb-0"> Any special offers or discounts on Services or previous subscriptions may not apply to a renewed subscription, including automatic renewals.</p></li>
                            </ol>

                            <p><strong> Free Trial </strong></p>



                            <p>Your subscription to the Services may begin with a free trial in which you have access to the Sites and Services for a previously agreed upon period.</p>
                            <p>At the conclusion of your free trial period, unless you provide written notice of cancellation, your account will be billed in accordance with the Policies stated above.</p>
                            <p>ProNspect reserves the right to modify, extend, shorten, cancel or limit the free trial period without notice at any time.</p>

                            <p><strong> Changes in Subscription Fees </strong></p>
                            <p>We reserve the right to change fees for the Sites and Services at any time or charge additional fees or costs.</p>
                            <p>Changes will be reflected in the first billing cycle that occurs more than thirty (30) days after we provide notice of our new fees or costs. If you have upgraded or downgraded prior to this notice, you will be charged at the then-current rate.</p>
                            <p><strong> Payment of Fees </strong></p>
                            <p>As an express condition of using ProNspect’s Sites and Services, you agree to pay all subscription fees associated with your Plan, including any additional services, and any applicable taxes associated with your Plan and Services. You acknowledge that amounts billed may vary due to promotional offers, changes to account and Pricing structure and changes to associated taxes, and you authorize ProNspect to bill you accordingly.</p>
                            <p>You acknowledge that ProNspect reserves the right to terminate your access to Sites and Services for failure to pay fees associated with your Plan and Services.</p>
                            <p><strong> Credit Card Payments </strong></p>
                            <p>All subscription Plan fees will be billed to the credit card you provide us with. You authorize the card issuer to pay any amounts described and agreed upon within your Plan. You acknowledge such charges will continue until the account is terminated or these amounts have been paid in full.</p>
                            <p>You agree to provide current, complete and accurate billing and credit card information. You agree to promptly update card numbers, expiration dates and billing address to keep your account current and accurate. You must contact ProNspect if your card is lost or stolen.</p>
                            <p>You authorize ProNspect to determine updated or replacement expiration dates for your credit card in the event your credit card expires. You acknowledge and agree that ProNspect reserves the right to charge any replacement or renewal card issued to you to the same extent that we charged the expired credit card. If your subscription Plan fees are not paid by your credit card issuer, you agree to pay all amounts agreed to and due immediately. You agree to pay all costs of collection efforts, including attorney fees & costs.</p>
                            <p><strong> Termination of Services </strong></p>
                            <p>Your subscription Plan may be cancelled at any time and will take effect at the end of the current billing cycle.</p>


                            <p>ProNspect is not obligated in any way to refund whole or part of your subscription fees in the event of termination of Services. You will still have access to the Sites and Services you have paid for until the end of your subscription period. You may lose access prior to the end of your subscription period if your account is not in good standing or you violate any of the terms in this Agreement.</p>
                            <p><strong> General Disclaimers </strong></p>
                            <p>You acknowledge the information, Sites, Services, reports included in or available through ProNspect Services and Sites may include inaccuracies or typographical errors.</p>

                            <p><strong> Third Party Websites & Links </strong>Third party websites (“Third Party Websites”) refer to any reference or link to an external website, app, document, resource, quote, advice or recommendation not owned, operated or affiliated with ProNspect, Inc or subsidiaries.</p>

                            <p>ProNspect Services and Sites may contain links to third party websites. Access and use of third party websites is at your own risk and ProNspect is not responsible for the accuracy or reliability of any information, data, opinions, statements or advice on third party sites that you access from ProNspect Sites or Services. Third party sites are not controlled, owned or operated by ProNspect, and ProNspect is not liable for any damage or losses caused or alleged to be caused by or in connection with reliance or use of any third-party site. Any external links to third party sites does not imply an endorsement or recommendation in any way.</p>

                            <p><strong> User Indemnification of Personal Website(s) </strong></p>

                            <p>You acknowledge and agree to indemnify and hold ProNspect and its directors, officers, agents, partners, subsidiaries and employees harmless from any loss, liability, claim or demand, including reasonable attorney fees, made by any third party due to or arising out of any content appearing or published on your website. ProNspect makes no claim to the accuracy, reliability, results or outcome of posting content or data you submit or publish on your website(s).</p>
                            <p>ProNspect makes no claim or guarantee, express or implied, to the effectiveness of any information, reports, data, content, advice or recommendations on any of the Sites or Services.</p>

                            <p><strong> Liability Limitations </strong></p>

                            <p>ProNspect does not guarantee the completeness, usefulness, or accuracy of any information on the Sites or Services. ProNspect does not endorse or accept responsibility for the accuracy or reliability of any opinion, statement or advice made by any party that appears on the Sites or provided by the Services.</p>
                            <p>
                                You acknowledge and agree that ProNspect and it’s officers, directors, agents, subsidiaries, partners and employees will be not liable to you or any third party for any Special, Indirect, Punitive, Incidental or Consequential (including, but not limited to, lost profits or lost data collected through the Sites or Services) damages, whether based on a claim or action of contract, negligence, strict liability, or other tort, breach of any statutory duty, even if ProNspect has been advised of the possibility of such damages.
                            </p>
                            <p>ProNspect Warranties, Limitations & Representations ProNspect provides Services “as-is” and provides no express warranties, guarantees, or conditions related to the Sites or Services. ProNspect disclaims any implied or statutory warranties including those of quality, performance, non-infringement, merchantability, fitness for a particular purpose, and including those arising by usage or trade, course of dealing, or performance.</p>
                            <p>ProNspect does not warrant that the Sites or Services will be free from errors, virus-free, or that services be uninterrupted.</p>
                            <p>You acknowledge and warrant to ProNspect that you (i) own all rights, title and interest in and to the URLs of your website; (ii) own all rights necessary to publish all of the content on or through your website.</p>

                            <p><strong> Service Failures </strong></p>
                            <p>User acknowledges and agrees that ProNspect does not guarantee, and shall have no liability for, any Sites or Services downtime, including, but not limited to; (i) any downtime or failures caused by previously scheduled maintenance to ProNspect servers, equipment, or any other device by which ProNspect depends to deliver Services, (ii) any downtime or failure caused by internet network, servers, or service providers, (iii) any downtime or failures caused by your network, systems, local service providers or equipment, or (iiii) any downtime or failures caused by events out of ProNspect’s control, such as government actions, labor conditions, natural disasters, or interruptions in internet services or connectivity to an area where ProNspect or ProNspect servers are located.</p>
                        </div>
                    </div>

                </Col>
            </Row>
        </div>


        < Footer />
    </>
}

export default TermsOfUse