import React, { useEffect, useState, useCallback } from "react";
import ScrollToTop from "../../ScrollToTop";
import NavbarDashboard from "../NavbarDashboard";
import { Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { setValue } from "../../../redux/reducers/templateSlice";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

const TemplateSelection = () => {
  const dispatch = useDispatch();
  const [defaultTemplate, setDefaultTemplate] = useState(null);
  const [customTemplates, setCustomTemplates] = useState([]);
  const [hasMoreRecords, setMoreRecords] = useState(true);
  const [clientExists, setClientExists] = useState(true);
  const [page, setPage] = useState(1);
  let token = localStorage.getItem("access_token");
  const params = useParams();
  const history = useHistory();

  const fetchTemplates = useCallback(async () => {
    const listSize = customTemplates.length;
    const config = {
      method: "get",
      url: `/v1/oauth/templates/list?listSize=${listSize}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { listSize: listSize }
    };

    try {
      const response = await axios(config);
      if (response.data.status === true) {
        const allTemplates = response.data.data;
        
        // Separate default and custom templates
        const defaultTemp = allTemplates.find(temp => temp.id === 1);
        const newCustomTemp = allTemplates.filter(temp => temp.id !== 1);
        
        if (defaultTemplate === null) {
          setDefaultTemplate(defaultTemp);
        }

        // Only add new templates that are not already in the list
        const updatedCustomTemplates = [...customTemplates, ...newCustomTemp.filter(
          newTemplate => !customTemplates.some(
            existingTemplate => existingTemplate.id === newTemplate.id
          )
        )];

        setCustomTemplates(updatedCustomTemplates);
        setPage(prevPage => prevPage + 1);
        setMoreRecords(newCustomTemp.length > 0);
      } else {
        setMoreRecords(false);
        setClientExists(false);
      }
    } catch (error) {
      console.error("Error fetching templates:", error);
    } finally {
      document.body.classList.remove("loading-indicator");
    }
  }, [token, customTemplates, defaultTemplate]);

  useEffect(() => {
    fetchTemplates();
  }, [token]);

  const handlePagination = () => {
    if (clientExists) {
      document.body.classList.add("loading-indicator");
      fetchTemplates();
    }
  };

  const handleDispatch = (id) => {
    dispatch(setValue(id));
    history.push(`/property-description/${params.id}`);
  };

  const TemplateItem = ({ data }) => (
    <div
      key={data.id}
      onClick={() => handleDispatch(data.id)}
      className="d-flex justify-content-between align-items-center mb-2 mt-1 mx-1"
      style={{
        boxShadow: "0px 0px 5.8px 0px rgba(0, 0, 0, 0.25)",
        padding: "16px",
        borderRadius: "8px",
        cursor: "pointer"
      }}>
      <div>{data.title}</div>
    </div>
  );

  return (
    <>
      <ScrollToTop />
      <NavbarDashboard />
      <div className="content-container Mcontent">
        <Row className="side-spacing pt50">
          <div className="mb20 list-header border-bottom">
            <h2 className="float-start">Select Template</h2>
          </div>

          <div className="mb-4">
            <h3>Default Template</h3>
            {defaultTemplate && <TemplateItem data={defaultTemplate} />}
          </div>

          <div>
            <h3>Custom Templates</h3>
            <InfiniteScroll
              dataLength={customTemplates.length}
              next={handlePagination}
              hasMore={hasMoreRecords}
              // loader={<h4>Loading...</h4>}
            >
              {customTemplates.map((data) => (
                <TemplateItem key={data.id} data={data} />
              ))}
            </InfiniteScroll>
          </div>
        </Row>
      </div>
    </>
  );
};

export default TemplateSelection;