import React from "react";
import ScrollToTop from "../../ScrollToTop";
import NavbarDashboard from "../NavbarDashboard";
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";

const Success = () => {
  localStorage.setItem("paymentStatus", true);
  const history = useHistory()
  const notify = (message, status) => {
    if (status) {
      toast.success(message, {
        theme: "dark",
      });
    } else {
      toast.error(message, {
        theme: "dark",
      });
    }
  };
  setTimeout(() => {
    notify("Your plan has been activated", true)
    history.push("/dashboard");
  }, 2000);

  return (
    <>
      <ScrollToTop />
      <NavbarDashboard />
      <div
        style={{
          marginTop: 300,
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        You'll be redirected soon....
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        transition={Slide}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Success;
