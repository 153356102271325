import React from 'react'
import { useParams } from 'react-router';
import AddClient from './AddClient'

const EditClient = () => {
    const { id } = useParams();

    return (
        <>
            <AddClient formType="edit" idOfEdittedClient={id} />
        </>
    )
}

export default EditClient
