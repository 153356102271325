const paidUser = () => {
  let isPaid = false;
  const access_token = localStorage.getItem("access_token");
  const payment_done = localStorage.getItem("paymentStatus");
  const userData = localStorage.getItem("userData");


  if (access_token !== undefined && access_token !== null) {
    if (userData.is_payment_done === "true" || payment_done === "true") {
      isPaid = true
    }
  }
  return isPaid;
};

export const paymentIntent = (id) => { };

const isPaid = () => {
  const payment_Int = localStorage.getItem("payInt");
  const payURL = "/checkout/" + payment_Int;
  // console.log(window.location.pathname === payURL);
  if (
    window.location.pathname === "/plans" ||
    window.location.pathname === "/dashboard" ||
    window.location.pathname === "/my-account" ||
    window.location.pathname === "/billinghistory" ||
    window.location.pathname === "/my-account/change-password" ||
    window.location.pathname === payURL ||
    window.location.pathname === "/success"
  ) {
    return;
  }
  if (!paidUser()) {
    window.location.href = "/plans";
  }
  return;
};

export { paidUser, isPaid };
