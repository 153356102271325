import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NavbarTop = ({ text }) => {
    const ButtonText = text ? 'Dashboard' : 'Login'

    return (

        <Navbar fixed="top" className="justify-content-between" expand="lg" variant="light" bg="white" >
            {/* <Navbar.Brand className="nav-logo" href="/#home"> <h1 className="mb-0"><b> HISCO </b></h1></Navbar.Brand> */}
            <div className='d-flex'>
                < Link className="d-flex nav-logo navbar-brand" to="/" >
                    <img className='navbar-icon' alt='navIcon' src='/assets/mainicon.png' />
                    <h1 className="mb-0 ml20 pt10 Navbar-text"><b> ProNspect </b></h1>
                </Link>
            </div>
            <div className="d-flex align-items-center nav-menu-container-parent">
                <ul className="list-unstyled nav-menu-container">

                    <li><a href="#/">FAQ</a></li>
                    <li><a href="#/">Blog</a></li>
                </ul>

                <Link to={text ? "/dashboard" : "/login"} className="login-btn "> {ButtonText}</Link>
            </div>
        </Navbar >

    );

}

export default NavbarTop;