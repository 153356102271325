// import React, { Component } from 'react'
import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const QuickHelp = () => {
  return (
    <>
      <Row className="side-spacing quick-help">
        <div className="mb-5">
          <h2>Quick Help</h2>
        </div>
        <Col md={4}>
          <Link
            to="/my-account/edit-profile"
            style={{ textDecoration: "none", color: "Black" }}
          >
            <Card className="card-custom br10 text-start">
              <Card.Body className="custom-card-content">
                <img src="/assets/edit.png" alt="" />
                <div>
                  <h5 className="mb-0"> Edit Profile </h5>
                  {/* <p className="m-0">Lorem ipsum dolor sit amet, consect.</p> */}
                </div>
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col md={4}>
          <Link to="/plans" style={{ textDecoration: "none", color: "Black" }}>
            <Card className="card-custom br10 text-start">
              <Card.Body className="custom-card-content">
                <img src="/assets/Group 1697.png" alt="" />
                <div>
                  <h5 className="mb-0"> Payments | Billing </h5>
                  {/* <p className="m-0">Lorem ipsum dolor sit amet, consect.</p> */}
                </div>
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col md={4}>
          <Link
            to="/my-account/change-password"
            style={{ textDecoration: "none", color: "Black" }}
          >
            <Card className="card-custom br10 text-start">
              <Card.Body className="custom-card-content">
                <img src="/assets/synchronize.png" alt="" />
                <div>
                  <h5 className="mb-0"> Change Password </h5>
                  {/* <p className="m-0">Lorem ipsum dolor sit amet, consect</p> */}
                </div>
              </Card.Body>
            </Card>
          </Link>
        </Col>
      </Row>
    </>
  );
};

export default QuickHelp;
