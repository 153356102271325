import React from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import moment from 'moment'

const EmailModel = ({
    show,
    handleClose,
    logData
}) => {
    const { payload_json, status, client, failure_json, updated_at } = logData
    const { client_name, email } = client
    const parsedData = JSON.parse(payload_json)
    const { cc, bcc } = parsedData
    let parsedError
    if (failure_json !== null || failure_json !== undefined) {
        parsedError = JSON.parse(failure_json)
    }
    const formatedDateAndTime = moment(updated_at).format("MMMM DD, YYYY HH:mm")
    return (
        <Modal
            enforceFocus={false}
            size="md"
            show={show}
            onHide={handleClose}
            className="custom-Modal"
            centered
        >
            <Modal.Header closeButton>
                <h4>{client_name}</h4>
            </Modal.Header>
            <Modal.Body>
                <Row>

                    <Row><Col><h6>Status</h6></Col><Col xs={8} style={{ color: status === 1 ? "green" : status === 0 ? "blue" : "red" }}>{status === 1 ? "sent" : status === 0 ? "pending" : "fail"}</Col></Row>
                    <Row>
                        <Col>
                            <h6>
                                Genrated At
                            </h6>
                        </Col>
                        <Col xs={8}>{formatedDateAndTime}</Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6>
                                Error
                            </h6>
                        </Col>
                        <Col xs={8}>{parsedError !== null ? parsedError : ''}</Col>
                    </Row>
                    <Row>
                        <Col><h6>Client Email</h6> </Col>
                        <Col xs={8}>{email}</Col>
                    </Row>
                    <Row>
                        <Col><h6>CC</h6> </Col>

                        <Col xs={8}>
                            {cc === null || cc === undefined || typeof(cc) !== 'object' ? '' : cc.map(e => {
                                return <Row key={e}>{e}</Row>
                            })}
                        </Col>
                    </Row>
                    <Row>
                        <Col><h6>BCC</h6> </Col>
                        <Col xs={8}>
                            {typeof(bcc) === 'object' ? bcc?.map(e => {
                                return <Row key={e}>{e}</Row>
                            }): ''}
                        </Col>
                    </Row>
                </Row>
            </Modal.Body>
            {/* <Modal.Footer>
                <Button>Click</Button>
            </Modal.Footer> */}

        </Modal>
    )
}

export default EmailModel