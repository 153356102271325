import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import axios from "axios";
import Step from "./steps/Step";
import Header from "./steps/Header";
import ViewPopup from "../InspectionSteps/StepsData/ViewPopup";
import Footer from "./steps/Footer";
import DialogBox from "../../../../utils/DialogBox";
import "./steps/Step.css";
import ScrollToTop from "../../../ScrollToTop";
import NavbarDashboard from "../../NavbarDashboard";
import { useSelector } from "react-redux";
import { Modal,Button } from "react-bootstrap";
import { Editor } from '@tinymce/tinymce-react';
const ReviewInspection = () => {
  var token = localStorage.getItem("access_token");
  const [showVeiwPopup, setShowVeiwPopup] = useState(false);
  const [data, setData] = useState({});
  const [stepName, setStepName] = useState("");
  const [category, setCategory] = useState("");
  const [summary, setSummary] = useState(null);
  const [monitor, setMonitor] = useState(null);
  const [showDescPopup, setShowDescPopup] = useState(false);
  const [description, setDescription] = useState("");
  const [scope, setScope] = useState("");
  const [convention, setConvention] = useState("");
  const [dialogBoxStatus, setDialogBoxStatus] = useState("");
  const [tempId, setTempId] = useState(null);
  const [style, setStyle] = useState("text");
const [newIds, setNewIds] = useState({});
const [counter,setCounter] = useState(0);
  const handleShowPopupClose = () => {
    setShowDescPopup(false);
  };
  const handleShowPopupShow = () => {
    setShowDescPopup(true);
  };

  const handleViewPopupClose = () => {
    setShowVeiwPopup(false);
  };
  const handleViewPopupShow = () => {
    setShowVeiwPopup(true);
  };
  const { id, clientId } = useParams();
  const [reviewInspection, setReviewInspection] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (reviewInspection) {
      var getTempId = {
        method: "post",
        url: "/v1/oauth/inspection/checkIfInspectionExists",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { client_id: clientId },
      };
      axios(getTempId).then((res) => {
        setTempId(res.data.template.id);
        setConvention(res.data.template.conventions);
        setScope(res.data.template.scope);
      });
    }
  }, [reviewInspection, clientId, token]);

  useEffect(() => {
    document.body.classList.add("loading-indicator");
    var getImage = {
      method: "post",
      url: "v1/oauth/getReportByInspectionId",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { inspection_id: id },
    };
    axios(getImage)
      .then((res) => {
        setReviewInspection(res.data);
        setLoading(false);
        setDialogBoxStatus("desc");
     
        setDescription(res.data.inspection.description);
        document.body.classList.remove("loading-indicator");
      })
      .catch((err) => {});
  }, [id, token,counter]);


  useEffect(() => {
    if (reviewInspection) {
      const myComments = [];
      reviewInspection.steps.forEach((step) => {
        step.inspection_step_categories.forEach((category) => {
          if (category.type === "recommendation") {
            category.inspection_category_checks_checked.forEach((check) => {
              check.inspection_category_checks_images.forEach((radio) => {
                myComments.push({
                  comment: radio.image_comments,
                  myRadio: radio.selrecomd,
                });
              });
            });
          }
        });
      });
      setSummary(myComments);
    }
  }, [reviewInspection]);


  // useEffect(() => {
  //   if (reviewInspection) {
  //     const myComments = [];
  //     reviewInspection.steps.forEach((step) => {
  //       step.recomendation.checks.forEach((label) => {
  //         label.labels_details.forEach((radio) => {
  //           if (radio.comments) {
  //             myComments.push({
  //               comment: radio.comments,
  //               myRadio: radio.radio,
  //             });
  //           }
  //         });
  //       });
  //     });
  //     setSummary(myComments);
  //   }
  // }, [reviewInspection]);

  useEffect(() => {
    if (summary) {
      const Safety_Issue = summary.filter(
        (e) => e.myRadio === "Safety issue" || e.myRadio === "Safety Issue"
      );
      const Concern = summary.filter((e) => e.myRadio === "Concern");
      const Repair = summary.filter((e) => e.myRadio === "Repair");
      const Improve = summary.filter((e) => e.myRadio === "Improve");
      const Monitor = summary.filter((e) => e.myRadio === "Monitor");

      setMonitor([Concern, Safety_Issue, Repair, Improve, Monitor]);
    }
  }, [summary]);

  const changeStyle = () => {
    if (style === "text") {
      setStyle("underline");
    }
    if (style === "underline") {
      setStyle("text");
    }
  };
  const handleViewPopupGetValues = (values,ids) => {
    document.body.classList.add("loading-indicator");
    var requiredVals = {
      inspection_id: ids.inspection_id,
      inpsection_category_check_id: ids.label_id,
    };
    const configAxios = {
      method: "post",
      url: "v1/oauth/inspection/get-comment-images",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: requiredVals,
    };
    axios(configAxios)
      .then((res) => {
        if (res.data.status === true) {
          document.body.classList.remove("loading-indicator");
          appendNewDataToCategory(res.data, values);
        }
      })
      .catch((err) => {
        document.body.classList.remove("loading-indicator");
      });
  };

  const appendNewDataToCategory = (FetchedData, info) => {
    const newImagesArray = FetchedData.images.map((img) => ({
      url: img,
      id: Math.floor(Math.random() * 1000000),
    }));
    const reviewData = { ...reviewInspection };
    const stepIndex = reviewInspection.steps.findIndex(
      (step) => step.stepId === stepName
    );
    if (FetchedData.selrecomd) {
      const categoryIndex = reviewInspection.steps[
        stepIndex
      ].recomendation.checks.findIndex((check) => check.header_id === category);
      const labelIndex = reviewInspection.steps[stepIndex].recomendation.checks[
        categoryIndex
      ].labels_details.findIndex((label) => label.label === info.label);

      const myArray = reviewData;
      let labels_details =
        myArray.steps[stepIndex].recomendation.checks[categoryIndex]
          .labels_details[labelIndex];
      labels_details.comments = FetchedData.comment;
      labels_details.radio = FetchedData.selrecomd;
      labels_details.image = newImagesArray;
      setReviewInspection(myArray);
    } else {
      const myArray = reviewData;
      const obs_details =
        myArray.steps[stepIndex].observation.checks[0].labels_details[0];
      obs_details.comments = FetchedData.comment;
      obs_details.image = newImagesArray;
    }
  };
  const handleChanges = (info, newIds) => {

    handleViewPopupGetValues(info,newIds);
  };

  const handlePopupCommentImageApi = (data, Newdata, tabName) => {
    let images = data.uploadImages;
    document.body.classList.add("loading-indicator");
    var formDataValues = new FormData();
    formDataValues.append("selrecomd", data.radioChecked);
    formDataValues.append("inspection_id", newIds.inspection_id);
    formDataValues.append("step_name", stepName);
    formDataValues.append("category", category);
    formDataValues.append("label", data.label);
    formDataValues.append("checked", data.checked);
    formDataValues.append("price", data.price);
    formDataValues.append("inpsection_category_check_id", newIds.label_id);
    formDataValues.append("inspection_step_id", newIds.inspection_step_id);
    formDataValues.append(
      "inpsection_step_category_id",
      newIds.inspection_step_category_id
    );
    formDataValues.append("comment", data.comment);
    formDataValues.append("call_from", "web");
    for (var i = 0; i < images.length; i++) {
      formDataValues.append("images[]", images[i]);
    }

    const config = {
      method: "post",
      url: "/v1/oauth/inspection/save-comment-images",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data: formDataValues,
    };
    axios(config)
      .then((res) => {
        if (res.data.status === true) {
          setCounter(counter + 1);
          document.body.classList.remove("loading-indicator");
          setTimeout(() => {
            handleChanges(data,newIds);
          }, 2000);
          handleViewPopupClose();
        } else if (res.data.status === false) {
          document.body.classList.remove("loading-indicator");
        }
      })
      .catch((err) => {
        document.body.classList.remove("loading-indicator");
      });
  };

  const handleInspectionDescription = () => {
    setDialogBoxStatus("desc");
    handleShowPopupShow();
  };
  // const inspection_template_id = useSelector((state) => state.template.value);
  const SaveDescriptionChanges = (text,type) => {
    const config = {
      method: "post",
      url: "/v1/oauth/inspection/update-property-description",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        client_id: clientId,
        paragraph_text: type === 'description' ? text : description,
        convention_text: type === 'convention' ? text : convention,
        scope_text: type === 'scope' ? text : scope,
      },
    };
    axios(config).then((res) => {
      if (type === 'description') {
        setDescription(text);
      } else if (type === 'convention') {
        setConvention(text);
      } else if (type === 'scope') {
        setScope(text);
      }
      handleShowPopupClose();
      handleConventionModalClose();
      handleScopeModalClose();
    });
  };
  const [showConventionModal, setShowConventionModal] = useState(false);
  const [showScopeModal, setShowScopeModal] = useState(false);
  const handleConventionModalOpen = () => setShowConventionModal(true);
const handleConventionModalClose = () => setShowConventionModal(false);

const handleScopeModalOpen = () => setShowScopeModal(true);
const handleScopeModalClose = () => setShowScopeModal(false);
  return (
    <>
      {!loading && (
        <div className="side-spacing">
          <ScrollToTop />
          <NavbarDashboard />
          <Header
            client={reviewInspection.client}
            inspection={reviewInspection.inspection}
            user={reviewInspection.user}
            client_id={clientId}
          />
          <div className="text-end ">
            <small>© 2022 Cybus Solutions</small>
            <h1>Report Overview</h1>
          </div>

          <div>
            <h5 className="text-uppercase">THE HOUSE IN PERSPECTIVE</h5> <hr />
            <p
              className={style}
              onMouseEnter={changeStyle}
              onMouseLeave={changeStyle}
              onClick={handleInspectionDescription}
              dangerouslySetInnerHTML={{ __html: description }}
            >
            
            </p>
          </div>
          <div>
            <h5 className="text-uppercase">CONVENTIONS USED IN THIS REPORT</h5>{" "}
            <hr />
            <p  className={style}
              onMouseEnter={changeStyle}
              onMouseLeave={changeStyle}
              onClick={handleConventionModalOpen}
              dangerouslySetInnerHTML={{ __html: convention }}>
           
            </p>
            {/* <ul>
              <li>
                <strong>Safety Issue:</strong> &nbsp; denotes a condition that
                is unsafe and in need of prompt attention.
              </li>
              <li>
                <b>Concern:</b> &nbsp; a system or component which is considered
                significantly deficient or is unsafe. Significant deficiencies
                need to be corrected and, except for some safety items, are
                likely to involve significant expense.
              </li>

              <li>
                <strong>Repair:</strong> &nbsp; denotes a system or component
                which is missing or which needs corrective action to assure
                proper and reliable function.
              </li>
              <li>
                <strong>Improve:</strong> &nbsp; denotes improvements which are
                recommended but not required.
              </li>
              <li>
                <strong>Monitor:</strong> &nbsp; denotes a system or component
                needing further investigation and/or monitoring in order to
                determine if repairs are necessary.
              </li>
            </ul> */}
          </div>
          <div>
            <h5 className="text-uppercase">SUMMARY</h5> <hr />
          </div>
          <div>
            <h5 className="text-uppercase">THE SCOPE OF THE INSPECTION</h5>{" "}
            <hr />
            <p
            className={style}
            onMouseEnter={changeStyle}
            onMouseLeave={changeStyle}
            onClick={handleScopeModalOpen}
            dangerouslySetInnerHTML={{ __html: scope }}>
         
            </p>
            <span>
              Please refer to the pre-inspection contract for a full explanation
              of the scope of the inspection.
            </span>
          </div>
          <div>
            <h1>Summary</h1>
          </div>
          {monitor && (
            <>
              {monitor[1].length > 0 && <h4>Safety Issues</h4>}

              {monitor[1].length > 0 &&
                monitor[1].map((e) => (
                  <ul key={Math.random()}>
                    <li>{e.comment}</li>
                  </ul>
                ))}
            </>
          )}
          {monitor && (
            <>
              {monitor[0].length > 0 && <h4>Concern</h4>}

              {monitor[0].length > 0 &&
                monitor[0].map((e) => (
                  <ul key={Math.random()}>
                    <li>{e.comment}</li>
                  </ul>
                ))}
            </>
          )}

          {monitor && (
            <>
              {monitor[2].length > 0 && <h4>Repair</h4>}
              {monitor[2].length > 0 &&
                monitor[2].map((e) => (
                  <ul key={Math.random()}>
                    <li>{e.comment}</li>
                  </ul>
                ))}
            </>
          )}
          {monitor && (
            <>
              {monitor[3].length > 0 && <h4>Improve</h4>}
              {monitor[3].length > 0 &&
                monitor[3].map((e) => (
                  <ul key={Math.random()}>
                    <li>{e.comment}</li>
                  </ul>
                ))}
            </>
          )}
          {monitor && (
            <>
              {monitor[4].length > 0 && <h4>Monitor</h4>}
              {monitor[4].length > 0 &&
                monitor[4].map((e) => (
                  <ul key={Math.random()}>
                    <li>{e.comment}</li>
                  </ul>
                ))}
            </>
          )}

          {reviewInspection.steps.map((step, index) => {
            return (
              <>
                <Step
                  key={step}
                  portfolio={reviewInspection.steps[index]}
                  handleShow={handleViewPopupShow}
                  handleData={setData}
                  handleCategory={setCategory}
                  handleStepName={setStepName}
                  handleCoverImage={setReviewInspection}
                  StepData={reviewInspection}
                  handleNewIds={setNewIds}
                />
                <Footer
                  key={Math.random()}
                  step={step.title}
                  stepId={step.id}
                />
              </>
            );
          })}
        </div>
      )}
      {showVeiwPopup && (
        <ViewPopup
          handleClose={handleViewPopupClose}
          show={showVeiwPopup}
          data={data}
          tabName={data.tabName}
          handlePopupCommentImageApi={handlePopupCommentImageApi}
          handleChanges={handleChanges}
          source="review"
        />
      )}
      {showDescPopup && (
        <DialogBox
          handleClose={handleShowPopupClose}
          show={showDescPopup}
          data={description}
          title="Property Description"
          status={dialogBoxStatus}
          handleDescription={SaveDescriptionChanges}
          source="review"
        />
      )}
      <ConventionModal 
      show={showConventionModal}
      handleClose={handleConventionModalClose}
      convention={convention}
      saveChanges={SaveDescriptionChanges}
    />

    <ScopeModal 
      show={showScopeModal}
      handleClose={handleScopeModalClose}
      scope={scope}
      saveChanges={SaveDescriptionChanges}
    />
    </>
  );
};

export default ReviewInspection;




const ConventionModal = ({ show, handleClose, convention, saveChanges }) => {
  const editorRef = useRef(null);
  const [editorContent, setEditorContent] = useState(convention);

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const handleSave = () => {
    if (editorRef.current) {
      const content = editorRef.current.getContent();
      saveChanges(content, "convention");
      handleClose();
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Conventions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Editor
          onInit={(evt, editor) => editorRef.current = editor}
          apiKey={process.env.REACT_APP_TINY_MCE_KEY}
          initialValue={convention}
          onEditorChange={handleEditorChange}
          init={{
            height: 500,
            menubar: false,
            plugins: [
              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
              'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
            ],
            toolbar: 'undo redo | blocks | ' +
              'bold italic forecolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button  className="m-0 w-100px btn-submit " onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ScopeModal = ({ show, handleClose, scope, saveChanges }) => {
  const editorRef = useRef(null);
  const [editorContent, setEditorContent] = useState(scope);

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const handleSave = () => {
    if (editorRef.current) {
      const content = editorRef.current.getContent();
      saveChanges(content, "scope");
      handleClose();
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Scope of Inspection</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Editor
          onInit={(evt, editor) => editorRef.current = editor}
          apiKey={process.env.REACT_APP_TINY_MCE_KEY}
          initialValue={scope}
          onEditorChange={handleEditorChange}
          init={{
            height: 500,
            menubar: false,
            plugins: [
              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
              'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
            ],
            toolbar: 'undo redo | blocks | ' +
              'bold italic forecolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button  className="m-0 w-100px btn-submit " onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};