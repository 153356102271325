import { useState } from 'react';
import axios from 'axios';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { Slide } from 'react-toastify';
import { convertAuthUserResponseToReact } from '../../../utils/Auth';

const SignInLogic = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    let user_Token_init;

    const initialValues = {
        email: "",
        password: ""
    }
    const notify = (message, status) => {
        if (status) {
            toast.success(message, {
                theme: "dark"
            });
        }
        else {
            toast.error(message, {
                theme: "dark"
            });
        }
    };

    const toastContainer = () => {
        return (<ToastContainer
            position="bottom-right"
            autoClose={1900}
            transition={Slide}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />)
    }

    const validate = Yup.object({
        email: Yup.string().email('Invalid Email Address')
            .required('Email required'),
        password: Yup.string().required('Password required')
    })

    const handleFormSubmit = () => {
        document.body.classList.add("loading-indicator");
        if (process.env.REACT_APP_ISPROD !== "false") {
            var data =
            {
                "username": email,
                "password": password,
                "grant_type": process.env.REACT_APP_GRANT_TYPE,
                "client_id": process.env.REACT_APP_CLIENT_ID,
                "client_secret": process.env.REACT_APP_CLIENT_SECRET
            }
        } else {

            var FormData = require('form-data');
            data = new FormData();
            data.append('username', email);
            data.append('password', password);
            data.append('grant_type', process.env.REACT_APP_GRANT_TYPE);
            data.append('client_id', process.env.REACT_APP_CLIENT_ID);
            data.append('client_secret', process.env.REACT_APP_CLIENT_SECRET);
        }

        axios({
            method: 'post',
            url: '/v1/oauth/token',
            data: data
        })

            .then(function (response) {
                user_Token_init = response.data.access_token

                if (response.data.access_token !== undefined) {
                    localStorage.setItem('access_token', user_Token_init);

                }

                var getUser = {
                    method: "get",
                    url: "/v1/oauth/user/get-user",
                    headers: {
                        Authorization: `Bearer ${response.data.access_token}`,
                    },
                };
                axios(getUser).then(res => {
                    document.body.classList.remove("loading-indicator");
                    if (res.data.status === true) {
                        if (process.env.NODE_ENV) {
                            localStorage.setItem("paymentStatus", true)
                        }
                        let user = convertAuthUserResponseToReact(res.data.user)
                        window.localStorage.setItem("userData", JSON.stringify(user))
                        if (user.is_payment_done !== 1) {
                            window.location.href = "/plans"
                        } else {
                            localStorage.setItem("paymentStatus", true);
                            if (res.data.user.user_type_id === 1) {
                                window.localStorage.setItem("adminData", JSON.stringify(user))
                                localStorage.setItem('Stoken', user_Token_init);
                                window.location.href = "/admin"
                            } else {
                                window.location.href = "/dashboard";
                            }
                        }
                    }
                })
            })
            .catch(function (error) {
                document.body.classList.remove("loading-indicator");
                if (error.request.status === 400) {
                    const errorMsg = 'Wrong Credentials!';
                    notify(errorMsg, false);
                } else {
                    notify("Something went wrong!", false)
                }
            });
    }

    return { initialValues, validate, handleFormSubmit, setEmail, setPassword, toastContainer }

}

export default SignInLogic
