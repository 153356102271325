import React from "react";
import { Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const InspectionRow = (props) => {
  // const { sourceLocation } = props
  const inspectionData = props.data;
  const handleCircleText = (name) => {
    const splitedName = name.split(" ");
    var fLetter, lLetter;

    if (splitedName.length === 1) {
      fLetter = splitedName[0].charAt(0);
      lLetter = splitedName[0].charAt(splitedName[0].length - 1);
    }
    if (splitedName.length > 1) {
      fLetter = splitedName[0].charAt(0);
      lLetter = splitedName[splitedName.length - 1].charAt(0);
      if (lLetter === "") {
        lLetter = splitedName[0].charAt(splitedName.length);
      }
      // console.log(splitedName, fLetter, lLetter, 'check if its an array - 1');
    }
    return [fLetter, lLetter];
  };
  return (
    <>
      <Col xs={12} md={12} className="pb25">
        <Card className="card-custom br10">
          <Card.Body className="position-relative text-start row insp-list-ct">
            <div className="col-md-2">
              <div className="circle-name float-start text-uppercase">
                {handleCircleText(inspectionData.client_name)}
              </div>
            </div>

            <div className="col-md-4 pl0">
              <div className="insp">
                <h6 className="fw-bold mb-0 ellipsis">
                  {inspectionData.inspection_name}
                </h6>
                <p className="text-black-50 mb-0 ellipsis">
                  Client Name: {inspectionData.client_name}
                </p>
                {/* <p className="text-black-50 mb-0 ellipsis">522,East - New York</p> */}
              </div>
            </div>
            <div className="insp-details col-md-4 pr0">
              <label>Details</label>
              <p className="text-black-50 mb-0 ellipsis">
                Added by: {inspectionData.added_by}
              </p>
              <p className="text-black-50 mb-0 ellipsis">
                Added on: {inspectionData.date}
              </p>
            </div>
            <div className="col-md-2 text-end ">

              <Link
                to={`/inspection/${inspectionData?.id
                  ? inspectionData.id
                  : inspectionData?.inspection_id
                  }/inspection-steps/${inspectionData.client_id}`}
                className="cursor-pointer"
              >
                <img src="/assets/edit.png" alt="" />
              </Link>

            </div>
            {/* <div className="col-md-2 text-end ">
              <div
                onClick={() => window.open(inspectionData.pdf)}
                className="cursor-pointer"
              >
                <img src='/assets/icon/download.png' style={{ height: 32, width: 32, cursor: 'pointer' }} alt="" />
              </div>
            </div> */}
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default InspectionRow;
