import React from 'react'
import { Card } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'

const ClientSkeleton = () => {
    return (
        <Card className="card-custom br10">
            <Card.Body className="position-relative text-start">
                <div className="circle-skel-br float-start mr20">
                    <Skeleton circle={true} className=" circle-name-skel" />
                </div>
                <div className="card-mid-text ">
                    <h6 className="fw-bold mb-0 ellipsis"><Skeleton /></h6>
                    <p className="text-black-50 mb-0 ellipsis"><Skeleton /></p>
                    <p className="text-black-50 mb-0 ellipsis"><Skeleton /></p>
                </div>
            </Card.Body>
        </Card>
    )
}

export default ClientSkeleton
