import React, { useEffect, useState } from "react";
import axios from "axios";
import { Row } from "react-bootstrap";
import ScrollToTop from "../../ScrollToTop";
import NavbarDashboard from "../NavbarDashboard";
import InspectionRow from "./InspectionRow";
import ClientSkeleton from "../clients/ClientSkeleton";
import InfiniteScroll from 'react-infinite-scroll-component'

const InspectionList = () => {
  let token = localStorage.getItem("access_token");

  const [inspectionExists, setInspectionExists] = useState(true);
  const [inspectionList, setInspectionList] = useState([]);
  const [hasMoreRecords, setMoreRecords] = useState(true)
  const [isLoading, setIsLoading] = useState(true);

  const [searchText, setSearchText] = useState("");

  const handleInspectionList = async (listsize) => {
    let dataConfig;
    setIsLoading(true);

    if (listsize) {
      dataConfig = {
        method: "post",
        url: "/v1/oauth/inspection/get-inspections",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          listSize: listsize,
        },
      };
    } else {
      dataConfig = {
        method: "post",
        url: "/v1/oauth/inspection/get-inspections",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
    await axios(dataConfig)
      .then((res) => {
        document.body.classList.remove("loading-indicator");
        if (res.data.status === true) {
          setInspectionList([...inspectionList, ...res.data.inspections]);
        } else {
          setInspectionExists(false);
          setMoreRecords(false)
        }
        setIsLoading(false);
      })
      .catch((err) => {
        document.body.classList.remove("loading-indicator");
      });
  };

  useEffect(() => {
    setTimeout(() => {
      handleInspectionList();
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePagination = async () => {
    document.body.classList.add("loading-indicator");
    if (inspectionExists) {
      handleInspectionList(inspectionList.length);
    }
  };
  const handleShowSearchInput = () => {
    const inputSearch = document.getElementById("searchField");
    if (document.getElementById("searchField").classList.contains("d-none")) {
      document.getElementById("searchField").classList.remove("d-none");
      inputSearch.focus()
    } else {
      document.getElementById("searchField").classList.add("d-none");
    }
  };
  const handleHideSearchInput = (e) => {
    document.getElementById("searchField").classList.add("d-none");
  };


  return (
    <>
      <ScrollToTop />
      <NavbarDashboard />
      <div className="content-container Mcontent" >
        <Row className="side-spacing pt50 " onMouseLeave={handleHideSearchInput}>
          <div className="mb20 list-header">
            <h2 className=" float-start">Inspection List</h2>
            <div className="position-relative float-end search-list">
              <img
                src="/assets/icon/search.png"
                className="ml20 cursor-pointer"
                alt=""
                onClick={handleShowSearchInput}
              />
              <input
                type="text"
                className="form-control d-none search-input"
                placeholder="Search Inspection"
                value={searchText}
                id="searchField"
                // onMouseEnter={handleHideSearchInput}
                onMouseLeave={handleHideSearchInput}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          </div>
          {isLoading && <ClientSkeleton />}
          {inspectionList && (


            <InfiniteScroll
              dataLength={inspectionList.length}
              next={handlePagination}
              hasMore={hasMoreRecords}
              // loader={<h4>Loading...</h4>}
              endMessage={<h4 style={{ marginLeft: 30, fontSize: 17, color: 'grey', fontWeight: 'bold' }}>All records have been fetched </h4>}

            >
              {inspectionList
                .filter((val) => {
                  if (searchText === "") {
                    return val;
                  } else if (
                    val.inspection_name
                      .toLowerCase()
                      .includes(searchText.toLowerCase()) ||
                    val.client_name
                      .toLowerCase()
                      .includes(searchText.toLowerCase()) ||
                    val.added_by
                      .toLowerCase()
                      .includes(searchText.toLowerCase()) ||
                    val.date.toLowerCase().includes(searchText.toLowerCase())
                  ) {
                    return val;
                  }
                  return false;
                }).map((inspection) => {
                  return <InspectionRow key={inspection.id} data={inspection} />
                })}
            </InfiniteScroll>
          )}
        </Row>
      </div>
    </>
  );
};

export default InspectionList;
