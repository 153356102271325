import React, { useState, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, Button, Row, Col, Form, Card, ListGroup } from "react-bootstrap";
import { ReactMultiEmail } from 'react-multi-email2'
import 'react-multi-email2/style.css'
import { debounce } from "./utills";
import { useHistory } from "react-router-dom";


const DialogBox = (props) => {
  const history = useHistory()
  const {
    show,
    handleClose,
    title,
    bodyText,
    handlePopupId,
    data,
    status,
    handleEmailReport,
    handleSelectedClient,
    handleWeatherUpdationApi,
    handleDescription,
    source,
    handleCancelation,
    setQuery
  } = props;

  const [description, setDescription] = useState(data);

  const [errorStart, setStartError] = useState("");
  const [errorEnd, setEndError] = useState("");

  const [showCcForm, setCCForm] = useState(false)
  const [showBccForm, setBccForm] = useState(false)
  if (data.email_from) {
    var inspectorEmail = [data.email_from]
  }

  const [ccEmails, setCcEmails] = useState([])
  const [BccEmails, setBccEmails] = useState(inspectorEmail)

  const startTimeStamp = data.start_date + " " + data.start_time;
  const endTimeStamp = data.end_date + " " + data.end_time;

  const [startDate, setStartDate] = useState(
    data.start_date ? new Date(data.start_date) : ""
  );
  const [endDate, setEndDate] = useState(
    data.end_date ? new Date(data.end_date) : ""
  );
  const [startTime, setStartTime] = useState(
    data.start_time ? new Date(startTimeStamp) : ""
  );
  const [endTime, setEndTime] = useState(
    data.end_time ? new Date(endTimeStamp) : ""
  );



  const [weatherConditions, setWeatherConditions] = useState(
    data?.weather_condition
  );

  const emailTo = data?.email_to;
  const emailFrom = data?.email_from;

  const [emailText, setEmailText] = useState(' ');

  // const [selectedClient, setSelectedClient] = useState("Select Client");

  const [searchMode, setSearchMode] = useState(false)

  const inputRef = useRef()

  const handleSubmit = (data) => {
    handleEmailReport(data);
  };

  // // FOR CLIENT LISTING
  // const handleSelectClient = (value) => {
  //   setSelectedClient(value);
  // };
  // const handleClientSubmit = (id) => {
  //   handleSelectedClient(id);
  // };

  const myStyle = {};

  const changeInputHandler = debounce(() => {
    const searchQuery = inputRef.current.value
    setQuery(searchQuery)
  })

  const NavigateToClient = () => {
    const searchQuery = inputRef.current.value
    history.push('/clients', searchQuery)
  }
  return (
    <>
      <Modal show={show} onHide={handleClose} className="custom-Modal">
        <Modal.Header closeButton className="pl0 pr0">
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {status === "emailReport" ? (
            <>
              <div className="input-custom form-group">
                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                  <div>
                    <label htmlFor="email_to" className="mb-2">
                      To
                    </label>
                  </div>
                  <div >

                    <div >
                      <label style={{ cursor: 'pointer' }} onClick={() => setCCForm(true)} >Cc</label>
                      <label style={{ marginLeft: 10, cursor: 'pointer' }} onClick={() => setBccForm(true)}>Bcc</label>

                    </div>
                  </div>
                </div>
                <br />
                <input
                  type="email"
                  name="email_to"
                  id="email_to"
                  className="form-control"
                  value={emailTo}
                  readOnly
                />
              </div>
              {showCcForm && (
                <div className="input-custom form-group">
                  <label htmlFor="email_from" className="mb-2">
                    Cc
                  </label>
                  <ReactMultiEmail
                    style={myStyle}
                    emails={ccEmails}
                    onChange={_emails => {
                      setCcEmails(_emails);
                    }}
                    getLabel={(
                      email,
                      index,
                      removeEmail,
                    ) => {
                      return (
                        <label key={index}>
                          {email}
                          <img style={{ height: 12, width: 12, marginRight: 15, marginLeft: 5 }} src="/assets/close.png" alt="remove" onClick={() => removeEmail(index)} />
                        </label>
                      );
                    }}
                  />
                </div>
              )}
              {showBccForm && (


                <div className="input-custom form-group">
                  <label htmlFor="email_from" className="mb-2">
                    Bcc
                  </label>
                  <ReactMultiEmail
                    style={myStyle}
                    emails={BccEmails}
                    onChange={_emails => {
                      setBccEmails(_emails);
                    }}
                    getLabel={(
                      email,
                      index,
                      removeEmail,
                    ) => {
                      return (
                        <label key={index}>
                          {email}
                          <img style={{ height: 12, width: 12, marginRight: 15, marginLeft: 5 }} src="/assets/close.png" alt="remove" onClick={() => removeEmail(index)} />
                        </label>
                      );
                    }}
                  />
                </div>
              )}
              <div className="input-custom form-group">
                <label htmlFor="email_from" className="mb-2">
                  From
                </label>
                <br />
                <input
                  type="email"
                  name="email_from"
                  id="email_from"
                  className="form-control"
                  value={emailFrom}
                  readOnly
                />
              </div>

              <div className="input-custom form-group">
                <label htmlFor="email_text" className="mb-2">
                  Text
                </label>
                <br />
                <textarea
                  className="form-control"
                  rows="4"
                  id="email_text"
                  value={emailText}
                  onChange={(e) => setEmailText(e.target.value)}
                >
                  {emailText}
                </textarea>
              </div>
            </>
          ) : status === "Cancelation" ?
            <>
              <div>Are you sure you want to cancel subscription?</div>
            </>
            : status === "selectClient" ? (
              <>
                <div className="rep-dropdown-ct">
                  {data[0]?.id === undefined && data.length === undefined ? (
                    <small style={{ color: "red" }}>
                      Active client list empty. please add a client first!
                    </small>
                  ) : (
                    <div style={{ width: "100%" }}>
                      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>

                        <div style={{ width: "100%" }}>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            ref={inputRef}
                            style={{ border: '1px solid black' }}
                            onClick={() => setSearchMode(true)}
                            onBlur={() => setSearchMode(false)}
                            // value={searchText}
                            id="searchField"
                            // onMouseLeave={handleHideSearchInput}
                            onChange={() => changeInputHandler()}
                          />
                        </div>
                      </div>
                      <div style={{maxHeight: 500}}>
                      {searchMode || 1 ? (
                        
                        <Card style={{ width: '100%' }}>
                        {/* <Card.Header>Featured</Card.Header> */}
                        <ListGroup variant="flush">
                          {data[0]?.id !== undefined
                          ? data.map((client, index) => {
                            if (index >3){
                              return null
                            }else{
                              return <ListGroup.Item onClick={() => handleSelectedClient(client.id)} style={{cursor: 'pointer'}} key={client.id}>{client.client_name}</ListGroup.Item>
                            }
                          }
                            )
                            : ""}
                        </ListGroup>
                      </Card>
                            ): null}
                            </div>
                      {/* <select
                        style={{ display: searchMode ? 'none' : '' }}
                        value={selectedClient}
                        onChange={(e) => handleSelectClient(e.target.value)}
                      >
                        <option disabled>Select Client</option>

                        {data[0]?.id !== undefined
                          ? data.map((client) => (
                            <option value={client.id} key={client.id}>
                              {client.client_name}
                            </option>
                          ))
                          : ""}
                      </select> */}
                    </div>
                  )}
                </div>
              </>
            ) : status === "weatherDetails" ? (
              <>
                <Row>
                  <Col>
                    <div className="input-custom form-group">
                      <label htmlFor="startInpectionDate">
                        Start Inspection Date
                        <DatePicker
                          dateFormat="yyyy-MM-dd"
                          selected={startDate}
                          // maxDate={endDate}
                          id="startInpectionDate"
                          // onFocus={setError("")}
                          className="form-control mt-2"
                          onChange={(startDate) => {
                            if (startDate <= endDate) {
                              setEndError("");
                              setStartError("");
                              setStartDate(startDate);
                            } else {
                              if (endDate !== "") {
                                setStartDate(startDate);
                                setEndDate("");

                                setEndError(
                                  "end date cannot be smaller start date"
                                );
                              }
                              setStartDate(startDate);
                            }
                          }}
                        />
                      </label>
                      {errorStart && (
                        <small style={{ color: "red" }}>{errorStart}</small>
                      )}
                    </div>
                  </Col>
                  <Col>
                    <div className="input-custom form-group">
                      <label htmlFor="endInpectionDate">
                        End Inspection Date
                        <DatePicker
                          selected={endDate}
                          // minDate={startDate}
                          // onFocus={setEndError("")}
                          id="endInpectionDate"
                          dateFormat="yyyy-MM-dd"
                          className="form-control mt-2"
                          onChange={(endDate) => {
                            if (endDate >= startDate) {
                              setEndError("");
                              setStartError("");
                              setEndDate(endDate);
                            } else {
                              if (startDate !== "") {
                                setStartDate("");
                                setEndDate(endDate);
                                setStartError(
                                  "start date must be less than end date"
                                );
                                setEndDate(endDate);
                              }
                            }
                          }}
                        />
                      </label>
                      {errorEnd && (
                        <small style={{ color: "red" }}>{errorEnd}</small>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="input-custom form-group">
                      <label htmlFor="startInpectionTime">
                        Start Inpection Time
                        <DatePicker
                          selected={startTime}
                          id="startInpectionTime"
                          className="form-control mt-2"
                          onChange={(startTime) => setStartTime(startTime)}
                          showTimeSelect
                          showTimeSelectOnly
                          // timeIntervals={15}
                          autoComplete="off"
                          timeCaption="Time"
                          // dateFormat="DD/MM/YYYY HH:mm:ss"
                          dateFormat="h:mm aa"
                        />
                      </label>
                    </div>
                  </Col>
                  <Col>
                    <div className="input-custom form-group">
                      <label htmlFor="endInpectionTime">
                        End Inspection Time
                        <DatePicker
                          selected={endTime}
                          id="endInpectionTime"
                          className="form-control mt-2"
                          showTimeSelect
                          showTimeSelectOnly
                          // timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          onChange={(endTime) => setEndTime(endTime)}
                        />
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className="input-custom form-group">
                    <label htmlFor="weatherConditions" className="mb-2">
                      Weather Conditions
                    </label>{" "}
                    <br />
                    <textarea
                      id="weatherConditions"
                      className="form-control"
                      placeholder="Weather Conditions"
                      rows="3"
                      value={weatherConditions ? weatherConditions : ""}
                      onChange={(e) => setWeatherConditions(e.target.value)}
                    >
                      {/* {weatherConditions} */}
                    </textarea>
                  </div>
                </Row>
              </>
            ) : status === "desc" ? (
              <>
                <Row className="mb-2">
                  <Col md={12} className="pl0">
                    <Form.Control
                      as="textarea"
                      rows={8}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <div className="para">{bodyText}</div>
            )}
        </Modal.Body>

        <Modal.Footer className="row pl0 pr0">
          {status === "selectClient" ? (
            <Button
            className="m-0 w-100 btn-submit"
              onClick={() => NavigateToClient()}
            >
              Next
            </Button>
          ) : status === "weatherDetails" ? (
            <>
              <div className="col-md-6 m-0 pl0 popup-button">
                <Button
                  className="m-0 w-100 btn-submit "
                  onClick={() =>
                    handleWeatherUpdationApi({
                      startDate,
                      endDate,
                      startTime,
                      endTime,
                      weatherConditions,
                    })
                  }
                >
                  Save
                </Button>
              </div>
              <div className="col-md-6 m-0 pr0 popup-button">
                <Button
                  variant="secondary"
                  className="w-100 "
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="col-md-6 m-0 pl0">
                {status === "emailReport" ? (
                  <Button
                    className="m-0 w-100 btn-submit"
                    onClick={() =>
                      handleSubmit({ emailText, emailTo, emailFrom, ccEmails, BccEmails })
                    }
                  >
                    Send
                  </Button>
                ) : status === "desc" ? (
                  <Button
                    className="m-0 w-100 btn-submit "
                    onClick={() => handleDescription(description, "description")}
                  >
                    Save Changes
                  </Button>
                ) : status === "Cancelation" ? (
                  <Button
                    className="m-0 w-100 btn-submit"
                    onClick={() => handleCancelation()}>
                    Yes
                  </Button>

                ) : (
                  <Button
                    className="m-0 w-100 btn-submit "
                    onClick={() => handlePopupId(data)}
                  >
                    Yes
                  </Button>
                )}
              </div>
              <div className="col-md-6 m-0 pr0">
                <Button
                  variant="secondary"
                  className="w-100 "
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </div>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DialogBox;
