import React, { useEffect, useState } from "react";
import ScrollToTop from "../../../ScrollToTop";
import axios from "axios";
import { Link } from "react-router-dom";
import NavbarDashboard from "../../NavbarDashboard";
import { Row, Col, Card } from "react-bootstrap";
import { isAuthenticated } from "../../../../utils/Auth";
import MyAccountSkeleton from "./MyAccountSkeleton";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";

const MyAccount = () => {
  isAuthenticated();
  var token = localStorage.getItem("access_token");

  const [contact, setContact] = useState(false);
  const [license, setLicense] = useState(false);
  const [company, setCompany] = useState(false);

  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const notify = (message, status) => {
    if (status) {
      toast.success(message, {
        theme: "dark",
      });
    } else {
      toast.error(message, {
        theme: "dark",
      });
    }
  };

  useEffect(() => {
    const getAuthUserApi = async () => {
      var getUser = {
        method: "get",
        url: "/v1/oauth/user/my-account",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios(getUser)
        .then((response) => {
          if (response.data.status === true) {
            setUserData(response.data.user);
            setIsLoading(false);
          }

          document.body.classList.remove("loading-indicator");
        })
        .catch(function (error) {
          console.log(error);
          document.body.classList.remove("loading-indicator");
        });
    };

    getAuthUserApi();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dpHandler = (e) => {
    document.body.classList.add("loading-indicator");

    const dpValue = new FormData();
    dpValue.append("profile_image", e.target.files[0]);

    const configValues = {
      method: "post",
      url: "/v1/oauth/user/update-profile-image",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: dpValue,
    };
    axios(configValues)
      .then((res) => {
        if (res.data.status === true) {
          let parsedLocalStorage = JSON.parse(localStorage.getItem("userData"));
          if (parsedLocalStorage.profile_image !== res.data.profile_image) {
            parsedLocalStorage.profile_image = res.data.profile_image;
            localStorage.setItem(
              "userData",
              JSON.stringify(parsedLocalStorage)
            );
            document.body.classList.remove("loading-indicator");
            notify(res.data.message, "success");
          }
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <ScrollToTop />
      <NavbarDashboard />
      <div className="content-container Mcontent">
        {!isLoading && (
          <Row className="side-spacing pt50 ">
            <div className="profHead mb50 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center" id="profile-detail">
                <div className="profImgCt">
                  <img
                    src={
                      userData.profile_image
                        ? userData.profile_image
                        : "/assets/defaultProfile.png"
                    }
                    className="profile-img"
                    alt=""
                    width="125px"
                    height="125px"
                  />
                  <label>
                    <input
                      type="file"
                      className="form-control d-none"
                      name="profile_image"
                      id="profileLogo"
                      accept="images/*"
                      onChange={dpHandler}
                    />
                    <img
                      src="/assets/icon/edit-prof.png"
                      className="edit-prof"
                      alt="edit"
                    />
                  </label>
                </div>
                <div className="ml20  mt20">
                  <h2 className="m-0 text-capitalize">
                    {userData.first_name} {userData.last_name}
                  </h2>

                  <p className="mb-1">
                    License Number:{" "}
                    {userData.inspector_license_number
                      ? userData.inspector_license_number
                      : "N/A"}
                  </p>
                </div>
              </div>

              <Link to="/my-account/edit-profile" className="btn btn-light btn-edit-profile">
                Edit
              </Link>
            </div>
            <Col xs={12} md={12} className="pb25 p-0">
              <Row>
                <Col xs={12} md={6}>
                  <Card className="mb-4">
                    <div
                      className="acc-header d-flex align-items-center justify-content-between"
                      onClick={() => setContact(!contact)}
                    >
                      <Card.Title className="mb-0">Contact Details</Card.Title>

                      <img
                        src={
                          !contact
                            ? "/assets/icon/arrow-down.png"
                            : "/assets/icon/arrow-up.png"
                        }
                        alt=""
                      />
                    </div>

                    {contact && (
                      <ul className="list-unstyled accordion-content mb-0 pt-0 topToBottom">
                        <li>{userData.email}</li>
                        <li>{userData.phone_number}</li>
                        <li>{userData.address}</li>
                        <li>{userData.country}</li>
                        <li>{userData.state}</li>
                        <li>{userData.zip}</li>
                        <li>{userData.website}</li>
                      </ul>
                    )}
                  </Card>
                  <Card className="mb-4">
                    <div
                      className="acc-header d-flex align-items-center justify-content-between"
                      onClick={() => setCompany(!company)}
                    >
                      <Card.Title className="mb-0">Company Details</Card.Title>

                      <img
                        src={
                          !company
                            ? "/assets/icon/arrow-down.png"
                            : "/assets/icon/arrow-up.png"
                        }
                        alt=""
                      />
                    </div>

                    {company && (
                      <ul className="list-unstyled accordion-content pt-0 topToBottom">
                        <li>{userData.company_info}</li>
                      </ul>
                    )}
                  </Card>
                </Col>
                <Col xs={12} md={6}>
                  <Card className="mb-4">
                    <div
                      className="acc-header d-flex align-items-center justify-content-between"
                      onClick={() => setLicense(!license)}
                    >
                      <Card.Title className="mb-0">License Details</Card.Title>

                      <img
                        src={
                          !license
                            ? "/assets/icon/arrow-down.png"
                            : "/assets/icon/arrow-up.png"
                        }
                        alt=""
                      />
                    </div>

                    {license && (
                      <ul className="list-unstyled accordion-content topToBottom pt-0">
                        <li>
                          License Number:{" "}
                          {userData.inspector_license_number
                            ? userData.inspector_license_number
                            : "N/A"}
                        </li>
                        <li>
                          Expiration Date:{" "}
                          {userData.license_expiration_date
                            ? userData.license_expiration_date
                            : "N/A"}
                        </li>
                      </ul>
                    )}
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {isLoading && <MyAccountSkeleton />}
      </div>

      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        transition={Slide}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default MyAccount;
