import { Formik } from "formik";
import React from "react";
import ScrollToTop from "../../../../ScrollToTop";
import NavbarDashboard from "../../../NavbarDashboard";
import * as Yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";
import { useHistory } from "react-router";

const ChangePassword = () => {
  const passwordValues = {
    old_password: "",
    new_password: "",
  };
  const history = useHistory();
  const validate = Yup.object().shape({
    old_password: Yup.string().required("Enter Old Password"),
    new_password: Yup.string()
      .required("Enter New Password")
      .min(8, "Password should be atleast 8 characters long"),
    confirm_new_password: Yup.string()
      .required("Confirm Password")
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.new_password === value;
      }),
  });
  const submitForm = (values, { setFieldError }) => {
    if (values.old_password === values.new_password) {
      setFieldError(
        "new_password",
        "Old Password and New Password should be different"
      );
      return false;
    }

    const notify = (message, status) => {
      if (status) {
        toast.success(message, {
          theme: "dark",
        });
      } else {
        toast.error(message, {
          theme: "dark",
        });
      }
    };

    var FormData = require("form-data");
    var sendThisData = new FormData();
    sendThisData.append("old_password", values.old_password);
    sendThisData.append("new_password", values.new_password);

    const token = localStorage.getItem("access_token");
    var changePasswordData = {
      method: "post",
      url: "/v1/oauth/user/update-password",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: sendThisData,
    };
    axios(changePasswordData)
      .then((res) => {
        if (res.data.status === false) {
          setFieldError("old_password", res.data.message);
        } else {
          notify(res.data.message, true);
          setTimeout(() => {
            history.push("/my-account");
          }, 2000);
        }
      })
      .catch((err) => {
        console.log(err.errors);
      });
  };
  return (
    <>
      <ScrollToTop />
      <NavbarDashboard />
      <div className="content-container Mcontent">
        <div className="side-spacing pt50 ">
          {/* <div md={8}> */}
          <div className="auth-form-container ">
            <div className="edit-profile">
              <div className="auth-form-text">
                <h3> Change Password</h3>
                {/* <small>Provides your customer with a professionally formatted report that they can follow in a logical sequence.</small> */}
              </div>

              <Formik
                initialValues={passwordValues}
                validationSchema={validate}
                onSubmit={submitForm}
              >
                {(props) => {
                  return (
                    <form onSubmit={props.handleSubmit}>
                      <div className="input-custom form-group">
                        <input
                          type="password"
                          name="old_password"
                          value={props.values?.old_password || ""}
                          className="form-control "
                          onBlur={props.handleBlur}
                          onChange={props.handleChange}
                          placeholder="Old Password *"
                          autoComplete="current-password"
                        />
                        {props.errors.old_password &&
                          props.touched.old_password && (
                            <small className="input-error">
                              {props.errors.old_password}
                            </small>
                          )}
                      </div>

                      <div className="input-custom form-group">
                        <input
                          type="password"
                          name="new_password"
                          value={props.values?.new_password || ""}
                          className="form-control "
                          onBlur={props.handleBlur}
                          onChange={props.handleChange}
                          placeholder="New Password *"
                          autoComplete="new-password"
                        />
                        {props.errors.new_password &&
                          props.touched.new_password && (
                            <small className="input-error">
                              {props.errors.new_password}
                            </small>
                          )}
                      </div>

                      <div className="input-custom form-group">
                        <input
                          type="password"
                          name="confirm_new_password"
                          value={props.values?.confirm_new_password || ""}
                          className="form-control "
                          onBlur={props.handleBlur}
                          onChange={props.handleChange}
                          placeholder="Confirm New Password *"
                          autoComplete="new-password"
                        />
                        {props.errors.confirm_new_password &&
                          props.touched.confirm_new_password && (
                            <small className="input-error">
                              {props.errors.confirm_new_password}
                            </small>
                          )}
                      </div>

                      <br />

                      <button type="submit" className="btn btn-submit ">
                        Update Password <i className="fa fa-arrow-right "></i>{" "}
                      </button>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={1900}
        transition={Slide}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default ChangePassword;
