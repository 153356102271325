// import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import ScrollToTop from './ScrollToTop';
import NavbarTop from './NavbarTop';
import Footer from './Footer';
import { Link } from 'react-router-dom';


const PrivacyPolicy = () => {

    return (
        <>
            <ScrollToTop />
            <NavbarTop />
            <div className="signIn-container">
                <Row className="">
                    <Col md={4} className="login-img-container ">
                        <img src="/assets/header-image.png" className="" alt="" />
                        {/* </div> */}
                    </Col>

                    <Col md={8} >
                        <div className="auth-form-container privacy-policy-container">
                            <div className="auth-form-text">
                                <div className="mb-3">
                                    <h2> <u>Privacy</u> Policy</h2>
                                    <small>Updated: 5/2/2022</small>
                                </div>
                                <p>
                                    As used in this “Privacy Policy”, ProNspect, LLC (“ProNspect”) refers to all websites, apps, properties, Sites owned and operated by ProNspect, LLC.
                                </p>
                                <p>
                                    ProNspect, LLC (“ProNspect”) respects your privacy and is committed to protecting it through our compliance with this Policy.
                                </p>
                                <p>
                                    We at ProNspect, LLC (“ProNspect”) constantly aim to provide the best user experience possible to our customers. We value the long-term relationship that allows us to provide a personalized, valuable experience that delivers information, resources and services that are helpful to you. This Privacy Policy discusses, among other things, how information and data obtained during your visit to ProNspect may be collected and used. By using the ProNspect website, visiting ProNspect Sites, or registering for ProNspect Services, you agree to be bound by the terms of this Privacy Policy.
                                </p>
                                <p>
                                    Please read this policy carefully to understand our policies and practices around collecting information and how we will treat it. If you do not agree or consent to any of the terms of this Policy, your only recourse is to discontinue accessing or otherwise using the website or any materials obtained from it. By using or continuing to use or access the Sites or Services, you agree to this Privacy Policy.
                                </p>
                                <p><strong> User Information ProNspect May Collect</strong></p>
                                <p>
                                    Visitors to ProNspect Sites or Customers of ProNspect Services, including users who are not signed in and users that have created an account and signed in, acknowledge that we may obtain the following types of information from you or your computer or device:
                                </p>
                                <ul>
                                    <li><p className="mb-0"> Name </p></li>
                                    <li><p className="mb-0">Email Address</p></li>
                                    <li><p className="mb-0">Phone Number</p></li>
                                    <li><p className="mb-0">Website Name</p></li>
                                    <li><p className="mb-0">Website URL</p></li>
                                    <li><p className="mb-0">Business Address</p></li>
                                    <li><p className="mb-0">Billing Address</p></li>
                                    <li><p className="mb-0">Visitor Site Preferences</p></li>
                                </ul>

                                <p>ProNspect may also collect information and data provided by you from the following sources:</p>
                                <ul>
                                    <li><p className="mb-0"> Your responses to surveys that we might ask for research purposes to improve our Services </p></li>
                                    <li><p className="mb-0">Information you provide us through our Sites and/or on-site Messaging Platform</p></li>
                                </ul>
                                <p><strong> How We Use Your Information </strong></p>
                                <p>ProNspect never sells your personal information to others for marketing purposes.</p>
                                <ul>
                                    <li><p className="mb-0">To carry out our Service agreements and obligations from any contracts entered between you and ProNspect, including billing and collections</p></li>
                                    <li><p className="mb-0">Notify you of Service and subscription expirations and renewals</p></li>
                                    <li><p className="mb-0">To provide customized, personalized content through our Sites and Services</p></li>
                                    <li><p className="mb-0">Notify you about changes to our Sites, Services or Policies</p></li>
                                    <li><p className="mb-0">To allow you to participate in new features and Services on our Sites</p></li>
                                    <li><p className="mb-0">To facilitate the delivery of content or advertisements that we feel may be relevant to you</p></li>
                                    <li><p className="mb-0">To communicate new or existing products or services that we feel can help you</p></li>
                                    <li><p className="mb-0">For any other purpose with you consent</p></li>
                                </ul>
                                <p>ProNspect may collect, aggregate and use broad statistical data and characteristics from your profile, preferences and/or property inspections to produce anonymous reports, data and information. Data and information that could be used to personally identify you or your clients by name, address or similar private information is never exposed, shared or sold to third parties for commercial purposes unless explicitly communicated and approved.</p>
                                <p>ProNspect may post testimonials on our Sites with express consent of the individual or company who provided the information. We may also post or share news, events, results, accomplishments, marketing materials from individuals or companies to help promote their brand. Requests to remove posted materials should be submitted to info@hititpro.com. Reasonable efforts will be made to remove posts upon receiving written notice.</p>
                                <p><strong> Disclosure of Information to Third Parties </strong></p>
                                <p>ProNspect customer information, user data, and personally identifiable information may be shared with certain third-party companies that help facilitate technical and administrative aspects of ProNspect’s Services (credit card processing, hosting, citation building & management). Third party companies perform these tasks on our behalf and are contractually obligated to not disclose or use ProNspect user information or customer data for any other purposes, and to provide adequate security measures to protect the data from unauthorized access. ProNspect is not liable or responsible in the event that personally identifiable information is disclosed or otherwise found as a result of breach or security lapse by any third party.</p>
                                <p><strong> ProNspect’s Use of Cookies </strong></p>
                                <p>Cookies are small text files that a site’s computer stores on your computer. ProNspect uses cookies and similar technologies to help recognize you when you log into our site. By accessing ProNspect Sites and using ProNspect Services, you are consenting to the placement of cookies and similar technologies in your browser in accordance with this Policy and <Link to="/terms-of-use">Terms of Use</Link>. If you do not wish for ProNspect to place and use cookies on your computer, you may modify and control how cookies are handled in your browser settings. If you choose to disable cookies, certain functions of the Sites and Services may not function correctly. Cookies allow us to collect information and data to customize your experience, so it is as helpful and relevant as possible.</p>
                            </div>
                        </div>

                    </Col>
                </Row>
            </div>


            < Footer />
        </>
    );

}

export default PrivacyPolicy;