import { createSlice } from "@reduxjs/toolkit";

export const typeSlice = createSlice({
  name: "type",
  initialState: {
    value: "",
    title: "",
    templateName: "",
  },
  reducers: {
    setType: (state, action) => {
      state.value = action.payload;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setTemplateName: (state, action) => {
      state.templateName = action.payload;
    },
  },
});

export const { setType, setTitle, setTemplateName } = typeSlice.actions;

export default typeSlice.reducer;
