import { Row, Col, Container, Button } from "react-bootstrap";
import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { PaymentContext } from "./Plans";
import "./BillingInformation/Billing.css"
import DialogBox from "../../../utils/DialogBox";

const ChoosePlan = ({ onChoose, activeChoosePlan }) => {
  let status = useContext(PaymentContext);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [activePlanExist, setActivePlanExist] = useState(false)

  const cancelSubscription = () => {
    const token = localStorage.getItem("access_token");
    document.body.classList.add("loading-indicator");
    const cancelPlan = {
      method: "post",
      url: "/v1/oauth/cancelSubscription",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(cancelPlan)
      .then((res) => {
        document.body.classList.remove("loading-indicator");
        setActivePlanExist(false)
        window.location.href = "/plans";
      })
      .catch((err) => {
        document.body.classList.remove("loading-indicator");
      });
  };
  const RetrievePlans = () => {
    const token = localStorage.getItem("access_token");
    const getPlans = {
      method: "get",
      url: "/v1/oauth/retrievePlans",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(getPlans)
      .then((res) => {
        var activePlan = res.data.plans.filter((plan) => plan.active === true)
        if (activePlan.length === 0) {

          setActivePlanExist(false)
        } else {
          setActivePlanExist(true)
        }
        setPlans(res.data.plans);
        onChoose(activePlan.length === 1 ? activePlan[0] : res.data.plans[0])
        // handleActive(activePlan)
        setLoading(false);
      })
      .catch((err) => {
      });
  };
  useEffect(() => {
    RetrievePlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {!loading && (
        <Container >
          <Row className="mt10" style={{ height: 150, display: 'inline-flex', flexDirection: 'column', alignContent: 'center' }}>
            {plans.map((plan, index) => (

              <Col

                onClick={() => onChoose(plans[index])}
                className={
                  status.authStatus
                    ? `mr10 btn btn-plans ${plan.active ? "btn-success" : "btn-warning disabled"
                    }`
                    : `mr10 btn btn-plans ${activeChoosePlan?.id === plan.id
                      ? "btn-primary"
                      : "btn-warning"
                    }`
                }
                style={{

                  cursor: "pointer",
                  display: "flex",
                  maxWidth: 325,
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <h4>{plan.interval_plan}</h4>
                <h5>Price: {plan.amount / 100}</h5>
                <h5>Interval: {plan.interval}</h5>
              </Col>
            ))}



          </Row>
          <Row>
            {activePlanExist && (
              <Button
                onClick={() => {
                  handleShow()
                  // cancelSubscription();
                }}
                style={{
                  backgroundColor: "black",
                  maxWidth: 200,
                  marginTop: 20,
                }}
              >
                Cancel Subscription
              </Button>
            )}
          </Row>
        </Container>
      )}
      {show && (
        <DialogBox
          show={show}
          title="Confirmation"
          data="are you sre"
          // data={
          //   dialogBoxStatus === "weatherDetails" ? weatherDetails : sendToForm
          // }
          // handleWeatherUpdationApi={handleWeatherUpdationApi}
          handleClose={handleClose}
          handleCancelation={cancelSubscription}
          status="Cancelation"
        // handleEmailReport={handleEmailReport}
        />
      )}
    </div>
  );
};

export default ChoosePlan;
