import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import NavbarTop from "../../NavbarTop";
import ScrollToTop from "../../ScrollToTop";
import Footer from "../../Footer";
import EmailForm from "./EmailForm";
import EnterCode from "./EnterCode";
import ForgetPasswordForm from "./ForgetPasswordForm";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";
import { useHistory } from "react-router";

const ForgotPassword = () => {
  const history = useHistory();
  const [showStep, setShowStep] = useState(1);
  const [code, setCode] = useState();
  const [email, setEmail] = useState({});
  const next = () => {
    if (showStep === 3) {
    } else {
      setShowStep(showStep + 1);
    }
  };
  const notify = (message, status) => {
    if (status) {
      toast.success(message, {
        theme: "dark",
      });
    } else {
      toast.error(message, {
        theme: "dark",
      });
    }
  };

  const submitPasswordForm = (password) => {
    var FormData = require("form-data");
    var formData = new FormData();
    formData.append("password", password);
    formData.append("reset_code", code);
    formData.append("email", email);

    var changePassword = {
      method: "post",
      url: "/user/reset-forgot-password",
      data: formData,
    };
    axios(changePassword)
      .then((res) => {
        if (res.data.status === true) {
          notify(res.data.message, true);
          setTimeout(() => {
            history.push("/login");
          }, 2000);
        } else {
          notify(res.data.message, false);
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  return (
    <>
      <ScrollToTop />
      <NavbarTop />
      <Row className="signIn-container">
        <Col md={4} className="login-img-container">
          <img src="/assets/header-image.png" alt="" />
        </Col>
        <Col md={8}>
          <div className="auth-form-container ">
            {showStep === 1 && <EmailForm setEmail={setEmail} next={next} />}

            {showStep === 2 && (
              <EnterCode email={email} next={next} setCode={setCode} />
            )}

            {showStep === 3 && (
              <ForgetPasswordForm submitPasswordForm={submitPasswordForm} />
            )}
          </div>
        </Col>
      </Row>
      <Footer />
      <ToastContainer
        position="bottom-right"
        autoClose={1900}
        transition={Slide}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default ForgotPassword;
