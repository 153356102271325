import React, { useEffect } from 'react'

const RedirectPage = () => {
    useEffect(() => {
        setTimeout(() => {
            window.location.href = "/"
        }, 5000);
    }, [])
    return (
        <div>
            <h3 style={{ textAlign: 'center' }}>We have moved to a new domain <a style={{ color: '#F1E246' }} href='https://pronspect.com/'>Pronspect.com </a></h3>
            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>You will be automatically redirect... </p>
            <div style={{ margin: 'auto', width: '60%', padding: '10px' }}>
                <img src='/assets/house.png' alt='home' style={{ maxWidth: 600 }} />
            </div>

        </div>
    )
}

export default RedirectPage