import { useEffect, useState } from "react";
import axios from "axios";
function useInspection() {
  const [inspection, setInspection] = useState([]);
  var token = localStorage.getItem("access_token");

  const getInspectionAPI = async () => {
    document.body.classList.add("loading-indicator");

    let dataConfig = {
      method: "post",
      url: "/v1/oauth/inspection/get-inspections",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios(dataConfig)
      .then((response) => {
        // console.log(response.data, 'response.data');
        if (response.data.status === true) {
          setInspection(response.data.inspections);
        }
        document.body.classList.remove("loading-indicator");
      })
      .catch((error) => {
        document.body.classList.remove("loading-indicator");

        if (error.response.status === 401) {
          window.localStorage.clear();
          window.location.href = "/login";
        }
      });
  };

  useEffect(() => {
    getInspectionAPI();
    // eslint-disable-next-line
  }, []);

  return [inspection];
}

export default useInspection;
