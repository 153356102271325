import React, { useEffect } from 'react'
import ScrollToTop from '../../ScrollToTop'
import NavbarDashboard from '../NavbarDashboard'
import axios from 'axios'
import Table from './Table'
import { useQuery } from '@tanstack/react-query'
import EmailModel from './EmailModel'
import { useState } from 'react'


const EmailLogs = () => {
  useEffect(() => {
    document.body.classList.add("loading-indicator");
  }, [])
  const [Log, setLog] = React.useState()
  const [show, setShow] = useState(false)
  const handleOpen = (log) => {
    setLog(log)
    setShow(true)
  }
  const handleClose = () => setShow(false)

  var token = localStorage.getItem('access_token');

  const emailLogObj = {
    method: 'get',
    url: '/v1/oauth/get-email-logs',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }
  // const fetchEmailLogs = () => {
  //   const data =  fetch(`${process.env.REACT_APP_DEV_API_URL}/v1/oauth/get-email-logs`, {
  //     method: 'get',
  //     headers: {
  //       'Authorization': `Bearer ${token}`
  //     }
  //   })
  // }
  // console.log(fetchEmailLogs)

  const { isLoading, data } = useQuery({
    queryKey: ['emailLogs'], queryFn: () =>
      axios(emailLogObj).then(res => {
        document.body.classList.remove("loading-indicator");
        return res.data
      })
  })

  // React.useEffect(() => {
  //     axios(emailLogObj).then(res => setLogs(res.data.logs)).catch(err => console.log(err))
  // }, [])

  return (
    <div>
      <NavbarDashboard />
      <ScrollToTop />
      {isLoading ? <div>Loading...</div> : (

        <div className="content-container Mcontent">
          <div className='side-spacing pt50'>
            <h2 className="mb50 ">Email Logs</h2>
            <Table handleOpen={handleOpen} Logs={data.logs} />

            {
              !isLoading && Log && <EmailModel logData={Log} show={show} handleClose={handleClose} />
            }

          </div>
        </div>
      )}
    </div>
  )
}

export default EmailLogs