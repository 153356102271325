import React, { useEffect, useState } from "react";
import ScrollToTop from "../../ScrollToTop";
import NavbarDashboard from "../NavbarDashboard";
import { Link, useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import Client from "./Client";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";
import ClientSkeleton from "./ClientSkeleton";
import DialogBox from "../../../utils/DialogBox";

const ArchiveClients = () => {
  const history = useHistory();
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [show, setShow] = useState(false);
  const [clientId, setClientId] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const notify = () => {
    toast.success("Client Restored Successfully!", {
      theme: "dark",
    });
  };
  const handleClientRestore = (id) => {
    let token = localStorage.getItem("access_token");

    var FormData = require("form-data");
    var clientFormData = new FormData();
    clientFormData.append("client_id", id);

    var clientRestoreData = {
      method: "post",
      url: "/v1/oauth/client/restore",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: clientFormData,
    };

    axios(clientRestoreData)
      .then((res) => {
        if (res.data.status === true) {
          handleClose();
          notify();
          setTimeout(() => {
            history.push("/clients");
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const getArchivedClients = () => {
      const token = localStorage.getItem("access_token");
      var config = {
        method: "post",
        url: "/v1/oauth/client/get-archived",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios(config)
        .then((res) => {
          setClients(res.data.clients);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    setTimeout(() => {
      getArchivedClients();
    }, 1000);
  }, []);
  const handleShowSearchInput = () => {
    const inputSearch = document.getElementById("searchField");
    if (document.getElementById("searchField").classList.contains("d-none")) {
      document.getElementById("searchField").classList.remove("d-none");
      inputSearch.focus();
    } else {
      document.getElementById("searchField").classList.add("d-none");
    }
  };

  const handleHideSearchInput = (e) => {
    document.getElementById("searchField").classList.add("d-none");
  };

  return (
    <>
      <ScrollToTop />
      <NavbarDashboard />
      <div className="content-container Mcontent">
        <Row className="side-spacing pt50" onMouseLeave={handleHideSearchInput}>
          <div className="p-0 mb20">
            <h2 className=" float-start">Archived Clients</h2>
            <div className="float-end position-relative">
              <Link
                to="/clients/add-client"
                className="ml20 text-decoration-none greylight"
              >
                Add Clients
              </Link>
              <Link
                to="/clients"
                className="ml20 text-decoration-none greylight"
              >
                Manage Clients
              </Link>
              <img
                src="/assets/icon/search.png"
                className="ml20 cursor-pointer"
                alt=""
                onClick={handleShowSearchInput}
              />
              <input
                type="text"
                className="form-control d-none search-input"
                placeholder="Search Client"
                value={searchText}
                id="searchField"
                onMouseLeave={handleHideSearchInput}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          </div>

          {clients &&
            clients
              .filter((val) => {
                if (searchText === "") {
                  return val;
                } else if (
                  val.client_name
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
                ) {
                  return val;
                }
                return false;
              })
              .map((client) => (
                <Client
                  key={client.id}
                  client={client}
                  setClientId={setClientId}
                  handleShow={handleShow}
                  clientsPage="archiveClients"
                />
              ))}
          {isLoading && (
            <Col xs={12} md={12} className="mt20 px-0">
              <ClientSkeleton />
            </Col>
          )}
        </Row>
      </div>

      <DialogBox
        show={show}
        title="Restore Client"
        bodyText="Are you sure?"
        handleClose={handleClose}
        data={clientId}
        handlePopupId={handleClientRestore}
      />

      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        transition={Slide}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default ArchiveClients;
