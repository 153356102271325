import React from "react";
import ScrollToTop from "../../ScrollToTop";
import NavbarDashboard from "../NavbarDashboard";
import { Row, Container } from "react-bootstrap";
import { isAuthenticated } from "../../../utils/Auth";

const BillingHistory = ({ location }) => {
  const transactions = location.state.transactions;
  const loading = location.state.loading;

  isAuthenticated();
  return (
    <>
      <ScrollToTop />
      <NavbarDashboard />
      {!loading && (
        <Container>
          <div className="content-container Mcontent">
            <Row className="pt50">
              <div className="mb10" style={{ height: 500 }}>
                <h2 className="float-start">Billing History</h2>
                <Row className="mt20">
                  {transactions.map((transaction) => {
                    const transDate = () => {
                      const myArray = transaction.transaction_date.split(" ");
                      return myArray[0];
                    };
                    const transRenew = () => {
                      const myArray = transaction.expiry_date.split(" ");
                      return myArray[0];
                    };
                    return (
                      <div
                        key={transaction.id}
                        id={transaction.id}
                        className="d-flex justify-content-around mt20 shadow p-3 mb-5 bg-white rounded pt30"
                      >
                        <p>
                          <strong>License Start Date: </strong>
                          {transDate()}
                        </p>
                        <p>
                          <strong>License Renew Date: </strong> {transRenew()}
                        </p>
                        <button className="new-Ins-btn">
                          <h3 className="badgeCheck">Paid </h3>
                        </button>
                      </div>
                    );
                  })}
                </Row>
              </div>
            </Row>
          </div>
        </Container>
      )}
    </>
  );
};

export default BillingHistory;
