import React, { useEffect, useState } from "react";
import ScrollToTop from "../../ScrollToTop";
import { Link, useHistory } from "react-router-dom";
import NavbarDashboard from "../NavbarDashboard";
import { Formik, Form } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'


const AddClient = (props) => {
  const [mynumber, setnumber] = useState("");
  const [loading, setLoading] = useState(true);
  let history = useHistory();
  const [userStoredData, setUserStoredData] = useState({});
  const { formType, idOfEdittedClient } = props;
  var token = localStorage.getItem("access_token");

  // Function to Get User Data for Edit form
  const getClientData = () => {
    document.body.classList.add("loading-indicator");
    setLoading(true);
    var FormData = require("form-data");
    var edittedClientData = new FormData();
    edittedClientData.append("client_id", idOfEdittedClient);

    var clientData = {
      method: "post",
      url: "/v1/oauth/client/get-client",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: edittedClientData,
    };
    axios(clientData)
      .then((res) => {
        setUserStoredData(res.data.client);
        setnumber(res.data.client.phone)
        setLoading(false);
        document.body.classList.remove("loading-indicator");
      })
      .catch((error) => {
        setLoading(false);
        notify("Something went wrong");
        if (error.response.status === 422) {
          notify(error.response.data.message);
        }
        document.body.classList.remove("loading-indicator");
      });
  };

  useEffect(() => {
    if (formType === "edit") {
      getClientData();
    }
    if (formType !== "edit") {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const notify = (message, status) => {
    if (status) {
      toast.success(message, {
        theme: "dark",
      });
    } else {
      toast.error(message, {
        theme: "dark",
      });
    }
  };

  const validate = Yup.object({
    client_name: Yup.string().required("Enter Client name"),
    address: Yup.string().required("Enter Address"),
    city: Yup.string().required("Enter City"),
    state: Yup.string().required("Enter State"),
    zip: Yup.number().required("Enter Zip"),
    email: Yup.string()
      .email("Enter Valid Email")
      .required("Enter Valid email"),
  });

  const addFormValues = {
    client_name: "",
    address: "",
    city: "",
    zip: "",
    state: "",
    contactname: "",
    phone: "",
    fax: "",
    email: "",
  };

  // edit Stored Values
  const storedValues = {
    client_name: userStoredData.client_name,
    address: userStoredData.address,
    city: userStoredData.city,
    zip: userStoredData.zip,
    state: userStoredData.state,
    contactname: userStoredData.contactname,
    phone: userStoredData.phone,
    fax: userStoredData.fax,
    email: userStoredData.email,
  };

  const addClientForm = (values) => {
    values.phone = mynumber
    document.body.classList.add("loading-indicator");

    var config = {
      method: "post",
      url: "/v1/oauth/client/create",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: values,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === true) {
          notify();
          history.push("/clients");
          document.body.classList.remove("loading-indicator");
        }
      })
      .catch((err) => {
        document.body.classList.remove("loading-indicator");
        setLoading(false);

        if (err.response.status === 422) {
          notify(err.response.data.message);
        } else {
          notify("Something went wrong");
        }

        if (err.response.status === 401) {
          window.location.href = "/login";
        }
      });
  };

  const editClientForm = (values) => {
    document.body.classList.add("loading-indicator");
    const updatedValuesWithId = { ...values, client_id: idOfEdittedClient };
    var updateConfig = {
      method: "post",
      url: "/v1/oauth/client/update",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: updatedValuesWithId,
    };
    axios(updateConfig)
      .then((res) => {
        if (res.data.status === true) {
          document.body.classList.remove("loading-indicator");
          window.location.href = "/clients";
        }
      })
      .catch((err) => {
        document.body.classList.remove("loading-indicator");
        setLoading(false);

        if (err.response.status === 422) {
          notify(err.response.data.message);
        } else {
          notify("Something went wrong");
        }

        if (err.response.status === 401) {
          window.location.href = "/login";
        }
      });
  };
  const submitForm = (values) => {
    if (formType === "edit") {
      editClientForm(values);
    } else {
      addClientForm(values);
    }
  };

  return (
    <>
      <ScrollToTop />
      <NavbarDashboard />
      <div className="content-container Mcontent">
        <div className="side-spacing pt50 ">
          <div>
            <h2 className="mb20 float-start">
              {formType === "edit" ? "Edit Client" : "Add Client"}{" "}
            </h2>
            <div className="float-end">
              <Link
                to="/clients"
                className="ml20 text-decoration-none greylight"
              >
                Manage Clients
              </Link>
              <Link
                to="/clients/archived-clients"
                className="ml20 text-decoration-none greylight"
              >
                Archived Clients
              </Link>
            </div>
          </div>
          <br />
          {!loading && (
            <>
              <div className="row mt50">
                <Formik
                  initialValues={
                    formType === "edit" ? storedValues : addFormValues
                  }
                  validationSchema={validate}
                  onSubmit={submitForm}
                >
                  {(props) => {
                    const {
                      values,
                      touched,
                      errors,
                      handleChange,
                      handleSubmit,
                      handleBlur,
                    } = props;

                    return (
                      <Form onSubmit={handleSubmit} className="p-0">
                        <div className="input-custom form-group">
                          <input
                            type="text"
                            name="client_name"
                            className="form-control "
                            value={values.client_name}
                            onChange={(e) => {
                              // let Allowedvalue = e.target.value;
                              // Allowedvalue = Allowedvalue.replace(
                              //   /[ ^ a-z0-9 ] /gi,
                              //   ""
                              // );
                              // let newVal = e;
                              // newVal.target.value = Allowedvalue;
                              handleChange(e);
                            }}
                            placeholder="Client Name *"
                            autoComplete="off"
                            onBlur={handleBlur}
                          />
                          {errors.client_name && touched.client_name && (
                            <small className="input-error">
                              {errors.client_name}
                            </small>
                          )}
                        </div>
                        <div className="input-custom form-group">
                          <input
                            type="text"
                            name="contactname"
                            value={values.contactname}
                            className="form-control "
                            onChange={(e) => {
                              // let value = e.target.value;
                              // value = value.replace(/[^a-z0-9]/gi, "");
                              // let newVal = e;
                              // newVal.target.value = value;
                              handleChange(e);
                            }}
                            placeholder="Contact Name "
                            autoComplete="off"
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="input-custom form-group">
                          <input
                            type="text"
                            name="address"
                            value={values.address}
                            className="form-control "
                            onChange={(e) => {
                              let value = e.target.value;
                              let newVal = e;
                              newVal.target.value = value;
                              handleChange(newVal);
                            }}
                            placeholder="Address *"
                            autoComplete="no-fill"
                            onBlur={handleBlur}
                          />

                          {errors.address && touched.address && (
                            <small className="input-error">
                              {errors.address}{" "}
                            </small>
                          )}
                        </div>

                        <div className="input-custom form-group">
                          <input
                            type="text"
                            name="city"
                            value={values.city}
                            className="form-control"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              let value = e.target.value;
                              value = value.replace(/[^A-Za-z ]/gi, "");
                              let newVal = e;
                              newVal.target.value = value;
                              handleChange(newVal);
                            }}
                            placeholder="City *"
                            autoComplete="no-fill"
                          />
                          {errors.city && touched.city && (
                            <small className="input-error">{errors.city}</small>
                          )}
                        </div>
                        <div className="input-custom form-group">
                          <input
                            type="text"
                            name="state"
                            value={values.state}
                            className="form-control"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              let value = e.target.value;
                              value = value.replace(/[^A-Za-z ]/gi, "");
                              let newVal = e;
                              newVal.target.value = value;
                              handleChange(newVal);
                            }}
                            placeholder="State *"
                            autoComplete="no-fill"
                          />
                          {errors.state && touched.state && (
                            <small className="input-error">
                              {errors.state}{" "}
                            </small>
                          )}
                        </div>

                        <div className="input-custom form-group">
                          <input
                            type="number"
                            name="zip"
                            value={values.zip}
                            className="form-control"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              let value = e.target.value;
                              let newVal = e;
                              newVal.target.value = value;
                              handleChange(newVal);
                            }}
                            placeholder="Zip Code *"
                            autoComplete="no-fill"
                          />
                          {errors.zip && touched.zip && (
                            <small className="input-error">{errors.zip} </small>
                          )}
                        </div>
                        <div className="input-custom form-group">

                          <PhoneInput
                            placeholder="Enter phone number"
                            id="phone-number"
                            inputStyle={{ width: '100%', marginBottom: 5, boxShadow: '0px 1px 6px #3B362E1F', border: 'none', height: 45, paddingLeft: 12 }}
                            buttonStyle={{ display: 'none' }}
                            value={mynumber}
                            onChange={(e) => {
                              let firsttwo = "+1"
                              if (mynumber.length > 1) {
                                setnumber(e)
                              } else {
                                setnumber(firsttwo + e)
                              }
                            }}
                          />
                        </div>
                        <div className="input-custom form-group">
                          <input
                            type="number"
                            name="fax"
                            value={values.fax}
                            className="form-control"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              let value = e.target.value;
                              let newVal = e;
                              newVal.target.value = value;
                              handleChange(newVal);
                            }}
                            placeholder="Fax "
                            autoComplete="off"
                          />
                        </div>
                        <div className="input-custom form-group">
                          <input
                            type="email"
                            name="email"
                            value={values.email}
                            className="form-control"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              let value = e.target.value;
                              let newVal = e;
                              newVal.target.value = value;
                              handleChange(newVal);
                            }}
                            placeholder="Email *"
                            autoComplete="no-fill"
                          />
                          {errors.email && touched.email && (
                            <small className="input-error">
                              {errors.email}{" "}
                            </small>
                          )}
                        </div>

                        <br />
                        <br />
                        <button type="submit" className="btn btn-submit w-100">
                          {formType === "edit" ? "Update Client" : "Add Client"}
                          <i className="fa fa-arrow-right float-end pt-1"></i>
                        </button>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </>
          )}
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        transition={Slide}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default AddClient;
