// import React, { Component } from 'react'
import { Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const InspectionBox = (props) => {
  const inspection = props.data;
  const IconName =
    inspection?.inspection_name[0].toUpperCase() +
    inspection?.inspection_name[1].toUpperCase();
  return (
    <>
      <Card className="card-custom card-Inspection">
        <Card.Body className="position-relative">
          <div className="circle-outer">
            <div className="circle-name m-auto">{IconName}</div>
          </div>

          <h4 className="mt-4 fs18">{inspection?.inspection_name}</h4>
          <Card.Text className="inspection-card-text mt-3 ">
            <strong>Client Name: </strong>
            {inspection?.client_name} <br />
            <strong>Added By: </strong>
            {inspection?.added_by} <br />
            <strong>Added On: </strong>
            {inspection?.date} <br />
          </Card.Text>

          <div className="edit-inpection-btn">
            <Link
              to={`/inspection/${
                inspection?.id ? inspection.id : inspection?.inspection_id
              }/inspection-steps/${inspection.client_id}`}
            >
              <Button variant="" className="btn-submit">
                Edit Inspection
              </Button>
            </Link>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default InspectionBox;
