import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Slide } from 'react-toastify';
const EmailForm = ({ setEmail, next }) => {

    const validate = Yup.object().shape({
        email: Yup.string().required('Enter Email to proceed').email('Invalid Email Address')
    })
    const emailVal = { email: '' }
    const notify = (message, status) => {
        if (status) {

            toast.success(message, {
                theme: "dark"
            });
        }
        else {
            toast.error(message, {
                theme: "dark"
            });
        }
    };
    const handleNext = () => {
        next();
    }
    const submitForm = (values) => {
        // setEmail
        setEmail(values.email);
        var sendCodeOnEmail = {
            method: 'post',
            url: '/user/forgot-password',
            data: values
        }
        axios(sendCodeOnEmail)
            .then(res => {
                // console.log(res.data);
                if (res.data.status === true) {
                    notify('Reset Code sent to your email!', true);
                    setTimeout(() => {
                        handleNext();
                    }, 2000);
                } else {
                    notify(res.data.message, false);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }
    return (
        <>
            <Formik
                initialValues={emailVal}
                validationSchema={validate}
                onSubmit={submitForm}
            >
                {
                    (props) => {
                        const {
                            values,
                            handleChange,
                            handleSubmit,
                            handleBlur,
                            errors,
                            touched
                        } = props;


                        return (
                            <form onSubmit={handleSubmit} className="form-wrapper login-form" autoComplete="off" id="loginForm">
                                <div className="auth-form-text">
                                    <h2> <u>Reset</u> Password</h2>
                                    <small>We will send you reset code.</small>
                                </div><br />

                                <div className="input-custom form-group mb20">
                                    <input type="email" name="email" onChange={handleChange}
                                        value={values.email ? values.email : ''}
                                        onBlur={handleBlur}
                                        className="form-control "
                                        placeholder="Email address *" />

                                    {errors.email && touched.email && (<small className="input-error">{errors.email}</small>)}
                                </div>
                                <br />
                                <button type="submit" className="btn btn-submit">Send Code <i className="fa fa-arrow-right "></i></button>
                            </form>
                        )

                    }

                }

            </Formik>

            <ToastContainer
                position="bottom-right"
                autoClose={1900}
                transition={Slide}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    )
}

export default EmailForm
