import { createSlice } from "@reduxjs/toolkit";

export const templateSlice = createSlice({
  name: "value",
  initialState: {
    value: "",
    is_applicable: false,
  },
  reducers: {
    setValue: (state, action) => {
      state.value = action.payload;
    },
    setIsApplicable: (state, action) => {
      state.is_applicable = action.payload;
    },
  },
});

export const { setValue, setIsApplicable } = templateSlice.actions;

export default templateSlice.reducer;
