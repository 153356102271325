
import axios from 'axios'

export default function ChoosePlan(planId, token) {
    document.body.classList.add("loading-indicator");
    // const token = localStorage.getItem("access_token");
    var data = new FormData();
    data.append("plan_id", planId);
    const setPlan = {
        method: "post",
        url: "/v1/oauth/getPaymentIntentSubscription",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: data,
    };
    axios(setPlan)
        .then((res) => {
            document.body.classList.remove("loading-indicator");
            localStorage.setItem("payInt", res.data.paymentIntent);
            window.location.href = `/checkout/${res.data.paymentIntent}`;
        })
        .catch((err) => {
            document.body.classList.remove("loading-indicator");
            console.log(err);
        });



}
