import React from 'react';

const ContactUs = () => {
    return (
        <>
            <div style={{ backgroundColor: '#F3F5F3' }} className="contactUs-container" id="contact-us">
                <div className="text-center cuHeading-container">
                    <h1 className="mt-4 title">Get in touch!</h1>
                    <p>Please write to us and one of our member will get back to you soon</p>

                </div>

                <div className="form-center">
                    <form action="" className="text-center">

                        <input type="text" placeholder="Full Name" /> <br />
                        <input type="text" placeholder="Email" /> <br />
                        <input type="text" placeholder="Subject" /> <br />
                        <textarea name="" id="" cols="30" rows="10" placeholder="Your message here ..."></textarea> <br />

                        <button type="submit" className="btn btn-send" >Send Message</button>
                        <br />

                        {/* <a href='https://www.facebook.com/groups/1167039177386471/people/?should_open_welcome_member_composer=1' className="btn btn-facebook" >
                            <svg style={{ marginRight: 10 }} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                            </svg>
                            Connect on Facebook</a> */}


                    </form>
                </div>
            </div>
        </>
    );
}

export default ContactUs;