import ReactFacebookLogin from 'react-facebook-login';
import React, {  useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


function FBTest() {
    const [userId, setUserID] = useState()
    const [show, setShow] = useState(false);
    const [pages, setPages] = useState([])
    // const [pageImages, setPageImage] = useState([])
    const pageImages = []

    var pageImageData = []

    // useEffect(() => {

    //     console.log(pageImageData)
    // }, [pageImageData.length, pageImageData])



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const responseFacebook = async (response) => {
        console.log(response)
        setUserID(response.userID)
    }

    const componentClicked = (response) => {
        console.log(response)
    }

    const getUserData = async () => {
        window.FB.api(
            `/${userId}/accounts`,
            function (response) {
                setPages(response.data)
                if (response && !response.error) {
                    console.log(response)
                    /* handle the result */
                }
                response.data.forEach((data, index) => {
                    setTimeout(() => {
                        handlePageImage(data.id)
                    }, 1000 * index);
                    // handlePageImage(data.id)
                })
            }
        );
    }
    const handleLogout = () => {
        window.FB.logout(res => {
            console.log(res)
        })
    }

    const pushImage = (id) => {
        console.log(id)
        const PageID = pages.findIndex(page => +page.id === +id)
        console.log(PageID)
        if (PageID !== -1) {
            const duplicatedPages = global.structuredClone(pages)
            duplicatedPages[PageID].url = pageImages[PageID]
        }

    }
    const handlePageImage = (id) => {
        console.log(id)
        window.FB.api(
            `/${id}/picture`,
            {
                "redirect": "0"
            },
            function (response) {
                if (response && !response.error) {
                    pageImageData.push(response.data.url)
                    setTimeout(() => {
                        pushImage(id)
                    }, 2000);
                }
            }
        );
    }

    const getUserList = () => {
        window.FB.api(
            `/${userId}/friends`,
            'GET',
            {},
            function (response) {

                console.log(response)
                /* handle the result */
            }
        );
    }





    return (<>
        <div className="App">
            <ReactFacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                autoLoad={true}
                fields="name,email,picture"
                scope="public_profile,user_friends,user_messenger_contact,pages_show_list,email,pages_read_engagement,pages_manage_metadata,pages_manage_posts,"
                onClick={componentClicked}
                callback={responseFacebook} />
            <button onClick={() => getUserData()}>click</button>
            <button onClick={() => getUserList()}>list</button>
            <button onClick={() => handleLogout()}>logout</button>
            <button onClick={() => handlePageImage()}>image</button>



        </div>
        <Button variant="primary" onClick={handleShow}>
            Launch demo modal
        </Button>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header>
            <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    </>
    );
}

export default FBTest;
