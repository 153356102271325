import axios from "axios";
import { useEffect, useState } from "react";

function useClient(keyword) {
  var token = localStorage.getItem("access_token");
  const [clients, setClients] = useState({});

  const getClientsApi = async () => {
    document.body.classList.add("loading-indicator");

    var getUser = {
      method: "post",
      url: "/v1/oauth/client/get-clients-list",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { active: 1 },
    };
    if (keyword){
      getUser.data= {search: keyword}
    }

    await axios(getUser)
      .then((response) => {
        // console.log(response.data, 'response.data');
        if (response.data.status === true) {
          setClients(response.data.clients);
        }
        if (response.data.status === false){
          setClients([])
        }
        document.body.classList.remove("loading-indicator");
      })
      .catch((error) => {
        document.body.classList.remove("loading-indicator");

        if (error.response.status === 401) {
          window.localStorage.clear();
          window.location.href = "/login";
        }
      });
  };

  useEffect(() => {
    getClientsApi();
    // eslint-disable-next-line
  }, [keyword]);

  return [clients];
}

export default useClient;
