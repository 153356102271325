import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import NavbarTop from "../../NavbarTop";
import ScrollToTop from "../../ScrollToTop";
import Footer from "../../Footer";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import axios from "axios";
import { authenticate } from "../../../utils/Auth";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";

const Register = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (authenticate()) {
      window.location.href = "/dashboard";
    } else {
      setIsAuthenticated(true);
    }
  }, []);
  const [step, setStep] = useState(1);
  const [steps, setSteps] = useState(constructStepsData);
  const [emailAvailable, setAvailable] = useState(true)
  let hideIcons = true;

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone_number: "+923123456789",
    adress: "",
    city: "",
    state: "",
    zip: "",
    inspector_license_number: "",
    license_expiration_date: "",
    website: "",
    company_info: "",
    profile_image: "",
  });

  const notify = (message, status) => {
    if (status) {
      toast.success(message, {
        theme: "dark",
      });
    } else {
      toast.error(message, {
        theme: "dark",
      });
    }
  };

  const updateFormData = (step, name, value) => {
    setSteps({
      ...steps,
      [step]: {
        ...steps?.[step],
        [name]: value,
      },
    });
  };

  const handleNextStep = () => {
    if (step === 3) {
      registerUserApi();
    }
    setStep((step) => step + 1);
  };
  const handlePrevStep = () => {
    if (step === 0) return;
    setStep((step) => step - 1);
  };

  const registerUserApi = () => {
    // var FormData = require('form-data');
    var data = new FormData();
    data.append("first_name", steps?.step1?.firstName);
    data.append("last_name", steps?.step1?.lastName);
    data.append("email", steps?.step1?.email);
    data.append("password", steps?.step1?.confirmPassword);
    data.append("adress", steps?.step2?.adress);
    data.append("phone_number", steps?.step1?.phone_number);
    data.append("city", steps?.step2?.city);
    data.append("country", steps?.step2?.country);
    data.append("state", steps?.step2?.state);
    data.append("zip", steps?.step2?.zip);
    data.append(
      "inspector_license_number",
      steps?.step3?.inspector_license_number
    );
    data.append(
      "license_expiration_date",
      steps?.step3?.license_expiration_date
    );
    data.append("website", steps?.step3?.website);
    data.append("company_info", steps?.step3?.company_info);
    if (steps?.step3?.profile_image) {
      data.append("profile_image", steps?.step3?.profile_image);
    }

    var config = {
      method: "post",
      url: "/register",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.token !== undefined) {
          localStorage.setItem("access_token", response.data.token);
          notify(response.data.message, true);
        }
        window.location.href = "/plans";
      })
      .catch(function (error) {
        if (error.response.status === 422) {
          notify(error.response.data.message);
        }
      });
  };

  function validateEmail(value) {
    if (/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      var bodyFormData = new FormData();
      bodyFormData.append("email", value);

      axios({
        method: "post",
        url: "/user/check-email-available",
        data: bodyFormData,
      })
        .then((res) => {

          res.data.status === true
            ? setAvailable(true)
            : setAvailable(false);

        })
        .catch((err) => {
          console.log(err);
        });

      return true;
    }
  }

  return (
    <>
      {isAuthenticated ? (
        <>
          <ScrollToTop />
          <NavbarTop />
          <Row className="signIn-container">
            <Col md={4} className="login-img-container formimg">
              <img src="/assets/header-image.png" alt="" />
            </Col>
            <Col md={8}>
              <div className="auth-form-container">
                <div className="form-wrapper userprof">
                  <div className="auth-form-text">
                    <h2>
                      {" "}
                      <u>Sign up</u> to ProNspect
                    </h2>
                    <small>
                      Provides your customer with a professionally formatted
                      report that they can follow in a logical sequence.
                    </small>
                  </div>

                  {step === 1 && (
                    <StepOne
                      next={handleNextStep}
                      data={steps?.step1}
                      showIcons={hideIcons}
                      formFor="registration"
                      updateFormData={updateFormData}
                      formData={formData}
                      setFormData={setFormData}
                      handleEmailValidation={validateEmail}
                      handleAvailablitity={emailAvailable}
                    />
                  )}
                  {step === 2 && (
                    <StepTwo
                      next={handleNextStep}
                      prev={handlePrevStep}
                      formFor="registration"
                      data={steps?.step2}
                      updateFormData={updateFormData}
                      formData={formData}
                      setFormData={setFormData}
                    />
                  )}
                  {step === 3 && (
                    <StepThree
                      next={handleNextStep}
                      prev={handlePrevStep}
                      data={steps?.step3}
                      updateFormData={updateFormData}
                      formData={formData}
                      setFormData={setFormData}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Footer />
          <ToastContainer
            position="bottom-right"
            autoClose={3000}
            transition={Slide}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Register;

const constructStepsData = {
  step1: {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone_number: "",
  },
  step2: {
    adress: "",
    country: "",
    city: "",
    state: "",
    zip: "",
  },
  step3: {
    inspector_license_number: "",
    license_expiration_date: "",
    website: "",
    company_info: "",
    profile_image: "",
  },
};
