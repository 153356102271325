import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import DialogBox from "../../../utils/DialogBox";
import ScrollToTop from "../../ScrollToTop";
import InspectionBox from "../InspectionBox";
import NavbarDashboard from "../NavbarDashboard";
import { ToastContainer, toast } from "react-toastify";
import { Slide } from "react-toastify";
// import CreateInspectionButton from './CreateInspectionButton';
import {
  inspectionExistence,
  switchRedirection,
} from "../../../redux/reducers/inspectionSlice";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useInspection from "../../../Hooks/useInspection";
import useClient from "../../../Hooks/useClient";

const Inspection = () => {
  const [query, setQuery] = useState("");
  const [inspection] = useInspection();
  const [clients] = useClient(query);
  const history = useHistory();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const notify = (message, status) => {
    if (status) {
      toast.success(message, {
        theme: "dark",
      });
    } else {
      toast.error(message, {
        theme: "dark",
      });
    }
  };

  const inspectionExistance = useSelector(
    (state) => state.inspection.checkInspectionExistanceData
  );
  const inspectionredirection = useSelector(
    (state) => state.inspection.redirection
  );

  useEffect(() => {
    if (inspectionExistance.status === true) {
      var inspection_id;

      if (
        inspectionExistance.inspection !== null &&
        inspectionExistance.inspection !== undefined
      ) {
        inspection_id = inspectionExistance.inspection.id;
      } else {
        inspection_id = inspectionExistance.inspection_id;
      }
      if (inspection_id === 0) {
        const client_id = inspectionExistance.client.id;
        if (inspectionredirection) {
          dispatch(switchRedirection(false));
          history.push(`/select-template/${client_id}`);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionExistance]);

  const handleSelectedClient = (id) => {
    if (id === "Select Client") {
      notify("Please select client!");
    } else {
      dispatch(inspectionExistence({ client_id: id, history }));
    }
  };

  return (
    <>
      <ScrollToTop />
      <NavbarDashboard />

      <div className="content-container Mcontent">
        <div className="create-inspection-ct height-unset side-spacing pt50 align-items-center">
          <div
            className="d-flex justify-content-between align-items-center"
            id="inspection-header">
            <div className="text-ct w-50">
              <h2>Create Inspection</h2>
              <p>
                Access an inspection that you have already started or create a
                new inspection.
              </p>
              {/* <CreateInspectionButton /> */}
              <button className="new-Ins-btn" onClick={handleShow}>
                <div className="circlePlus"> + </div>
                Create/Resume Inspection
              </button>
            </div>
            <img src="/assets/icon/home-search.png" alt="" />
          </div>

          <div className="d-flex justify-content-between mt50">
            <label className="greylight fs20">Recent Inspection</label>
            <Link
              className="greylight text-decoration-none fs20"
              to="/inspection-list">
              View All
            </Link>
          </div>
          {inspection && (
            <>
              {inspection.length > 0 ? (
                <Row className="mt50">
                  <Col xl={4} lg={4} md={6}>
                    <InspectionBox data={inspection[0]} />
                  </Col>
                  {inspection.length > 1 ? (
                    <Col md={6} lg={4} xl={4}>
                      <InspectionBox data={inspection[1]} />
                    </Col>
                  ) : null}
                  {inspection.length > 2 ? (
                    <Col md={6} lg={4} xl={4}>
                      <InspectionBox data={inspection[2]} />
                    </Col>
                  ) : null}
                </Row>
              ) : null}
            </>
          )}
        </div>
      </div>

      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        transition={Slide}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      {show && (
        <DialogBox
          handleClose={handleClose}
          show={show}
          title="Select Client"
          status="selectClient"
          data={clients}
          handleSelectedClient={handleSelectedClient}
          setQuery={setQuery}
        />
      )}
    </>
  );
};

export default Inspection;
