import React, { useEffect, useState } from "react";
import ScrollToTop from "../../ScrollToTop";
import NavbarDashboard from "../NavbarDashboard";
import { Editor } from '@tinymce/tinymce-react';
import { useParams } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";

const DescriptionTemplate = () => {
  let token = localStorage.getItem("access_token");
  let history = useHistory();
  const {id:templateId } = useParams();
  const [paragraph_text, setParagraphText] = useState("");
  const [conventions, setConventions] = useState("");
  const [scope, setScope] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchDescription = async () => {
    const config ={
      method: "get",
      url: `/v1/oauth/templates/details/${templateId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    try {
      const response = await axios(config);
      console.log(response.data.template);
      setParagraphText(response.data.template.paragraph_text || "");
      setConventions(response.data.template.conventions || "");
      setScope(response.data.template.scope || "");
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
fetchDescription()
  },[])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const config = {
      method: "post",
      url: `/v1/oauth/templates/property-description`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        paragraph_text,
        conventions,
        scope,
        template_id: templateId,
      }
    }
    try {
      const response = await axios(config);
      console.log(response.data.template);
      // Redirect to the next page (replace '/next-page' with your actual next page route)
      history.push(`/template-details/${templateId}`);
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false);
    }
  }

  const editorInitConfig = {
    height: 300,
    menubar: false,
    plugins: [
      'list', 'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'pagebreak'
    ],
    toolbar: 'bullist numlist | formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | fontsizeselect styleselect blockquote hr',
    toolbar_mode: 'sliding',
    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable'
  };

  return (
    <>
    <ScrollToTop />
    <NavbarDashboard />
    <div className="content-container Mcontent">
      <div className="side-spacing pt50">
        <div className="p-0">
          <h2 className="mb20">Template</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="paragraph_text" className="mb10 fw-bold fs-4">
              Property Description
            </label>
            <textarea
              id="paragraph_text"
              name="paragraph_text"
              className="form-control"
              rows={10}
              value={paragraph_text}
              onChange={(e) => setParagraphText(e.target.value)}
            />
            <small className="input-error"></small>
          </div>
          <br />
          <br />
          <EditorField
            label="Conventions Used In This Report"
            initConfig={editorInitConfig}
            value={conventions}
            onChange={setConventions}
          />
          <EditorField
            label="Scope"
            initConfig={editorInitConfig}
            value={scope}
            onChange={setScope}
          />
          <div className="text-center">
            <button type="submit" className="btn btn-submit w-100" disabled={isLoading}>
              {isLoading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </div>
  </>
);
};

const EditorField = ({ label, initConfig, value, onChange }) => (
<>
  <label className="mb10 fw-bold fs-4">{label}</label>
  <Editor
    apiKey={process.env.REACT_APP_TINY_MCE_KEY}
    init={initConfig}
    value={value}
    onEditorChange={(content) => onChange(content)}
  />
  <br /><br />
</>
);

export default DescriptionTemplate;
