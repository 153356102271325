import { useState } from "react";
import CategoryCheckTemplate from "./CategoryCheckTemplate";
import { Modal, Button } from "react-bootstrap";

const StepCategoryTemplate = (props) => {
  const { onEdit, onDelete, addSubItem } = props;
  const data = props.data;
  const [showItemUpdate, setShowItemUpdate] = useState(false);
  const [inputValue, setInputValue] = useState();
  const [modalState, setModalState] = useState("add");
  const [deleteConfirmShow, setDeleteConfirmShow] = useState(false);

  const handleEditClick = () => {
    setModalState("update");
    setInputValue(data.title); // Set the current name of the item to the input field
    setShowItemUpdate(true);
  };

  const handleDeleteClick = () => {
    onDelete(data.id, data.template_id);
    setDeleteConfirmShow(false);
  };
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleCloseUpdateItem = () => {
    setShowItemUpdate(false);
    setInputValue("");
  };
  const handleUpdateItem = () => {
    const newTitle = inputValue;
    onEdit(data.template_id, newTitle, data.step_id, data.id, data.type);
    setShowItemUpdate(false);
  };

  const handleAddSubItem = () => {
    setModalState("add");
    setShowItemUpdate(true);
  };
  const handleAddSubItems = () => {
    addSubItem(data.template_id, inputValue, data.step_id, data.id, data.type);
    setInputValue("");
    setShowItemUpdate(false);
  };

  const {
    activeCategoryStep,
    tabName,
    MobileCheck,
    handleGetCategoryLabel,
    handleOpenCategoryDeletionModal,
    handleUpdateSubCategory,
  } = props;
  let FirstLetter = data.title[0].toUpperCase();
  let SecondLetter = data.title[1].toUpperCase();
  const handleGetSubCategory = (id) => {
    handleUpdateSubCategory(id);
  };
  return (
    <>
      <div
        className={
          "d-flex align-items-center justify-content-between circle-card-ct cursor-pointer w-100 " +
          (activeCategoryStep === data.id ? "active" : "")
        }
        onClick={() => handleGetSubCategory(data.id)}
      >
        <div className="d-flex align-items-center">
          <div className="float-start">
            <div className="circle-name  mr10">
              <div>{FirstLetter + SecondLetter} </div>
            </div>
          </div>
          <h5 className="mb-0 d-flex break-word">{data.title}</h5>
        </div>

        <div className="d-flex align-items-center">
          <img
            src="/assets/icon/editicon.png"
            alt="edit"
            onClick={handleEditClick}
          />
          <img
            src="/assets/icon/delete.png"
            alt="edit"
            height={"20px"}
            width={"20px"}
            onClick={() => setDeleteConfirmShow(true)}
          />

          <img
            src="/assets/icon/add.png"
            alt="delete"
            height={"20px"}
            width={"20px"}
            onClick={handleAddSubItem}
          />
        </div>

        {/* <div className={tabName === "observation" ? "" : "hide"}>
          <img
            style={{ height: 40 }}
            src="/assets/icon/add.png"
            alt="add category"
          />
        </div> */}
      </div>

      {/* {MobileCheck && (
        <CategoryCheckTemplate
          data={{
            checked: true,
            label: "Dummy Data",
          }}
          tabName={tabName}
          handleGetCategoryLabel={handleGetCategoryLabel}
          handleOpenCategoryDeletionModal={handleOpenCategoryDeletionModal}
          MobileCheck={MobileCheck}
        />
      )} */}

      <Modal
        show={showItemUpdate}
        onHide={handleCloseUpdateItem}
        className="p-3"
      >
        <Modal.Header
          closeButton
          className="my-2"
          style={{ borderBottom: "none" }}
        >
          <Modal.Title>
            {modalState === "update" ? "Update Item" : "Add Sub Item"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4 w-100">
          <input
            type="text"
            placeholder="Enter Name"
            value={inputValue}
            style={{
              border: "none",
              borderBottom: "1px solid #CDCDCD",
              width: "100%",
            }}
            onChange={(e) => handleChange(e)}
          />
        </Modal.Body>
        <Modal.Footer className="my-2" style={{ borderTop: "none" }}>
          <Button
            variant="secondary"
            className="m-0 w-25 mx-2 "
            onClick={handleCloseUpdateItem}
          >
            Cancel
          </Button>
          <Button
            className="m-0 w-25 btn-submit mx-2 "
            variant="primary"
            onClick={() =>
              modalState === "update" ? handleUpdateItem() : handleAddSubItems()
            }
          >
            Next
          </Button>
        </Modal.Footer>
      </Modal>
      {/* DELETE CONFIRMATION MODAL */}
      <Modal
        show={deleteConfirmShow}
        onHide={() => setDeleteConfirmShow(false)}
        className="p-3"
      >
        <Modal.Header
          closeButton
          className="my-2"
          style={{ borderBottom: "none" }}
        >
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          Are you sure you want to delete this template?
        </Modal.Body>
        <Modal.Footer className="my-2" style={{ borderTop: "none" }}>
          <Button
            variant="secondary"
            className="m-0 w-25 mx-2"
            onClick={() => setDeleteConfirmShow(false)}
          >
            Cancel
          </Button>
          <Button
            className="m-0 w-25 btn-submit mx-2"
            variant="danger"
            onClick={handleDeleteClick}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StepCategoryTemplate;
