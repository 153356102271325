import React from "react";
// import { Link } from "react-router-dom";
import NavbarDashboard from "../components/admin/NavbarDashboard";

const NotFound404 = () => {
  return (
    <>
      <div style={{ height: '100%', position: 'relative', width: '100%' }}>
        <NavbarDashboard />
        <div style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', display: 'flex', width: '100%', justifyContent: 'center' }}>
          <h4>404 - Page Not Found</h4>
        </div>
      </div>
    </>
  );
};

export default NotFound404;
