import React from 'react'
import TableRow from './TableRow'

const Table = ({ Logs, handleOpen }) => {
  return (
    <div><table className="table">
      <thead>
        <tr>
          <th scope="col">Client Name</th>
          <th scope="col">Client Email</th>
          <th scope="col">Status</th>
        </tr>
      </thead>
      <tbody>
        {Logs.map(log => {
          return <TableRow key={log.id} handleOpen={handleOpen} data={log} />
        })}
      </tbody>
    </table></div>
  )
}

export default Table