import React, { useState, useEffect } from "react";
import ScrollToTop from "../../../ScrollToTop";
import axios from "axios";
import StepOne from "../../../AuthForms/Register/StepOne";
import StepTwo from "../../../AuthForms/Register/StepTwo";
import StepThree from "../../../AuthForms/Register/StepThree";
import NavbarDashboard from "../../NavbarDashboard";
import { useHistory } from "react-router-dom";

function UpdateUser() {
  const [step, setStep] = useState(1);
  const [steps, setSteps] = useState(constructStepsData);
  const [emailAvailable, setAvailable] = useState(true)
  const [existEmail, setExistEmail] = useState('')
  let hideIcons = false;
  var token = localStorage.getItem("access_token");
  var history = useHistory();

  const getAuthUserApi = async () => {
    document.body.classList.add("loading-indicator");
    var getUser = {
      method: "get",
      url: "/v1/oauth/user/my-account",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios(getUser)
      .then((response) => {
        setExistEmail(response.data.user.email)
        setSteps(convertStepsToReact(response.data.user));
        document.body.classList.remove("loading-indicator");
      })
      .catch(function (error) {
        console.log(error);
        document.body.classList.remove("loading-indicator");
      });
  };
  useEffect(() => {
    getAuthUserApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateFormData = (step, name, value) => {
    setSteps({
      ...steps,
      [step]: {
        ...steps?.[step],
        [name]: value,
      },
    });
  };
  const updateUserApi = () => {
    document.body.classList.add("loading-indicator");
    var data = new FormData();
    data.append("first_name", steps?.step1?.firstName);
    data.append("last_name", steps?.step1?.lastName);
    data.append("email", steps?.step1?.email);
    data.append("adress", steps?.step2?.adress);
    data.append("phone_number", steps?.step1?.phone_number);
    data.append("city", steps?.step2?.city);
    data.append("country", steps?.step2?.country);
    data.append("state", steps?.step2?.state);
    data.append("zip", steps?.step2?.zip);
    data.append(
      "inspector_license_number",
      steps?.step3?.inspector_license_number
    );
    data.append(
      "license_expiration_date",
      steps?.step3?.license_expiration_date
    );
    data.append("website", steps?.step3?.website);
    data.append("company_info", steps?.step3?.company_info);
    if (typeof steps?.step3?.profile_image !== "string") {
      data.append("profile_image", steps?.step3?.profile_image);
    } else {
      data.append("profile_image", "");
    }

    var config = {
      method: "post",
      url: "/v1/oauth/user/update-profile",
      // url: '/v1/oauth/user/my-account/update',
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === true) {
          document.body.classList.remove("loading-indicator");
          let newUserData = {
            first_name: response.data.user.first_name,
            last_name: response.data.user.last_name,
            country: response.data.user.country,
            city: response.data.user.city,
            profile_image: response.data.user.profile_image,
          };
          const usData = localStorage.getItem("userData");
          if (JSON.stringify(newUserData) !== usData) {
            localStorage.removeItem("userData");
            localStorage.setItem("userData", JSON.stringify(newUserData));
          }
          history.push("/my-account");
        }
      })
      .catch(function (error) {
        document.body.classList.remove("loading-indicator");
      });
  };
  const handleNextStep = () => {
    if (step === 3) {
      updateUserApi();
    } else {
      setStep((step) => step + 1);
    }
  };
  const handlePrevStep = () => {
    if (step === 0) return;
    setStep((step) => step - 1);
  };

  function validateEmail(value) {
    if (value === existEmail) {
      setAvailable(true) 
      return true
    }
    if (/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      var bodyFormData = new FormData();
      bodyFormData.append("email", value);

      axios({
        method: "post",
        url: "/user/check-email-available",
        data: bodyFormData,
      })
        .then((res) => {
          
            res.data.status === true
              ? setAvailable(true)
              : setAvailable(false);
          
        })
        .catch((err) => {
          console.log(err);
        });

      return true;
    }
  }

  return (
    <>
      <ScrollToTop />
      <NavbarDashboard />
      <div className="content-container Mcontent">
        <div className="side-spacing pt50 ">
          <div className="auth-form-container ">
            <div className="edit-profile">
              <div className="auth-form-text">
                <h3> Edit Profile</h3>
              </div>

              {step === 1 && (
                <StepOne
                handleEmailValidation={validateEmail}
                  next={handleNextStep}
                  showIcons={hideIcons}
                  data={steps?.step1}
                  updateFormData={updateFormData}
                  handleAvailablitity={emailAvailable}
                />
              )}
              {step === 2 && (
                <StepTwo
                  next={handleNextStep}
                  prev={handlePrevStep}
                  data={steps?.step2}
                  updateFormData={updateFormData}
                />
              )}
              {step === 3 && (
                <StepThree
                  next={handleNextStep}
                  prev={handlePrevStep}
                  data={steps?.step3}
                  updateFormData={updateFormData}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateUser;

const constructStepsData = {
  step1: {
    firstName: "",
    lastName: "",
    email: "",
    phone_number: "",
  },
  step2: {
    adress: "",
    country: "",
    city: "",
    state: "",
    zip: "",
  },
  step3: {
    inspector_license_number: "",
    license_expiration_date: "",
    website: "",
    company_info: "",
    profile_image: "",
  },
};

const convertStepsToReact = (res) => {
  var dateVal = res.license_expiration_date.split("/").reverse().join("-");
  return {
    step1: {
      firstName: res.first_name,
      lastName: res.last_name,
      email: res.email,
      phone_number: res.phone_number,
    },
    step2: {
      adress: res.address,
      country: res.country,
      city: res.city,
      state: res.state,
      zip: res.zip,
    },
    step3: {
      inspector_license_number: res.inspector_license_number,
      license_expiration_date: dateVal,
      website: res.website,
      company_info: res.company_info,
      profile_image: res.profile_image,
    },
  };
};
