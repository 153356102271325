import React from "react";
import { Form } from "react-bootstrap";

const CategoryCheck = (props) => {
  const {
    checked,
    title,
    inspection_id,
    inspection_step_id,
    inspection_step_category_id,
    type,
    id,
  } = props.data;
  const {
    handleChangeInCheckbox,
    tabName,
    handleGetCategoryLabel,
    handleViewPopupGetValues,
    handleOpenCategoryDeletionModal,
  } = props;

  const handleChange = async (e) => {
    const isChecked = e.target.checked;
    await handleChangeInCheckbox(id, isChecked, title);
    
    if (isChecked || type === "recommendation") {
      handleViewClick({
        id,
        title,
        checked: isChecked,
        inspection_step_category_id,
        inspection_step_id,
        type,
      });
    }
  };

  const handleEditClick = () => {
    if (typeof handleGetCategoryLabel === 'function') {
      handleGetCategoryLabel(
        title,
        inspection_id,
        inspection_step_id,
        inspection_step_category_id,
        type,
        id
      );
    } else {
      console.error('handleGetCategoryLabel is not a function');
    }
  };

  const handleDeleteClick = () => {
    if (typeof handleOpenCategoryDeletionModal === 'function') {
      handleOpenCategoryDeletionModal(title, id);
    } else {
      console.error('handleOpenCategoryDeletionModal is not a function');
    }
  };

  const handleViewClick = (data, viewType) => {
    if (typeof handleViewPopupGetValues === 'function') {
      if (viewType === "view" || (data.type === "recommendation" && data.checked)) {
        handleViewPopupGetValues(data, id);
      }
    } else {
      console.error('handleViewPopupGetValues is not a function');
    }
  };

  const showEditDelete = tabName === "description" || tabName === "recommendation";
  const showView = tabName === "observation" || tabName === "recommendation";

  return (
    <div className="d-flex align-items-center justify-content-between mb20">
      <Form.Check
        type="checkbox"
        id={`checkbox-${id}`}
        name={`checkbox-${id}`}
        className="custom-formCheck"
        label={title}
        checked={checked}
        onChange={handleChange}
      />

      <div className="d-flex">
        {showEditDelete && (
          <>
            <div className="ml20 cursor-pointer" onClick={handleEditClick}>
              <img src="/assets/edit.png" alt="Edit" />
            </div>
            <div className="ml20 cursor-pointer" onClick={handleDeleteClick}>
              <img src="/assets/close.png" style={{ height: 20, width: 20 }} alt="Delete" />
            </div>
          </>
        )}
        {showView && (
          <div
            className="ml20 cursor-pointer"
            onClick={() =>
              handleViewClick(
                {
                  id,
                  title,
                  checked,
                  inspection_step_category_id,
                  inspection_step_id,
                  type,
                },
                "view"
              )
            }
          >
            <img src="/assets/eye.png" alt="View" style={{ height: 20, width: 20 }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryCheck;