// import React, { Component } from 'react'
import { Card, Row, Col } from 'react-bootstrap';
import MenuCardArray from './MenuArray/MenuArray';
import { Link } from 'react-router-dom'

const MenuCards = () => {


    return <>
        <Row className="side-spacing menu-links-ct">
            {MenuCardArray.map(function Array(val, index) {
                return (
                    <Col md={2} key={val.id}>
                        <Link key={index} to={val.url} style={{ color: 'inherit', textDecoration: 'none' }} >

                            <Card className="card-custom menuCard-height">
                                <Card.Body className="text-center">
                                    <img src={val.image} className="menuCard-img" alt="myAcc" />
                                    <h6 className="mt-4"> {val.menu_heading} </h6>
                                </Card.Body>
                            </Card>
                        </Link>
                    </Col>
                );
            })}

        </Row>
    </>;

}

export default MenuCards