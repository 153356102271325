import React from 'react'
import ScrollToTop from '../../ScrollToTop';
import NavbarDashboard from '../NavbarDashboard';

import CreateInspection from './CreateInspection';
import MenuCards from './MenuCards';
import QuickHelp from './QuickHelp';
import { isAuthenticated } from '../../../utils/Auth';
import { useEffect } from 'react';
import axios from 'axios'

const Dashboard = () => {
    const getAuthUserApi = () => {
        let token = localStorage.getItem("access_token");
        var getUser = {
            method: "get",
            url: "/v1/oauth/user/get-user",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        axios(getUser)
            .then(function (response) {
                const user = response.data.user;
                if (user.is_payment_done !== 1) {
                    localStorage.setItem("paymentStatus", false);
                } else {
                    localStorage.setItem("paymentStatus", true);
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    window.location.href = "/login";
                }
            });
    };
    useEffect(() => {
        getAuthUserApi()
    })

    isAuthenticated();

    return <>
        <ScrollToTop />
        <NavbarDashboard />
        <div className="content-container dashboardContent">
            <CreateInspection />
            <MenuCards />
            <QuickHelp />
        </div>
    </>;

}

export default Dashboard;