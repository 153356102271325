import ImageEditor from "@toast-ui/react-image-editor";
import "tui-image-editor/dist/tui-image-editor.css";
import 'tui-color-picker/dist/tui-color-picker.css';
import "./ToastUI.css";

const myTheme = {
  // Theme object to extends default dark theme.
  "header.display": "none",
};

const ToastUI = ({ source }) => (
  <ImageEditor
    includeUI={{
      loadImage: {
        path: source,
        name: "SampleImage",
      },
      theme: myTheme,
      menu: [
        "crop",
        "flip",
        "rotate",
        "draw",
        "shape",
        "icon",
        "text",
        "filter",
      ],
      initMenu: "filter",
      uiSize: {
        width: "1000px",
        height: "700px",
      },
      menuBarPosition: "bottom",
    }}
    cssMaxHeight={500}
    cssMaxWidth={700}
    selectionStyle={{
      cornerSize: 20,
      rotatingPointOffset: 70,
    }}
    usageStatistics={true}
  />
);

export default ToastUI;
