import React from "react";
import { Form } from "react-bootstrap";

const RadioCheck = (props) => {
  const {
    checked,
    title,
    id,
    inspection_step_category_id,
    inspection_step_id,
    type,
  } = props.data;
  const { handleChangeInRadio, handleViewPopupGetValues } = props;

  const handleChange = async () => {
    await handleChangeInRadio(id, !checked, title);
    handleViewClick();
  };

  const handleViewClick = () => {
    handleViewPopupGetValues(
      {
        id,
        title,
        checked,
        inspection_step_category_id,
        inspection_step_id,
        type,
      },
      id
    );
  };

  const handleDivClick = () => {
    if (!checked) {
      handleChange();
    } else {
      handleViewClick();
    }
  };

  return (
    <div 
      className="d-flex align-items-center justify-content-between mb20"
      onClick={handleDivClick}
      style={{
        cursor: "pointer",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flex: 1,
        }}
      >
        <Form.Check
          type="radio"
          name="group1"
          checked={checked}
          onChange={() => {}} // We'll handle changes in the onClick
          onClick={(e) => {
            e.stopPropagation(); // Prevent the outer div's onClick from firing
            handleDivClick(); // Call handleDivClick directly
          }}
        />
        <span style={{ marginLeft: "10px" }}>{title}</span>
      </div>

      <div className="d-flex">
        <div 
          className="ml20 cursor-pointer" 
          onClick={(e) => {
            e.stopPropagation();
            handleViewClick();
          }}
        >
          <img src="/assets/eye.png" alt="" style={{ height: 20, width: 20 }} />
        </div>
      </div>
    </div>
  );
};

export default RadioCheck;