import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import ScrollToTop from "../../ScrollToTop";

const StepTwo = ({ updateFormData, data, next, prev, formFor }) => {
  const [clicked, setClicked] = useState({
    address: "",
    country: "",
    city: "",
    state: "",
    zip: "",
  });
  const validate = Yup.object({
    adress: Yup.string().required("Enter Address"),
  });
  const validateUpdateForm = Yup.object({
    adress: Yup.string().required("Enter Address"),

  });
  const submitForm = () => {
    next();
  };
  return (
    <>
      <ScrollToTop />
      <Formik
        initialValues={data}
        enableReinitialize
        validationSchema={
          formFor === "registration" ? validate : validateUpdateForm
        }
        onSubmit={submitForm}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleSubmit,
            handleBlur,
          } = props;
          return (
            <form onSubmit={handleSubmit}>
              <div className="input-custom form-group">
                <input
                  onClick={() => setClicked({ ...clicked, address: true })}
                  type="text"
                  name="adress"
                  onBlur={handleBlur}
                  className={
                    "form-control " +
                    (errors.adress && touched.adress && "br-danger")
                  }
                  value={values?.adress || ""}
                  onChange={(e) => {
                    handleChange(e);
                    updateFormData("step2", e.target.name, e.target.value);
                  }}
                  placeholder="Address *"
                  autoComplete="no-fill"
                />
                {errors.adress && touched.adress && (
                  <small className="input-error">{errors.adress} </small>
                )}
              </div>
              <div className="input-custom form-group">
                <input
                  onClick={() => setClicked({ ...clicked, country: true })}
                  type="text"
                  name="country"
                  onBlur={handleBlur}
                  value={values?.country || ""}
                  className={
                    "form-control " +
                    (errors.country && touched.country && "br-danger")
                  }
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^A-Za-z ]/gi, "");
                    handleChange(value);
                    updateFormData("step2", e.target.name, value);
                  }}
                  placeholder="Country "
                  autoComplete="no-fill"
                />
                {errors.country && touched.country && (
                  <small className="input-error">{errors.country} </small>
                )}
              </div>
              <div className="input-custom form-group">
                <input
                  onClick={() => setClicked({ ...clicked, city: true })}
                  type="text"
                  name="city"
                  onBlur={handleBlur}
                  value={values?.city || ""}
                  className="form-control "
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^A-Za-z ]/gi, "");
                    handleChange(value);
                    updateFormData("step2", e.target.name, value);
                  }}
                  placeholder="City "
                  autoComplete="no-fill"
                />
                {errors.city && touched.city && (
                  <small className="input-error">{errors.city} </small>
                )}
              </div>

              {/* <div className="sZ-fields"> */}
              <div className="input-custom form-group">
                <input
                  onClick={() => setClicked({ ...clicked, state: true })}
                  type="text"
                  name="state"
                  onBlur={handleBlur}
                  value={values?.state || ""}
                  className="form-control"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^A-Za-z ]/gi, "");
                    handleChange(value);
                    updateFormData("step2", e.target.name, value);
                  }}
                  placeholder="State "
                  autoComplete="no-fill"
                />
                {errors.state && touched.state && (
                  <small className="input-error">{errors.state} </small>
                )}
              </div>
              <div className="input-custom form-group">
                <input
                  onClick={() => setClicked({ ...clicked, zip: true })}
                  type="number"
                  name="zip"
                  value={values?.zip || ""}
                  className="form-control"
                  onChange={(e) => {
                    handleChange(e);
                    updateFormData("step2", e.target.name, e.target.value);
                  }}
                  placeholder="Zip "
                  autoComplete="no-fill"
                />
              </div>
              {/* </div> */}
              <br />

              <button type="submit" className="btn btn-submit ">
                Continue <i className="fa fa-arrow-right"></i>{" "}
              </button>
              <button
                type="button"
                className="btn btn-light "
                onClick={() => prev()}
              >
                Back{" "}
              </button>
            </form>
          );
        }}
      </Formik>
      <br />
    </>
  );
};

export default StepTwo;
