const MenuCardArray = [
    {
        id: 1,
        image: '/assets/profile-black.png',
        url: '/my-account',
        menu_heading: 'My Account',
    },
    {
        id: 2,
        image: '/assets/clients.png',
        url: '/clients',
        menu_heading: 'Clients',
    },
    {
        id: 3,
        image: '/assets/inspection.png',
        url: '/inspection',
        menu_heading: 'Inspection',
    },
    {
        id: 4,
        image: '/assets/document.png',
        url: '/inspection-list',
        menu_heading: 'Inspection List',
    },
    {
        id: 5,
        image: '/assets/profit-report.png',
        url: '/reports',
        menu_heading: 'Report',
    },
    {
        id: 6,
        image: '/assets/medical-report.png',
        url: '/reports-history',
        menu_heading: 'Report History',
    },
]


export default MenuCardArray;