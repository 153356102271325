import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import axios from "axios";
// import { ToastContainer, toast } from "react-toastify";
// import { Slide } from "react-toastify";

const Header = ({ client, inspection, user, client_id }) => {
  var token = localStorage.getItem("access_token");
  const { address, city, state, zip, username } = client;
  const { first_name, last_name, email, phone_number, profile_image } = user;
  let { page_cover, start_date, end_date, added_date } = inspection;
  const inspectionDate = added_date.slice(0, 10);
  if (start_date !== null) {
    var dateMomemtStart = moment(start_date, "YYYY/MM/DD");
    var dateStart = dateMomemtStart.toDate();
    var dateMomemtend = moment(end_date, "YYYY/MM/DD");
    var dateEnd = dateMomemtend.toDate();
  }
  const [startDate, setStartDate] = useState(dateStart);
  const [endDate, setEndDate] = useState(dateEnd);
  function changeDateHandler(updatedStartDate, updatedEndDate) {
    if (startDate !== null) {
      document.body.classList.add("loading-indicator");
      const weatherVals = {
        client_id: client_id,
        start_date: formatDate(updatedStartDate),
        end_date: formatDate(updatedEndDate),
      };
      function formatDate(date) {
        var d = new Date(date),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
      }
      const configForm = {
        method: "post",
        url: "/v1/oauth/inspection/update-inspection-time",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: weatherVals,
      };
      axios(configForm)
        .then((res) => {
          document.body.classList.remove("loading-indicator");
          // if (res.data.status === true) {
          //   notify(res.data.message, true);
          // }
          // else {
          //   notify(res.data.message);
          // }
        })
        .catch((err) => {
          // notify("Something went wrong!");
          if (err?.response?.status === 401) {
            window.location = "/login";
          }
        });
    }
  }

  // const notify = (message, status) => {
  //   if (status) {
  //     toast.success("Changes saved", {
  //       theme: "dark",
  //     });
  //   } else {
  //     toast.dark(message, {
  //       theme: "dark",
  //     });
  //   }
  // };

  return (
    <>
      <div>
        <div className="text-center">
          <strong className="reviewHeading">Property Inspection Report</strong>
        </div>
        <br />
        <p className="text-center">
          {address}, {city}, {state} {zip}
        </p>
        <hr />
        <div className="text-center m-auto">
          <strong>Inspection Date</strong>
          <p>{inspectionDate}</p>
        </div>
        <br />
        {start_date !== null && (
          <div className="text-center m-auto ">
            <div
              className="inspection-date"
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
              }}
            >
              <div className="review-inspec-start" >
                <p>
                  <strong>Inspection Start Date:</strong>
                </p>

                <DatePicker
                  dateFormat="MM/dd/yyyy"
                  selected={startDate}
                  maxDate={endDate}
                  onChange={(date) => {
                    changeDateHandler(date, endDate )
                    setStartDate(date)
                  }}
                />
              </div>

              <div className="review-inspec-end">
                <p>
                  <strong>Inspection End Date:</strong>
                </p>
                <DatePicker
                  dateFormat="MM/dd/yyyy"
                  minDate={startDate}
                  selected={endDate}
                  onChange={(date) => {
                    changeDateHandler(startDate, date)
                    setEndDate(date);
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <div className="text-center m-auto ">
          <div className="mb20">
            <p>
              <strong>Prepared For: {username}</strong>
            </p>
            <p>User Inspection</p>
            {page_cover && (

              <img
                src={page_cover}
                style={{ maxWidth: "420px", height: "250px" }}
                alt=""
              />
            )}
          </div>
        </div>
        <div className="text-center m-auto ">
          <p>
            <strong>Prepared By:</strong>
          </p>
          <div className="text-center">
            <p>
              {first_name} {last_name}{" "}
            </p>
            <p> {address} </p>
            <p> {phone_number} </p>
            <p>{email} </p>
          </div>
        </div>
        <div className="text-center m-auto ">
          <div className="mb20">
            <p>
              <strong>Inspector:</strong>
            </p>
            <p>
              {first_name} {last_name}
            </p>
            {profile_image && (

              <img
                src={profile_image}
                style={{ width: "170px", height: "170px" }}
                alt=""
              />
            )}
          </div>
        </div>
      </div>
      {/* <ToastContainer
        position="bottom-right"
        autoClose={2000}
        transition={Slide}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
    </>
  );
};

export default Header;
