import React from "react";
import "./Step.css";

const Footer = ({ step, stepId }) => {
  const Limitations = [
    {
      step: "portfolio",
      limitations: [
        "Structural components concealed behind finished surfaces could not be inspected.",
        "Only a representative sampling of visible structural components were inspected.",
        "Furniture and/or storage restricted access to some structural components.",
        "Engineering or architectural services such as calculation of structural capacities, adequacy, or integrity are not part of a home inspection.",
      ],
    },
    {
      step: "roofing",
      limitations: [
        "Not all of the underside of the roof sheathing is inspected for evidence of leaks.",
        "Evidence of prior leaks may be disguised by interior finishes.",
        "Estimates of remaining roof life are approximations only and do not preclude the possibility of leakage. Leakage can develop at any time and may depend on rain intensity, wind direction, ice build up, and other factors.",
        "Antennae, chimney/flue interiors which are not readily accessible are not inspected and could require repair.",
        "Roof inspection may be limited by access, condition, weather, or other safety concerns.",
      ],
    },
    {
      step: "exterior",
      limitations: [
        "A representative sample of exterior components was inspected rather than every occurrence of components.",
        "The inspection does not include an assessment of geological, geotechnical, or hydrological conditions, or environmental hazards.",
        "Screening, shutters, awnings, or similar seasonal accessories, fences, recreational facilities, outbuildings, seawalls, break-walls, docks, erosion control and earth stabilization measures are not inspected unless specifically agreed-upon and documented in this report.",
      ],
    },
    {
      step: "electrical",
      limitations: [
        "Electrical components concealed behind finished surfaces are not inspected.",
        "Only a representative sampling of outlets and light fixtures were tested.",
        "Furniture and/or storage restricted access to some electrical components which may not be inspected.",
        "The inspection does not include remote control devices, alarm systems and components, low voltage wiring, systems, and components, ancillary wiring, systems, and other components which are not part of the primary electrical power distribution system.",
      ],
    },
    {
      step: "heating",
      limitations: [
        "The adequacy of heat supply or distribution balance is not inspected.",
        "The interior of flues or chimneys which are not readily accessible are not inspected.",
        "The furnace heat exchanger, humidifier, or dehumidifier, and electronic air filters are not inspected.",
        "Solar space heating equipment/systems are not inspected.",
      ],
    },
    {
      step: "cooling",
      limitations: [
        "Window mounted air conditioning units are not inspected.",
        "The cooling supply adequacy or distribution balance are not inspected.",
      ],
    },
    {
      step: "insulation",
      limitations: [
        "Insulation/ventilation type and levels in concealed areas are not inspected. Insulation and vapor barriers are not disturbed and no destructive tests (such as cutting openings in walls to look for insulation) are performed.",
        "Potentially hazardous materials such as Asbestos and Urea Formaldehyde Foam Insulation (UFFI) cannot be positively identified without a detailed inspection and laboratory analysis. This is beyond the scope of the inspection.",
        "An analysis of indoor air quality is not part of our inspection unless explicitly contracted-for and discussed in this or a separate report.",
        "Any estimates of insulation R values or depths are rough average values.",
      ],
    },
    {
      step: "plumbing",
      limitations: [
        "Portions of the plumbing system concealed by finishes and/or storage (below sinks, etc.), below the structure, or beneath the ground surface are not inspected.",
        "Water quantity and water quality are not tested unless explicitly contracted-for and discussed in this or a separate report.",
        "Clothes washing machine connections are not inspected.",
        "Interiors of flues or chimneys which are not readily accessible are not inspected.",
        "Water conditioning systems, solar water heaters, fire and lawn sprinkler systems, and private waste disposal systems are not inspected unless explicitly contracted-for and discussed in this or a separate report.",
      ],
    },
    {
      step: "interior",
      limitations: [
        "Furniture, storage, appliances and/or wall hangings are not moved to permit inspection and may block defects.",
        "Carpeting, window treatments, central vacuum systems, household appliances, recreational facilities, paint, wallpaper, and other finish treatments are not inspected.",
        "Please also refer to the pre-inspection contract for a detailed explanation of the scope of this inspection.",
      ],
    },
    {
      step: "appliance",
      limitations: [
        "Thermostats, timers and other specialized features and controls are not tested.",
        "The temperature calibration, functionality of timers, effectiveness, efficiency and overall performance of appliances is outside the scope of this inspection.",
      ],
    },
    {
      step: "fireplaces",
      limitations: [
        "Thermostats, timers and other specialized features and controls are not tested.",

        "The temperature calibration, functionality of timers, effectiveness, efficiency and overall performance of appliances is outside the scope of this inspection.",
      ],
    },
  ];
  // const LimitationIndex = Limitations.findIndex(
  //   (limit) => limit.step === stepId
  // );
  const LimitationData = Limitations[0].limitations;
  return (
    <div>
      <h4 className="text-uppercase" style={{ marginTop: 30 }}>
        LIMITATIONS OF {step}
      </h4>{" "}
      <hr />
      <p className="text-uppercase limitation">
        As we have discussed and as described in your inspection contract, this
        is a visual inspection limited in scope by (but not restricted to) the
        following conditions:
      </p>
      <ul>
        {LimitationData.map((limit) => {
          return (
            <div key={limit}>
              <li className="limitation">{limit}</li>
            </div>
          );
        })}
      </ul>
      <p className="limitation">
        Please also refer to the pre-inspection contract for a detailed
        explanation of the scope of this inspection.
      </p>
    </div>
  );
};

export default Footer;
