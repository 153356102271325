import { configureStore } from "@reduxjs/toolkit";
import inspectionReducer from "./reducers/inspectionSlice";
import notificationReducer from "./reducers/notificationSlice";
import typeReducer from "./reducers/typeSlice";
import templateReducer from "./reducers/templateSlice";

export default configureStore({
  reducer: {
    inspection: inspectionReducer,
    notification: notificationReducer,
    type: typeReducer,
    template: templateReducer,
  },
});
