import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Footer from '../../Footer';
import NavbarTop from '../../NavbarTop';
import ScrollToTop from '../../ScrollToTop';
import { authenticate } from '../../../utils/Auth';
import { Formik } from 'formik';
import SignInLogic from './SignInLogic.js';


const SignIn = () => {

    if (authenticate()) {
        window.location.href = '/dashboard'
    }

    const { initialValues, validate, handleFormSubmit, setEmail, setPassword, toastContainer } = SignInLogic();

    return (
        <>
            <ScrollToTop />
            <NavbarTop />
            <Row className="signIn-container">

                <Col md={4} className="login-img-container">
                    <img src="/assets/header-image.png" alt="" />
                </Col>
                <Col md={8}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validate}
                        onSubmit={handleFormSubmit}
                    >
                        {
                            props => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    // isSubmitting,
                                    handleChange,
                                    handleSubmit,
                                    handleBlur
                                } = props;

                                return (
                                    <div className="auth-form-container ">

                                        <form className="form-wrapper login-form" autoComplete="off" onSubmit={handleSubmit} noValidate="novalidate" id="loginForm" >
                                            <div className="auth-form-text">
                                                <h2> Sign in to ProNspect</h2>
                                                <small>Provides your customer with a professionally formatted report that they can follow in a logical sequence.</small>
                                            </div> <br />
                                            <div className="input-custom form-group mb20">
                                                <input type="email" name="email" value={values.email} onBlur={handleBlur}
                                                    className={"form-control " + (errors.email && touched.email && 'br-danger')}
                                                    onChange={e => {
                                                        handleChange(e)
                                                        let val = e.target.value
                                                        setEmail(val)
                                                    }}
                                                    autoComplete="off"
                                                    placeholder="Email address *" />
                                                {errors.email && touched.email && (
                                                    <small className="input-error">{errors.email}</small>
                                                )}
                                            </div>
                                            <div className="input-custom form-group mb20">
                                                <input type="password" name="password" onBlur={handleBlur} value={values.password}
                                                    className="form-control "
                                                    onChange={e => {
                                                        handleChange(e)
                                                        let passVal = e.target.value
                                                        setPassword(passVal)
                                                    }}
                                                    autoComplete="new-password"
                                                    placeholder="Password *" />
                                                {errors.password && touched.password && (
                                                    <small className="input-error">{errors.password}</small>
                                                )}
                                            </div>


                                            <br />

                                            <button type="submit" className="btn btn-submit " >Sign In <i className="fa fa-arrow-right "></i> </button>
                                            <Link to="/register" className="btn btn-light">Create Account<i className="fa fa-arrow-right "></i> </Link>
                                            <Link to="/forgot-password" className="text-decoration-none"><small> Forgot Password ? </small> </Link>
                                        </form>

                                    </div>
                                );
                            }}
                    </Formik>
                </Col>
            </Row >
            <Footer />

            {toastContainer()}
        </>

    );

}

export default SignIn;