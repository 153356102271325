import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "./Step.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Step = ({
  portfolio,
  handleShow,
  handleData,
  handleCategory,
  handleStepName,
  handleNewIds,
}) => {
  const { title, stepId, inspection_step_categories } = portfolio;

  const description = inspection_step_categories.filter(
    (category) => category.type === "description"
  );
  const observation = inspection_step_categories.filter(
    (category) => category.type === "observation"
  );
  const recomendation = inspection_step_categories.filter(
    (category) => category.type === "recommendation"
  );


  const [style, setStyle] = useState("text");
  const data = {
    tabName: "recommendation",
    values: {
      label: "Water Intrusion",
      checked: true,
    },
    resData: {
      comment:
        "evidence of past water in the basement as noted by the water stain and efflorescence (whitish mineral deposit) on the front wall.  No active moisture intrusion was visible at the time of inspection. Keep in mind that a wet basement risks building damage from rot and insects and can cause interior mold or mildew. This condition may vary seasonally and/or with precipitation intensity. Roof and lot drainage repairs or improvements should be addressed as a first step to controlling water in the basement (see Exterior and Roof). This condition should then be monitored to determine if additional, potentially costly measures are necessary to protect the building interior from water and moisture damage. ",
      selrecomd: "Improve",
      images: [],
    },
  };

  var settings = {
    dots: true,
    arrows: true,
    scale: 0.8,
    indicators: true,
    draggable: true,
    focusOnSelect: true,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    infinite: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const setData = (
    e,
    label,
    comment,
    radio,
    images,
    category,
    type,
price,
    tabName = "recommendation",
   
  ) => {
    
    handleStepName(stepId);
    handleCategory(category);
    data.values.label = label;
    data.resData.comment = comment;
    data.resData.selrecomd = radio;
    data.resData.images = images.map((img) => img.aws_attachment_url);
    data.values.type = type;
    data.source = "review";
    data.tabName = tabName;
    data.resData.price = price;
    handleData(data);
    handleShow();
  };
  const setNewIds = (newIdsData) => {
    handleNewIds(newIdsData);
  };

  const changeStyle = () => {
    if (style === "text") {
      setStyle("underline");
    }
    if (style === "underline") {
      setStyle("text");
    }
  };

  // var obs = observation.checks[0]?.labels_details[0];
  // if (obs?.image[0]?.length === 0) {
  //   obs.image = [];
  // }
  return (
    <div>
      <div className="text-end my-5">
        <h1>{title}</h1>
      </div>
      <div>
        <h3 className="text-uppercase">DESCRIPTION OF {title}</h3>
        <ul className="list-unstyled">
          {description.map((descItem, index) => {
            return (
              <div key={index}>
                <h5>{descItem.title}</h5>
                {descItem.inspection_category_checks_checked.map(
                  (check, checkIndex) => {
                    return (
                      <div key={checkIndex}>
                        <ul style={{ fontSize: 12 }}>
                          <li>{check.title}</li>
                        </ul>
                        {/* {check.labels?.map((label, labelIndex) => (
                          <div key={labelIndex}>
                            <ul style={{ fontSize: 12 }}>
                              <li>{label}</li>
                            </ul>
                          </div>
                        ))} */}
                      </div>
                    );
                  }
                )}
              </div>
            );
          })}
        </ul>
      </div>

      {/* <div>
        <h3 className="text-uppercase">DESCRIPTION OF {title}</h3>
        <ul className="list-unstyled">
          {description.inspection_category_checks_checked?.map((check) => {
            return (
              <div key={check.id}>
                <h5>
                  {check.title}
                  <div>
                    {check.labels?.map((label) => {
                      return (
                        <div key={label}>
                          <ul style={{ fontSize: 12 }}>
                            <li>{label}</li>
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                </h5>
              </div>
            );
          })}
        </ul>
      </div> */}
      <div>
        <h3 className="text-uppercase">{title} Observations</h3>
        {observation.map((descItem, index) => {

          return (
            <div key={index}>
              <h5>{descItem.title}</h5>
              {descItem.inspection_category_checks_checked.map(
                (label, checkIndex) => {
           
                  return (
                    <div key={label.id}>
                      <p>{label.title}</p>
                      <h5>{label.radio}</h5>

                      {label.inspection_category_checks_images.map(
  (img, imgIndex) => {
    
    if (imgIndex === 0) {  
      return (
        <div key={imgIndex}>
          <h5>{img.selrecomd}</h5>
          <p
            onMouseEnter={changeStyle}
            onMouseLeave={changeStyle}
            onClick={(e) => {
              setData(
                e,
                label.title,
                img.image_comments,
                img.selrecomd,
                label.inspection_category_checks_images,
                descItem.header_id,
                "observation"
              )
              setNewIds({
                label_id: label.id,
                inspection_id: label.inspection_id,
                inspection_step_id: label.inspection_step_id,
                inspection_step_category_id: label.id
              });
            }}
            className={style}
          >
            {img.image_comments}
          </p>
          <Button
            variant="warning"
            onClick={(e) => {
              setData(
                e,
                label.title,
                img.image_comments,
                img.selrecomd,
                label.inspection_category_checks_images,
                descItem.header_id,
                "observation"
              )
              setNewIds({
                label_id: label.id,
                inspection_id: label.inspection_id,
                inspection_step_id: label.inspection_step_id,
                inspection_step_category_id: label.id
              });
            }}
          >
            Add/Remove Images
          </Button>
        </div>
      );
    }
    return null;  // Return null for all other indices
  }
)}
                      <Slider {...settings}>
                        {label.inspection_category_checks_images.map(
                          (img, imgIndex) => {
                            return (
                              <div key={imgIndex}>
                                <img
                                  style={{
                                    width: 200,
                                    height: 200,
                                  }}
                                  alt="Observation Images"
                                  src={img.aws_attachment_url}
                                />
                              </div>
                            );
                          }
                        )}
                      </Slider>
                    </div>
                  );
                }
              )}
            </div>
          );
        })}
      </div>

      {/* <div>
        <h3 className="text-uppercase">{title} Observations</h3>
        <h5>{obs?.label} </h5>
        <p
          onMouseEnter={changeStyle}
          onMouseLeave={changeStyle}
          onClick={(e) =>
            setData(
              e,
              obs.label,
              obs.comments,
              obs.radio,
              obs.image,
              observation.checks[0]?.header_id,
              observation.checks[0]?.header
            )
          }
          className={style}>
          {obs?.comments}{" "}
        </p>
        {obs?.label && (
          <div>
            <>
              <Button
                variant="warning"
                onClick={(e) =>
                  setData(
                    e,
                    obs?.label,
                    obs?.comments,
                    obs?.radio,
                    obs?.image,
                    observation.checks[0]?.header_id,
                    observation.checks[0]?.header
                  )
                }>
                Add/Remove Images
              </Button>
            </>
          </div>
        )}
        <div>
          <Slider {...settings}>
         
            {obs?.image?.map((img) => {
              return (
                <div key={img.id}>
                  <div
                    style={{
                      width: "150px",
                      height: "150px",
             
                    }}>
                    <img
                      style={{
                        width: 200,
                        height: 200,
                      }}
                      alt="observatoin Images"
                      src={img.url}
                    />
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div> */}
      <div>
        <h3 className="text-uppercase">{title} Recommendation</h3>
        <h4>
          {recomendation.map((descItem, index) => {
            return (
              <div key={index}>
                <h5>{descItem.title}</h5>
                {descItem.inspection_category_checks_checked.map(
                  (label, checkIndex) => {
                    return (
                      <div key={label.id}>
                        <p>{label.title}</p>
                        <h5>{label.radio}</h5>

                        {label.inspection_category_checks_images.map(
  (img, imgIndex) => {
  console.log(img.price)
    if (imgIndex === 0) {  // Only render for the 0th index
      return (
        <div key={imgIndex}>
          <h5>{img.selrecomd}</h5>
          <p
            onMouseEnter={changeStyle}
            onMouseLeave={changeStyle}
            onClick={(e) => {
              setData(
                e,
                label.title,
                img.image_comments,
                img.selrecomd,
                label.inspection_category_checks_images,
                descItem.id,
                'recommendation',
                img.price
              )
              setNewIds({
                label_id: label.id,
                inspection_id: label.inspection_id,
                inspection_step_id: label.inspection_step_id,
                inspection_step_category_id: label.id
              });
            }}
            className={style}
          >
            {img.image_comments}
          </p>
          <Button
            variant="warning"
            onClick={(e) => {
              setData(
                e,
                label.title,
                img.image_comments,
                img.selrecomd,
                label.inspection_category_checks_images,
                descItem.id,
                'recommendation',
                img.price
              )
              setNewIds({
                label_id: label.id,
                inspection_id: label.inspection_id,
                inspection_step_id: label.inspection_step_id,
                inspection_step_category_id: label.id
              });
            }}
          >
            Add/Remove Images
          </Button>
        </div>
      );
    }
    return null;  // Return null for all other indices
  }
)}
                      <Slider {...settings}>
  {label.inspection_category_checks_images.map(
    (img, imgIndex) => {
      return (
        <div key={imgIndex}>
          {img.aws_attachment_url ? (
            <img
              style={{
                width: 200,
                height: 200,
                objectFit: 'cover',
              }}
              alt="Recommendation Images"
              src={img.aws_attachment_url}
            />
          ) : (
           <></>
          )}
        </div>
      );
    }
  )}
</Slider>
                      </div>
                    );
                  }
                )}
              </div>
            );
          })}

          {/* {recomendation.checks?.map((check) => {
            return (
              <div key={check.id}>
                <h3>{check.header}</h3>
                {check.labels_details.map((label) => {
                  return (
                    <div key={label.id}>
                      <p>{label.label}</p>
                      <h5>{label.radio}</h5>
                      <p
                        onMouseEnter={changeStyle}
                        onMouseLeave={changeStyle}
                        onClick={(e) =>
                          setData(
                            e,
                            label.label,
                            label.comments,
                            label.radio,
                            label.image,
                            check.header_id
                          )
                        }
                        className={style}>
                        {label.comments}
                      </p>

                      <Button
                        variant="warning"
                        onClick={(e) =>
                          setData(
                            e,
                            label.label,
                            label.comments,
                            label.radio,
                            label.image,
                            check.header_id
                          )
                        }>
                        Add/Remove Images
                      </Button>
                      <div>
                        <Slider {...settings}>
                          {label.image.map((img) => {
                            return (
                              <div key={Math.floor(Math.random() * 1000000)}>
                                <div
                                  style={{
                                    width: "150px",
                                    height: "250px",
                                    // position: "relative",
                                  }}>
                                  <img
                                    style={{
                                      width: 200,
                                      height: 200,
                                    }}
                                    alt="observatoin Images"
                                    src={img.url}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}{" "} */}
        </h4>
      </div>
    </div>
  );
};

export default Step;
