import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ScrollToTop from "../../../ScrollToTop";
import NavbarDashboard from "../../NavbarDashboard";
import { useParams } from "react-router-dom";

import CheckoutForm from "./CheckoutForm";
import "./CheckoutPage.css";


// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
// const stripePromise = loadStripe(
//   "pk_test_51JSzoHLZUKXsvZw93VPxF1F6DkOzg9ITIGvTntS2bocr5MdWlb46GrQ7AiKL7nS42oWkc0EvnkSEsATivS8SQvsF00IBhKsrzG"
// );
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);



export default function CheckoutPage() {
  const { id } = useParams();
  let clientSecret = id;

  //   useEffect(() => {
  //     // Create PaymentIntent as soon as the page loads
  //     fetch("/create-payment-intent", {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
  //     })
  //       .then((res) => res.json())
  //       .then((data) => setClientSecret(data.clientSecret));
  //   }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div
      className="CheckoutPage "
      style={{
        display: "flex",
        marginTop: 200,
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <ScrollToTop />
      <NavbarDashboard />
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm paymentIntentUser={id} />
        </Elements>
      )}
    </div>
  );
}
