import React, { useEffect, useState } from 'react'
import ScrollToTop from '../../ScrollToTop'
import NavbarDashboard from '../NavbarDashboard'
import { Row, Table } from 'react-bootstrap'
// import { useSelector } from "react-redux"
import moment from 'moment'
import axios from 'axios'

const ReportArchives = () => {
    // const reports = useSelector((state) => state.notification.notification)
    const [allReports, setReports] = useState([])
    let token = localStorage.getItem("access_token");
    useEffect(() => {
        var getReportsArchive = {
            method: "get",
            url: "/v1/oauth/inspection/report-history",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        axios(getReportsArchive).then(res => setReports(res.data.reports))
    }, [token])

    return (
        <>
            <ScrollToTop />
            <NavbarDashboard />
            <div className="content-container Mcontent" >
                <Row className="side-spacing pt50" id="archive-spacing">
                    <h2 className=" float-start">Report Archives</h2>
                    <Table striped bordered>
                        <thead>
                            <tr>
                                <th className="text-center">Client Name</th>
                                <th className="text-center">Email</th>
                                <th className="text-center">Date</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allReports.map((report, index) => {
                                return (
                                    <tr key={index} style={{ padding: '1rem' }}>
                                        <td className='text-center'>
                                            {report.client.client_name}
                                        </td>
                                        <td className='text-center'>
                                            {report.client.email}
                                        </td>
                                        <td className='text-center'>
                                            {moment(report.created_date).add(5, 'hours').fromNow()}
                                        </td>
                                        <td style={{ display: 'flex', justifyContent: 'center' }}>
                                            <img src='/assets/icon/download.png' onClick={() => window.open(`https://beta.pronspect.com/${report.report_name}`)} style={{ height: 32, width: 32, cursor: 'pointer', }} alt='downloadButton' />
                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </Table>
                </Row>
            </div>
        </>
    )
}

export default ReportArchives