import React, { useState, useEffect, useRef } from 'react';
import ScrollToTop from '../../ScrollToTop';
import NavbarDashboard from '../NavbarDashboard';
import { Row } from 'react-bootstrap'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { Slide } from 'react-toastify';
import ReportRow from './ReportRow';
import InfiniteScroll from 'react-infinite-scroll-component';
import { debounce } from '../../../utils/utills';

const ReportHistory = () => {

    //combined component for active and archive clients

    var token = localStorage.getItem('access_token');
    const [clients, setClients] = useState([]);
    const [hasMoreRecords, setHasMoreRecords] = useState(true)
    const inputSearchRef = useRef()

    const notify = (message, status) => {
        if (status) {
            toast.success(message, {
                theme: "dark"
            });
        } else {
            toast.error(message, {
                theme: "dark"
            });
        }
    };

    const getClientsApi = async (listSize) => {
        const searchQuery = inputSearchRef.current.value
        var getUser;
        // document.body.classList.add('loading-indicator')
            getUser = {
                method: 'post',
                url: '/v1/oauth/client/get-clients-list',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: { active: 1, search: searchQuery, paginate: true}
            }
            if (listSize){
                getUser.data = {listSize: listSize, paginate: true, active: 1}
            }
  
            await axios(getUser)
            .then(response => {
                if (response.data.status === true) {
                    if (searchQuery){
                        setClients(response.data.clients)
                    }else{
                        setClients([...clients, ...response.data.clients]);
                    }
                    document.body.classList.remove('loading-indicator');
                } else {
                    document.body.classList.remove('loading-indicator');
                    setHasMoreRecords(false)
                    if (searchQuery.length !== 0){
                        setClients('')
                        notify(response.data.message, 'success');
                    }
                }

            })
            .catch(error => {

                document.body.classList.remove('loading-indicator');
                notify('Network Error!')
            });

    };

    useEffect(() => {
        // setClientInspection([])
        getClientsApi();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handleShowSearchInput = () => {
        const inputSearch = document.getElementById("searchField");
        if (document.getElementById("searchField").classList.contains("d-none")) {
            document.getElementById("searchField").classList.remove("d-none");
            inputSearch.focus()
        } else {
            document.getElementById("searchField").classList.add("d-none");
        }
    };
    const handleHideSearchInput = (e) => {
        document.getElementById("searchField").classList.add("d-none");
    };


    const handlePagination = () => {
        const searchQuery = inputSearchRef.current.value
        if (searchQuery){
            return
        }
        document.body.classList.add('loading-indicator')
        getClientsApi(clients.length)
    }


    const InputChange = debounce(() => getClientsApi())

    return <>
        <ScrollToTop />
        <NavbarDashboard />
        <div className="content-container Mcontent" >

            <Row className="side-spacing pt50" onMouseLeave={handleHideSearchInput}>
                <div className="mb20 list-header">
                    <h2 className=" float-start">Report History</h2>
                    <div className="position-relative float-end search-list">
                        <img
                            src="/assets/icon/search.png"
                            className="ml20 cursor-pointer"
                            alt=""
                            onClick={handleShowSearchInput}
                        />
                        <input
                            type="text"
                            className="form-control d-none search-input"
                            placeholder="Search Inspection"
                            id="searchField"
                            ref={inputSearchRef}
                            // onMouseEnter={handleHideSearchInput}
                            onMouseLeave={handleHideSearchInput}
                            onChange={() => {
                                InputChange()
                            }
    
                            }
                        />
                    </div>
                </div>
                <div className="mb10">
                    {/* <label htmlFor="activeClient" className="choose-file-box px-3 p-2 cursor-pointer mr20">
                        <input type="checkbox" name="activeClient" id="activeClient"
                            onChange={() => handleClientStatus()} checked={activeClientStatus} /> &nbsp;
                        Active Clients
                    </label>
                    <label htmlFor="archiveClient" className="choose-file-box px-3 p-2 cursor-pointer">
                        <input type="checkbox" name="archiveClient" id="archiveClient"
                            onChange={() => handleClientStatus()} checked={archiveClientStatus} /> &nbsp;
                        Archive Clients
                    </label> */}
                </div>
                <div className="mt-4">
                    {
                        clients &&
                        <InfiniteScroll
                        dataLength={clients.length}
                        hasMore={hasMoreRecords}
                        endMessage={<h4 style={{ marginLeft: 30, fontSize: 17, color: 'grey', fontWeight: 'bold' }}>All records have been fetched </h4>}
                        next={handlePagination}
                        >
                        {clients
                            // .filter((val) => {
                            //     if (searchText === "") {
                            //         return val;
                            //     } else if (
                            //         val.client_name
                            //             .toLowerCase()
                            //             .includes(searchText.toLowerCase()) ||
                            //         val.email.toLowerCase().includes(searchText.toLowerCase())
                            //     ) {
                            //         return val;
                            //     }
                            //     return false;
                            // })
                            .map(inspection => {
                                if (inspection.inspection_report !== undefined && inspection.inspection_report !== null) {

                                    return < ReportRow key={inspection.id} data={inspection} />

                                }
                                else{
                                    return null
                                }
                            }
                            )
                        }
                            </InfiniteScroll>
                    }


                </div>

            </Row>
        </div >


        <ToastContainer
            position="bottom-right"
            autoClose={2000}
            transition={Slide}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </>;
}
export default ReportHistory;
