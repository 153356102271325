import React, { useEffect, useState, createContext } from "react";
import axios from "axios";
import ScrollToTop from "../../ScrollToTop";
import NavbarDashboard from "../NavbarDashboard";
import BillingInformation from "./BillingInformation/BillingInformation";
import { useHistory } from "react-router-dom";
export const PaymentContext = createContext();

const Plans = () => {
  const history = useHistory()
  const [authStatus, setAuthStatus] = useState(false);

  const token = localStorage.getItem("access_token");
  const getAuthUserApi = () => {
    var getUser = {
      method: "get",
      url: "/v1/oauth/user/get-user",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(getUser)
      .then(function (response) {
        if (response.data.user.is_payment_done === 1) {
          localStorage.setItem("paymentStatus", true);
        } else {
          history.push("/plans")
        }
      })
      .catch((error) => {
        // if (error.response.status === 401) {
        //   window.location.href = "/login";
        // }
      });
  };
  const checkUserPayment = () => {


    const getSubscription = {
      method: "get",
      url: "/v1/oauth/getSubscriptionDetails",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(getSubscription)
      .then((res) => {
        // localStorage.setItem("paymentStatus", res.data.status);
        setAuthStatus(res.data.status);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    getAuthUserApi()
    checkUserPayment()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ScrollToTop />
      <NavbarDashboard />
      <PaymentContext.Provider value={{ authStatus, setAuthStatus }}>
        <BillingInformation authStatus={authStatus} />
      </PaymentContext.Provider>
    </>
  );
};

export default Plans;
