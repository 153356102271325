import React, { useEffect, useState } from "react";
import ScrollToTop from "../../ScrollToTop";
import NavbarDashboard from "../NavbarDashboard";
import { Row, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { setTemplateName } from "../../../redux/reducers/typeSlice";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

const Templates = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [editShow, setEditShow] = useState(false);
  const handleEditClose = () => setEditShow(false);
  const [updateInput, setUpdateInput] = useState("");
  const [updateTemp, setUpdateTemp] = useState(false);
  const dispatch = useDispatch();
  const [editIndex, setEditIndex] = useState(null); // Store the index of the template being edited
  const [hasMoreRecords, setMoreRecords] = useState(true)
const [clientExists, setClientExists] = useState(true);
  const [input, setInput] = useState("");
  const [templates, setTemplates] = useState([]);
  const [deleteConfirmShow, setDeleteConfirmShow] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState(null);
  const [editError, setEditError] = useState("");
  let token = localStorage.getItem("access_token");

  function handleChange(e) {
    setInput(e.target.value);
  }
  const [page, setPage] = useState(1);


const fetchTemplates = async () => {
 

  const listSize = templates.length;

  const config = {
    method: "get",
    url: `/v1/oauth/templates/list?listSize=${listSize}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { listSize: listSize }
  };

  try {
    const response = await axios(config);
    if (response.data.status === true) {
      const newTemplates = response.data.data;
      // Only add new templates that are not already in the list
      const updatedTemplates = [...templates, ...newTemplates.filter(
        newTemplate => !templates.some(
          existingTemplate => existingTemplate.id === newTemplate.id
        )
      )];
      setTemplates(updatedTemplates);
      setPage(prevPage => prevPage + 1);
      setMoreRecords(newTemplates.length > 0);
    } else {
      setMoreRecords(false);
      setClientExists(false);
    }
  } catch (error) {
    console.error("Error fetching templates:", error);
  } finally {
  
    document.body.classList.remove("loading-indicator");
  }
};

useEffect(() => {
  fetchTemplates();
}, [updateTemp]);

const handlePagination = () => {
  if (clientExists ) {
    document.body.classList.add("loading-indicator");
    fetchTemplates();
  }
};
  console.log(templates)
  const handleSave = () => {
    document.body.classList.add("loading-indicator");
    if (input.trim()) {
      const title = {
        title: input,
      };

      const config = {
        method: "post",
        url: "/v1/oauth/templates/create",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: title,
      };

      // Make the POST request with the Authorization header
      axios(config)
        .then((response) => {
          console.log("Template created successfully", response.data);
          setUpdateTemp((Prev) => !Prev);
          setInput(""); // Clear the input field
          handleClose(); // Close the modal
          document.body.classList.remove("loading-indicator");
        })
        .catch((error) => {
          console.error("There was an error creating the template", error);
          document.body.classList.remove("loading-indicator");
        });
    }
  };

  const handleEdit = (id) => {
    const templateToEdit = templates.find((template) => template.id === id);
    if (templateToEdit) {
      setUpdateInput(templateToEdit.title);
      setEditIndex(id);
      setEditShow(true);
    }
  };

  const handleEditChange = (e) => {
    setUpdateInput(e.target.value);
  };

  const handleEditSave = () => {
    if (updateInput.trim() === "") {
      setEditError("Template title cannot be empty");
      return;
    }
    setEditError("");
    document.body.classList.add("loading-indicator");
    if (updateInput.trim() !== "") {
      const templateId = editIndex;

      const data = {
        template_id: templateId,
        title: updateInput,
      };

      const config = {
        method: "post",
        url: "/v1/oauth/templates/update",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response) => {
         
        setTemplates((prevTemplates) =>
          prevTemplates.map((template) =>
            template.id === templateId
              ? { ...template, title: updateInput } // Update the edited template
              : template
          )
        );
          setUpdateTemp((prev) => !prev);
          setEditShow(false);
          setUpdateInput("");
          document.body.classList.remove("loading-indicator");
        })
        .catch((error) => {
          console.error("There was an error updating the template", error);
          document.body.classList.remove("loading-indicator");
        });
    }
  };
  const handleDeleteConfirm = (id) => {
    setTemplateToDelete(id);
    setDeleteConfirmShow(true);
  };
  const handleDelete = (id) => {
    document.body.classList.add("loading-indicator");
    const config = {
      method: "post",
      url: "/v1/oauth/templates/delete",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        template_id: templateToDelete,
      },
    };
    axios(config)
      .then((response) => {
        console.log("Template deleted successfully", response.data);
        setUpdateTemp((prev) => !prev);
        setDeleteConfirmShow(false);
        setTemplateToDelete(null);
      })
      .catch((error) => {
        console.error("There was an error deleting the template", error);
        setTemplateToDelete(null);
        setDeleteConfirmShow(false);
      });
    setUpdateTemp((prev) => !prev);
    document.body.classList.remove("loading-indicator");
  };
  const handleTitle = (title) => {
    dispatch(setTemplateName(title));
  };
const handleClone=(id) => {
  document.body.classList.add("loading-indicator");
    const config = {
      method: "post",
      url: "/v1/oauth/templates/clone",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        template_id: id,
      },
    };
    axios(config)
     .then((response) => {
   
        setUpdateTemp((prev) =>!prev);
        document.body.classList.remove("loading-indicator");
      })
     .catch((error) => {
        console.error("There was an error cloning the template", error);
        document.body.classList.remove("loading-indicator");
      });
    setUpdateTemp((prev) =>!prev);
}
  return (
    <>
      <ScrollToTop />
      <NavbarDashboard />
      <div className="content-container Mcontent">
        <Row className="side-spacing pt50">
          <div className="mb20 list-header border-bottom">
            <h2 className=" float-start">My Templates</h2>
            <div
              className="position-relative float-end search-list d-flex align-items-center justify-content-center mt-2 btn"
              onClick={handleShow}
            >
              <img
                src="/assets/icon/addicon.png"
                className="ml20 cursor-pointer mr10"
                alt=""
              />
              <div>Add Template</div>
            </div>
          </div>
          <InfiniteScroll
              dataLength={templates.length}
              next={handlePagination}
              hasMore={hasMoreRecords}
              style={{minHeight: 320,width: "100%"}}
              // loader={<h4>Loading...</h4>}
              endMessage={<h4 style={{ marginLeft: 30, fontSize: 17, color: 'grey', fontWeight: 'bold' }}>All records have been fetched </h4>}

            >
          {templates.map((data, index) => (
            <div
              key={data.id}
              className="d-flex justify-content-between align-items-center mb-2"
              style={{
                boxShadow: " 0px 0px 5.8px 0px rgba(0, 0, 0, 0.25)",
                padding: "16px",
                borderRadius: "8px",
                cursor: "default",
                margin:"4px 12px 0px 12px"
              }}
            >
              <div>{data.title}</div>

            

<div className="d-flex gap-2 align-items-center">


    <div style={{ cursor: "pointer" }} title="clone" onClick={()=>handleClone(data.id)}>
      <img
        src="/assets/icon/clone.svg"
        style={{ cursor: "pointer" }}
        alt="View"
        height={"20px"}
        width={"20px"}
        title="clone"

      />
    </div>
 

  {data.id !== 1 && (
    <>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => handleEdit(data.id)}
              title="rename"
      >
        <img
          src="/assets/icon/rename.svg"
          style={{ cursor: "pointer" }}
          alt="Rename"
          height={"20px"}
          width={"20px"}
                title="rename"
        />
      </div>

      <Link
        // to={`/template-details/${data.id}`}
        to={`template-description/${data.id}`}
        style={{ textDecoration: "none", color: "black" }}
        onClick={() => handleTitle(data.title)}
      >
        <div style={{ cursor: "pointer" }} title="edit">
          <img
            src="/assets/icon/editicon.png"
            style={{ cursor: "pointer" }}
            alt="Edit"
            height={"20px"}
            width={"20px"}
            title="edits"
          />
        </div>
      </Link>

      <div onClick={() => handleDeleteConfirm(data.id)} title="delete">
        <img
          src="/assets/icon/delete.png"
          style={{ cursor: "pointer" }}
          alt="Delete"
          height={"20px"}
          width={"20px"}
          title="delete"
        />
      </div>
    </>
  )}
</div>
              
            </div>
          ))}
          </InfiniteScroll>
        </Row>
      </div>

      <Modal show={show} onHide={handleClose} className="p-3">
        <Modal.Header
          closeButton
          className="my-2"
          style={{ borderBottom: "none" }}
        >
          <Modal.Title>Add a Template</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4 w-100">
          <input
            type="text"
            placeholder="Enter Name"
            style={{
              border: "none",
              borderBottom: "1px solid #CDCDCD",
              width: "100%",
            }}
            onChange={(e) => handleChange(e)}
          />
        </Modal.Body>
        <Modal.Footer className="my-2" style={{ borderTop: "none" }}>
          <Button
            variant="secondary"
            className="m-0 w-25 mx-2 "
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="m-0 w-25 btn-submit mx-2 "
            variant="primary"
            onClick={handleSave}
          >
            Next
          </Button>
        </Modal.Footer>
      </Modal>

      {/* UPDATE MODAL */}

      <Modal show={editShow} onHide={handleEditClose} className="p-3">
        <Modal.Header
          closeButton
          className="my-2"
          style={{ borderBottom: "none" }}
        >
          <Modal.Title>Edit Template</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4 w-100">
          <input
            type="text"
            placeholder="Enter Name"
            value={updateInput} // Use the updateInput state for the input value
            style={{
              border: "none",
              borderBottom: "1px solid #CDCDCD",
              width: "100%",
            }}
            onChange={handleEditChange}
          />
           {editError && <div className="text-danger mt-2">{editError}</div>}
        </Modal.Body>
        <Modal.Footer className="my-2" style={{ borderTop: "none" }}>
          <Button
            variant="secondary"
            className="m-0 w-25 mx-2 "
            onClick={handleEditClose}
          >
            Cancel
          </Button>
          <Button
            className="m-0 w-25 btn-submit mx-2 "
            variant="primary"
            onClick={handleEditSave}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* DELETE CONFIRMATION MODAL */}
      <Modal
        show={deleteConfirmShow}
        onHide={() => setDeleteConfirmShow(false)}
        className="p-3"
      >
        <Modal.Header
          closeButton
          className="my-2"
          style={{ borderBottom: "none" }}
        >
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          Are you sure you want to delete this template?
        </Modal.Body>
        <Modal.Footer className="my-2" style={{ borderTop: "none" }}>
          <Button
            variant="secondary"
            className="m-0 w-25 mx-2"
            onClick={() => setDeleteConfirmShow(false)}
          >
            Cancel
          </Button>
          <Button
            className="m-0 w-25 btn-submit mx-2"
            variant="danger"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Templates;
